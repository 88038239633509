:global(body.modal-open),
:global(html.modal-open) {
	overflow: hidden;
	margin: 0 !important;
}

:global(body.modal-open) {
	position: relative;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: var(--100vw);
	height: var(--100vh);
	z-index: 998;
	background-color: rgba(0, 0, 0, 0.55);
	backdrop-filter: blur(2px);
}

.popup {
	position: fixed;
	z-index: 1000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--section-background-color);
	padding: 2em 1.375em 20px;
	overflow-y: auto;
	max-height: calc(var(--100vh) - 12px);
}

.closeX {
	line-height: 2em;
	position: absolute;
	right: 1em;
	top: 1em;
	width: 2em;
	height: 2em;
	border-radius: 1em;
	background-color: var(--module-box-color);
	cursor: pointer;
	text-align: center;
	z-index: 1100;
}

.header {
	display: flex;
}

.closeXIcon {
	width: unset !important;
	height: unset !important;
	display: block !important;
	margin-top: 2px;
}

.closeXIconInside {
	stroke: var(--module-box-icon-color);
	fill: none;
}

@media (min-width: 710px) {
	.popup {
		border-radius: 0.75em;
	}

	.popup > div:nth-of-type(2) {
		width: 650px;
	}
}

@media (max-width: 710px) {
	.popup {
		width: var(--100vw);
		height: calc(var(--100vh) - 60px);
		border-radius: 0;
		top: 60px !important;
		left: 0 !important;
		transform: none;
	}

	.popup > div:nth-of-type(2) {
		width: 100%;
		height: 100%;
		position: relative;
	}
}

.disabled {
	cursor: not-allowed;
	background-color: var(--disable-color);
}

.disabled .closeXIconInside {
	stroke: var(--disable-inside-color) !important;
}
