.wrapper {
	width: var(--100vw);
	height: var(--100vh);
	text-align: center;
	background-color: var(--main-background-color);
	padding-top: clamp(4px, 5vh, 36px);
}

.header {
	font-size: clamp(2.5em, 6vh, 5em);
	color: var(--text-color-1);
	margin: 0;
}

.description {
	font-size: clamp(1.2em, 3vh, 2em);
	color: var(--text-color-4);
}

.subDescription {
	margin-top: 0;
	font-weight: 600;
	font-size: 28px;
	color: var(--text-color-4);
}

.lottieWrapper {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	height: clamp(100px, 36vh, 500px);
}

.reportStatus {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 3px;
	background-color: lightgray;
}

.reportSuccessful {
	background-color: lightgreen;
}

.reportFailed {
	background-color: darkred;
}

@media (max-width: 768px) {
	.lottieWrapper {
		height: clamp(100px, 25vh, 300px);
	}
}
