.dateSlotsWrapper {
	overflow-y: scroll;
	max-height: calc(calc(var(--100vh)) - 250px);
}

.dateSlotsWrapper::-webkit-scrollbar {
	display: none;
}

.dateSelectInput {
	justify-self: end;
}

.normalDate {
	cursor: pointer;
}

.dateSlotError {
	font-size: 16px;
	font-weight: 600;
	color: var(--invalid-color);
}

@media (max-width: 710px) {
	.finalize {
		position: absolute;
		bottom: 0;
	}

	.normalDate {
		grid-template-columns: auto;
	}

	.normalDate > button {
		display: none;
	}
}

@media (max-width: 250px) {
	.specificDate {
		grid-template-columns: auto;
	}
}
