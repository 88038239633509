.icon {
	background-color: transparent;
}

.iconInside {
	stroke: var(--section-background-text-color);
	fill: none;
}

.noSelectedConsumer .iconInside,
.noSelectedUserButton .iconInside {
	stroke: var(--accent-inside-color);
}

.noSelectedConsumer,
.noSelectedUserButton {
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	border: none;
}

.noSelectedConsumer:hover,
.noSelectedUserButton:not(.selectUserDisabled):hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.noSelectedConsumer:hover .iconInside,
.noSelectedUserButton:not(.selectUserDisabled):hover .iconInside {
	stroke: var(--accent-hover-inside-color);
}

.selectUserDisabled {
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
	cursor: not-allowed;
}

.selectUserDisabled .iconInside {
	stroke: var(--disable-inside-color);
}

.buttonsWrapper > div {
	min-height: 75px;
}

@media (max-width: 650px) {
	.buttonsWrapper {
		grid-template-columns: 1fr;
	}

	.buttonsWrapper > div {
		grid-template-columns: minmax(0, 1fr) 20px;
	}
}
