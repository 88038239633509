.wrapper {
	display: grid;
	padding-left: 35px;
	padding-right: 35px;
	grid-template-columns: minmax(auto, calc(calc(var(--100vw) - 1635px) / 2)) auto;
	padding-bottom: 70px;
}

.content {
	max-width: 1600px;
	margin-top: 48px;
}

.contentHeader {
	margin-bottom: 30px;
}

.contentHeader .title,
.contentHeader .subTitle {
	height: 100%;
	display: flex;
	align-items: center;
	color: var(--main-background-text-color);
}

.contentHeader .title {
	font-size: 40px;
	font-weight: 600;
}

.contentHeader .subTitle {
	font-size: 20px;
}

.disclaimerText {
	font-size: 20px;
	color: var(--main-background-text-color);
	max-width: 1100px;
}

.calcsWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 26px;
}

.calcItem {
	display: flex;
	height: fit-content;
	min-height: 250px;
	box-shadow: 0 1px 6px #0000002e;
	border-radius: 12px;
	padding: 20px;
	width: 100%;
	max-width: 660px;
	min-width: 550px;
	flex: 1 1 550px;
	gap: 20px;
	background-color: var(--section-background-color);
}

.selectedCalcItem {
	max-width: 1350px;
	flex-wrap: wrap;
}

.calcItemContent {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-width: 200px;
}

.selectedCalcItem .calcItemContent {
	min-width: 135px;
	max-width: 450px;
	flex: 1;
}

.calcItemContent .name {
	font-size: 28px;
	font-weight: 600;
	margin-top: 5px;
	color: var(--section-background-text-color);
}

.selectedCalcItem .calcItemContent .name {
	hyphens: auto;
}

.calcItemContent .description {
	margin-top: 10px;
	font-size: 20px;
	color: var(--section-background-text-color);
}

.selectedCalcItem .calcItemContent .description {
	hyphens: auto;
}

.calcItemContent .actionButton {
	margin-top: auto;
	max-width: 250px;
	min-width: 170px;
	width: 100%;
}

.selectedCalcItem .selectAreaSection {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	gap: 20px;
}

.selectAreaSection .actionButton {
	margin-top: auto;
}

.selectAreaSection .title {
	font-size: 20px;
	margin-bottom: 8px;
	color: var(--section-background-text-color);
}

.inputWrapper {
	position: relative;
	width: fit-content;
}

.inputWrapper > .input::-webkit-inner-spin-button {
	display: none;
}

.inputWrapper > .input {
	height: 68px;
	border: 2px solid #707070;
	border-radius: 12px;
	font-size: 18px;
	padding-left: 10px;
	padding-right: 40px;
	max-width: 310px;
	width: 100%;
}

.inputWrapper > span {
	font-size: 20px;
	position: absolute;
	right: 10px;
	top: 14px;
	font-weight: 600;
}

.calcItemImageWrapper {
	overflow: hidden;
	box-shadow: 0 0 6px #00000029;
	border-radius: 12px;
	max-width: 220px;
	max-height: 220px;
	min-width: 200px;
}

.calcUrlImageWrapperFit {
	height: 22em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.calcUrlImageWrapperFill {
	height: 22em;
	width: calc(100%);
	min-width: 220px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.calcUrlImageFill {
	height: 100%;
	min-width: 100%;
	object-fit: cover;
}

.calcUrlImageFit {
	height: calc(100%);
	max-width: 100%;
	padding: 10px;
	object-fit: contain;
}

.summaryWrapper {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
}

.summaryBox {
	box-shadow: 0 1px 6px #0000002e;
	background-color: var(--section-background-color);
	padding: 20px;
	color: var(--section-background-text-color);
}

.summaryBox > .title {
	color: var(--section-background-text-color);
	font-size: 35px;
	font-weight: 600;
	margin-bottom: 25px;
}

.spacedRow {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.calcProductList {
	display: flex;
	flex-direction: column;
}

.calcProductList hr {
	height: 0;
	border: none;
	border-bottom: 1px solid #e5e5e5;
	width: 100%;
	margin-top: 15px;
	margin-bottom: 15px;
}

.calcProductListItem {
	font-size: 20px;
	row-gap: 5px;
}

.calcProductListHeader,
.calcProductListItem {
	display: grid;
	grid-template-columns: 50fr 25fr 25fr;
}

.calcProductListHeader {
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 6px;
	margin-bottom: 15px;
	font-size: 14px;
}

.calcProductListHeader > div:first-of-type,
.calcProductListItem .itemCompressionWeight,
.calcProductListItem .productName {
	padding-left: 22px;
}

.calcProductListItem .itemWeight {
	white-space: nowrap;
	margin-left: auto;
}

.calcProductListItem .itemCompressionWeight {
	font-size: 14px;
	grid-row: 2;
	grid-column: 1/4;
	display: flex;
	justify-content: space-between;
}

.calcProductListItem .productName {
	text-decoration: underline;
	color: var(--section-discount-color);
	font-weight: 600;
}

.totalWeightsBox {
	max-width: 550px;
}

.totalWeightsBox hr {
	border: none;
	border-bottom: 2px solid #e5e5e5;
}

.calculatedWeightsBox {
	max-width: 550px;
	margin-top: 20px;
}

.suggestedProductsBox {
	width: 38%;
	min-width: 550px;
	height: fit-content;
}

.addressBox {
	width: auto;
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	height: fit-content;
	max-width: 450px;
}

.addressBoxPricesDisabled {
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	height: fit-content;
	max-width: 450px;
}

.suggestedProductsWrapper {
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 15px;
}

.suggestedProduct {
	min-height: 85px;
	height: auto;
	background-color: var(--module-box-color);
	display: grid;
	grid-template-columns: 20fr 35fr 45fr;
	padding: 10px;
	border-radius: 12px;
	gap: 10px;
	grid-template-rows: min-content;
}

.suggestedCustomProduct {
	grid-template-columns: 100%;
}

.suggestedProductAmountDiffers {
	font-size: 14px;
	margin-top: -5px;
	font-weight: 600;
	color: var(--invalid-color);
}

.suggestedProductUrlImageWrapper.fit {
	display: flex;
	justify-content: center;
	align-items: center;
}

.suggestedProductUrlImageWrapper.fill {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	overflow: hidden;
	max-height: 80px;
	min-width: 80px;
	margin-top: auto;
	margin-bottom: auto;
}

.suggestedProductUrlImageWrapper.fit img {
	max-height: 80%;
	width: 100%;
	object-fit: contain;
}

.suggestedProductUrlImageWrapper.fill img {
	min-width: 100%;
	min-height: 100%;
}

.suggestedProduct .suggestedProductCard {
	height: 100px;
	max-height: 80px;
}

.suggestedProductUnderRow {
	grid-row: 2;
	grid-column: 1 / 5;
}

.suggestedProductInfo {
	margin-top: 10px;
	margin-bottom: 10px;
}

.suggestedProductInfo .title {
	font-weight: 600;
	font-size: 20px;
	color: var(--module-box-text-color);
	margin-bottom: 15px;
}

.suggestedProductInfo .dropdownSelector {
	font-size: 14px;
	color: var(--accent-secondary-inside-color);
	background-color: var(--accent-secondary-color);
	border-radius: 6px;
	padding: 10px;
	box-shadow: 0 3px 6px #00000029;
	display: flex;
	flex-wrap: nowrap;
	gap: 5px;
	justify-content: space-between;
	cursor: pointer;
	max-width: 200px;
}

.suggestedProductPrice {
	display: flex;
	align-items: flex-start;
	font-size: 20px;
	padding-top: 5px;
	color: var(--module-box-text-color);
}

.priceSummaryProductRow .suggestedProductPrice {
	color: var(--section-background-text-color);
}

.suggestedProductAmount {
	display: flex;
	justify-content: center;
	align-items: center;
}

.suggestedProductAmount > div {
	width: 100%;
	max-width: 90px;
	height: 32px;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	border-radius: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
}

.suggestedProductServiceSelectorWrapper {
	position: relative;
	width: 100%;
}

.suggestedProductServiceSelectorWrapper .selectedValue {
	background-color: var(--accent-secondary-color);
	color: var(--accent-secondary-inside-color);
	max-width: 250px;
	text-overflow: ellipsis;
	padding: 10px;
	border-radius: 6px;
	cursor: pointer;
	display: flex;
	gap: 10px;
	width: 100%;
	justify-content: space-between;
}

.suggestedProductServiceSelectorWrapper .selectedValue:hover {
	background-color: var(--accent-secondary-hover-color);
	color: var(--accent-secondary-inside-color);
}

.dropdown {
	position: absolute;
	width: fit-content;
	min-width: 200px;
	max-width: 300px;
	background-color: var(--accent-secondary-color);
	border-radius: 6px;
	cursor: pointer;
	z-index: 1;
	right: 0;
}

.dropdown > * {
	color: var(--accent-secondary-inside-color);
	padding: 10px;
	border-bottom: 1px solid var(--chosen-border-color);
}

.dropdown > *:last-of-type {
	border-bottom: none;
}

.dropdown > *:hover {
	background-color: var(--accent-secondary-hover-color);
}

.dropdown > *:hover:first-of-type {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.dropdown > *:hover:last-of-type {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.addressIcon svg {
	stroke: var(--input-field-inside-color);
}

.editIcon {
	cursor: pointer;
	min-width: 18px;
}

.editIcon svg {
	stroke: var(--module-box-text-color);
}

.selectedAddressInputBox {
	box-shadow: 0 1px 6px #0000002e;
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	background-color: var(--module-box-color);
	height: 68px;
	border-radius: 12px;
	font-size: 20px;
	align-items: center;
	color: var(--module-box-text-color);
}

.widgetSection {
	margin-top: 100px;
}

.widgetSection .widgetTitle {
	font-size: 40px;
	margin-bottom: 30px;
	color: var(--main-background-text-color);
}

.priceSummarySections {
	display: flex;
	flex-direction: column;
}

.priceSummaryProductRows {
	margin-left: 10px;
	padding-top: 10px;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.priceSummarySection .priceSummaryTransport {
	display: flex;
	align-items: center;
	font-size: 20px;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #e5e5e5;
}

.priceSummarySection .priceSummaryZone {
	display: flex;
	justify-content: space-between;
	font-size: 20px;
	margin-left: 10px;
	column-gap: 5px;
	margin-bottom: 5px;
}

.priceSummaryZone .zoneRow {
	display: flex;
	flex-wrap: wrap;
	column-gap: 5px;
	word-break: break-word;
	hyphens: auto;
}

.tooltipIcon {
	cursor: pointer;
	border-radius: 100px;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	padding: 5px;
	min-width: 26px;
	min-height: 26px;
	width: 26px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
}

.tooltipWrapper {
	max-width: 500px;
	width: calc(var(--100vw) - 54px) !important;
	box-shadow: 0 1px 6px #0000002e;
	opacity: 1 !important;
	z-index: 1000;
}

.priceSummaryProductRow {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.priceSummaryProductInfo {
	display: flex;
	font-size: 20px;
	column-gap: 5px;
	flex-wrap: wrap;
}

.suggestedProductSelector {
	margin-left: auto;
}

.twoBoxWrapper {
	flex: 1;
	max-width: 550px;
	min-width: 350px;
}

.priceAndSelectorWrapper {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	gap: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	justify-content: space-between;
}

.calcItemImageWrapper.noImage {
	box-shadow: none;
}

.disabledButtonText {
	font-size: 14px;
	font-weight: 600;
	color: var(--invalid-color);
	margin-left: auto;
}

@media (max-width: 1635px) {
	.wrapper {
		grid-template-columns: 0 auto;
	}
}

@media (max-width: 1100px) {
	.disclaimerText {
		font-size: 16px;
	}

	.content {
		margin-top: 32px;
	}

	.wrapper {
		padding-left: 20px;
	}

	.contentHeader .title {
		font-size: 28px;
	}

	.widgetSection .widgetTitle {
		font-size: 28px;
	}

	.contentHeader .subTitle {
		font-size: 16px;
	}

	.addressBox {
		max-width: 550px;
	}

	.calcItemContent .name {
		font-size: 20px;
	}

	.calcItemContent .description {
		font-size: 16px;
	}

	.summaryBox > .title {
		font-size: 20px;
	}

	.summaryBox > .spacedRow > div {
		font-size: 16px !important;
	}

	.calcProductListItem {
		font-size: 16px;
	}

	.suggestedProductInfo .title {
		font-size: 16px;
	}

	.priceAndSelectorWrapper .suggestedProductPrice {
		font-size: 16px;
	}
}

@media (max-width: 750px) {
	.selectedCalcItem {
		display: grid;
		grid-template-columns: 220px auto;
	}
}

@media (max-width: 710px) {
	.wrapper {
		padding-left: 8px;
		padding-right: 20px;
	}

	.calcItem {
		min-width: 0;
		flex-wrap: wrap-reverse;
	}

	.calcItem.selectedCalcItem {
		flex-direction: column;
	}

	.totalWeightsBox,
	.calculatedWeightsBox,
	.suggestedProductsBox,
	.addressBox,
	.addressBoxPricesDisabled,
	.twoBoxWrapper {
		width: 100%;
		min-width: unset;
		max-width: unset;
	}

	.widgetSection {
		width: calc(var(--100vw) - 28px);
	}

	.suggestedProductInfo .title,
	.suggestedProductInfo .dropdownSelector {
		margin-left: auto;
		text-align: end;
	}

	.calcItemContent button {
		width: 100%;
	}

	.calcItemImageWrapper {
		width: calc(100vw - 56px);
		min-width: unset;
	}
}

@media (max-width: 515px) {
	.calcItemContent .actionButton {
		width: 100%;
		max-width: unset;
	}
}

@media (max-width: 500px) {
	.priceAndSelectorWrapper {
		grid-row: 2;
		grid-column: 1/3;
		flex-direction: row;
	}

	.suggestedProductInfo {
		grid-row: 1;
		grid-column: 2 / 3;
		display: flex;
		flex-direction: column;
	}

	.suggestedProduct {
		grid-template-columns: minmax(auto, 150px) auto;
	}

	.suggestedProduct {
		grid-template-columns: 50fr 50fr;
	}

	.selectAreaSection {
		width: 100%;
	}

	.selectAreaSection .inputWrapper {
		width: 100%;
	}

	.selectAreaSection .input {
		max-width: unset;
		width: 100%;
	}

	.addressBox .input {
		max-width: unset;
	}
}

@media (max-width: 450px) {
	.selectedCalcItem {
		display: grid;
		grid-template-columns: auto;
	}
}

@media (max-width: 370px) {
	.suggestedProduct {
		grid-template-columns: 100%;
	}

	.priceAndSelectorWrapper {
		grid-row: unset;
		grid-column: unset;
		flex-wrap: wrap;
	}

	.suggestedProductInfo {
		grid-row: unset;
		grid-column: unset;
	}

	.priceAndSelectorWrapper .suggestedProductPrice {
		margin-left: auto;
	}

	.priceAndSelectorWrapper .suggestedProductSelector {
		margin-left: auto;
		margin-right: unset;
	}
}
