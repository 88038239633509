.modal {
	height: 100%;
	max-height: calc(var(--100vh) * 0.85);
}

.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.content {
	hyphens: auto;
	word-break: break-word;
	line-height: 22px;
	font-size: 18px;
	color: var(--section-background-text-color);
}

.buttons {
	margin-top: auto;
	display: flex;
	gap: 10px;
}

.customContent {
	margin: 10px 0;
}

@media (max-width: 710px) {
	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}
}

@media (max-width: 350px) {
	.buttons {
		flex-direction: column;
	}
}
