.projectFieldModuleBox {
	background-color: var(--module-box-color);
	border-radius: 0.375em;
	border: 1px solid var(--module-box-color);
	display: grid;
	grid-template-columns: auto 7.5em;
	align-items: center;
	padding: 16px;
}

.projectFieldModuleBox:hover {
	border: 1px solid var(--hover-border-color);
}

.selected {
	border: 1px solid var(--chosen-border-color);
}
