.headerRow {
	display: flex;
	gap: 25px;
	flex-wrap: wrap;
	margin-bottom: 30px;
	justify-content: space-between;
}

.header {
	color: var(--main-background-text-color);
	font-size: 40px;
	font-weight: 600;
	margin-bottom: 10px;
}

.updateListButton {
	max-width: 200px;
}

.tableWrapper {
	max-width: 1200px;
	margin-bottom: 70px;
}

.notificationsTable td,
.notificationsTable th {
	padding-left: 10px;
}

.notificationsTable thead tr {
	height: 65px;
	min-height: 65px;
}

.clickableRow input,
.clickableRow {
	cursor: pointer;
}

.clickableRow:hover {
	background-color: var(--section-hover-color);
}

.notificationsTable .notificationHeader,
.notificationsTable .notificationMessageContentText,
.notificationsTable .notificationMarking {
	hyphens: auto;
}

.notificationsTable .notificationMarking {
	font-weight: 600;
}

.notificationsTable .createdAt {
	font-weight: 600;
}

.notificationUnreadMarker {
	margin-left: 10px;
	background-color: var(--accent-color);
	border-radius: 100px;
	width: 12px;
	min-width: 12px;
	max-width: 12px;
	height: 12px;
	min-height: 12px;
	max-height: 12px;
}

.selectAllRowsCheckbox {
	cursor: pointer;
	margin-left: 3px;
}

.checkBoxCell input,
.selectAllRowsCheckbox {
	width: 16px;
	height: 16px;
}

.checkedRowsSection {
	display: flex;
	gap: 10px;
	flex-direction: column;
}

.checkedRowAmountText {
	color: var(--main-background-text-color);
	font-size: 18px;
}

.checkedRowsSection button {
	max-width: 180px;
}

.showNewToggleWrapper {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
	margin-top: 20px;
}

.showNewToggleText {
	color: var(--main-background-text-color);
}

@media (max-width: 1100px) {
	.header {
		font-size: 26px;
	}
}
