.header {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.invitationRow {
	background-color: var(--module-box-color);
	padding: 12px;
	border-radius: 6px;
	margin-bottom: 0.5rem;
	color: var(--module-box-text-color);
}

.invitationMainDetails {
	display: grid;
	grid-template-columns: auto 25px;
	cursor: pointer;
}

.inviteRowName {
	font-size: 14px;
	padding-right: 10px;
	margin-top: auto;
	margin-bottom: auto;
}
.trashIcon svg {
	stroke: var(--module-box-icon-color);
	fill: var(--module-box-icon-color);
}
.resendIcon svg {
	stroke: var(--module-box-icon-color);
	fill: var(--module-box-icon-color);
	margin-bottom: 0.25rem;
}

.inviteExtraDetailSection {
	display: grid;
	grid-template-columns: 59% 39%;
	column-gap: 1rem;
	row-gap: 1rem;
}

.inviteExtraDetailSectionButton {
	display: flex;
	flex-direction: row;
	padding: 0.5rem;
	border-radius: 15px;
}

.inviteExtraDetailSectionButton:hover {
	display: flex;
	background-color: var(--accent-hover-color);
	transition: 1.5s;
	padding: 0.5rem;
	border-radius: 15px;
	cursor: pointer;
}

.inviteExtraDetailSectionButtonText {
	margin-left: 1rem;
	margin-top: auto;
	margin-bottom: auto;
}

.inviteExtraDetailInfo {
	display: flex;
	flex-direction: column;
}

.activeInvitationList {
	max-height: 300px;
	overflow-y: scroll;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}

.activeInvitationList::-webkit-scrollbar {
	display: none;
}

@media (max-width: 1100px) {
	.header {
		font-size: 20px;
	}
}
