.showWrapper {
	margin-bottom: 2rem;
}

.orderInfoWrapper {
	padding-left: 0.7rem;
	padding-right: 0.7rem;
	padding-bottom: 0.7rem;
	background-color: var(--section-background-color);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.divider {
	background-color: var(--header-divider-color);
	height: 1px;
}

.gridWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: "productListing metaInfo";
	column-gap: 0;
	font-size: 11px;
	padding-bottom: 0.3rem;
	padding-top: 0.4rem;
}

.acceptButton {
	border-style: none;
	padding-top: 0.9rem;
	padding-bottom: 0.9rem;
	width: 48%;
	background-color: var(--accent-color);
	border-radius: 4px;
	font-size: 16px;
	font-weight: bold;
	color: var(--text-color-4);
}

.declineButton {
	border-style: none;
	padding-top: 0.9rem;
	padding-bottom: 0.9rem;
	width: 48%;
	background-color: var(--icon-color-2);
	border-radius: 4px;
	font-size: 16px;
	font-weight: bold;
	float: right;
	color: var(--text-color-4);
}

.productListing {
	grid-area: productListing;
	padding-left: 0.5rem;
	padding-right: calc(calc(var(--100vw)) * 0.01);
}

.metaInfo {
	grid-area: metaInfo;
	border-left: solid 1px var(--header-divider-color);
	padding-left: calc(calc(var(--100vw)) * 0.01);
}

.buttonContainer {
	padding-top: 1.2rem;
}

.commentWrapper {
	margin-top: 1rem;
	padding: 0.7rem;
	background-color: var(--section-background-color);
	font-size: 12px;
	border-radius: 5px;
}

.commentInput {
	background-color: var(--main-background-color);
	color: var(--text-color-1);
	height: 3rem;
	border-style: none;
	width: 100%;
	margin-top: 0.5rem;
	resize: none;
	padding: 0.4rem;
}

.iconColor {
	fill: var(--icon-color-1);
}

@media (max-width: 768px) {
	.gridWrapper {
		grid-template-columns: 1fr;
		grid-template-areas:
			"productListing"
			"metaInfo";
	}

	.metaInfo {
		border-style: none;
	}

	.buttonContainer {
		padding-top: 1.2rem;
	}

	.commentWrapper {
		margin-top: 0.6rem;
		padding: 0.4rem;
	}

	.commentInput {
		height: 2rem;
	}

	.acceptButton,
	.declineButton {
		padding-bottom: 0.7rem;
		padding-top: 0.7rem;
		width: 48%;
	}
}
