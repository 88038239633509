.wrapper {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-bottom: 20px;
}

.flexColumns {
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex-basis: 600px;
}

.box {
	display: flex;
	justify-content: center;
	background-color: var(--section-background-color);
	padding: 15px 20px 28px 20px;
	border-radius: 12px;
	width: 100%;
	max-width: 600px;
	height: fit-content;
}

@media (max-width: 915px) {
	.wrapper {
		flex-direction: column;
	}

	.wrapper > div,
	.consumerInfoAndInvoicingInfo > div {
		max-width: none;
	}
}
