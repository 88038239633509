.dropdown {
	font-size: 20px;
	padding: 8px 15px 8px 15px;
	background-color: var(--section-value-box-color);
	border-radius: 6px;
	min-width: min(100%, 220px);
	cursor: pointer;
	color: var(--section-value-box-inside-color);
	box-shadow: 0 3px 6px #00000029;
}

.dropdown:focus-visible {
	outline: none;
}
