.tooltipArrowBorder {
	border-bottom: 1px solid black;
	border-right: 1px solid black;
}

.tooltipWrapper {
	font-size: 16px !important; /*Reset font-size*/
	font-weight: normal !important; /*Reset font-weight*/
	max-width: 300px;
	width: calc(var(--100vw) - 56px) !important;
	box-shadow: 0 1px 6px #0000002e;
	opacity: 1 !important;
	z-index: 2000;
}

.tooltipWrapper h1 {
	font-size: 1.2em;
	font-weight: 600;
	margin: 4px;
}

.tooltipWrapper h2 {
	font-size: 1.1em;
	font-weight: 500;
	margin: 4px;
}

.tooltipWrapper h3 {
	font-size: 1.05em;
	font-weight: 400;
	margin: 4px;
}

.tooltipWrapper p {
	font-size: 1em;
	margin: 4px;
}
