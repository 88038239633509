:global(div.google-map-container-custom-fullscreen) {
	position: fixed !important;
	top: 100px;
	bottom: 0;
	right: 0;
	left: 0;
	height: calc(var(--100vh) - 100px);
	width: var(--100vw);
	z-index: 1200;
	margin-top: 0;
	border-radius: 0;
}

:global(div[aria-label="modal"]) :global(div.google-map-container-custom-fullscreen).mapContainer {
	width: 100%;
}

@media (min-width: 711px) {
	:global(div[aria-label="modal"]) :global(div.google-map-container-custom-fullscreen).mapContainer {
		top: 0;
		height: 100%;
	}
}

@media (max-width: 1100px) {
	:global(div.google-map-container-custom-fullscreen) {
		top: 60px;
		height: calc(var(--100vh) - 60px);
	}
}

:global(.google-maps-custom-fullscreen-button) {
	width: 40px;
	height: 40px;
	appearance: none;
	border: none;
	background-color: white;
	margin: 0;
	padding: 0;
	box-shadow: 1px 1px 6px 2px #00000029;
	right: 10px !important;
	top: 10px !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

:global(.google-maps-custom-fullscreen-button > .expandIcon),
:global(.google-maps-custom-fullscreen-button.is-fullscreen > .compressIcon) {
	display: block;
	color: #656566;
}

:global(.google-maps-custom-fullscreen-button > .expandIcon:hover),
:global(.google-maps-custom-fullscreen-button.is-fullscreen > .compressIcon:hover) {
	color: black;
}

:global(.google-maps-custom-fullscreen-button > .compressIcon),
:global(.google-maps-custom-fullscreen-button.is-fullscreen > .expandIcon) {
	display: none;
}

.blockContent,
.blockHeader {
	padding: 10px;
}

.blockHeader {
	background-color: var(--module-box-color);
	font-size: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	margin-bottom: 14px;
	color: var(--module-box-text-color);
	cursor: pointer;
}

.noBackgroundColor {
	padding-left: 0;
	padding-bottom: 0;
	background-color: transparent;
	margin-bottom: 3px;
}

.blockContent {
	background-color: var(--module-box-color);
	padding: 15px;
	border-radius: 6px;
	color: var(--module-box-text-color);
}

.productInfoBox {
	border-radius: 6px;
	background-color: var(--module-box-color);
	padding: 15px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: auto 100px 140px;
	height: 100px;
}

.productInfoBoxName p {
	font-size: 18px;
	font-weight: 600;
}

.tableCellWrapper {
	display: table-cell;
	vertical-align: middle;
}

.orderItemProductAmount {
	display: flex;
	justify-content: space-evenly;
	border-radius: 50px;
	background-color: var(--confirmed-amount-color);
	font-size: 20px;
	font-weight: 600;
	color: var(--confirmed-amount-inside-color);
	padding: 2px 10px;
	min-width: 85px;
}

.basketItemProductImage {
	display: flex;
	height: 100%;
	max-height: 80px;
	width: auto;
	aspect-ratio: 1/1;
	margin: 0 15px 0 auto;
}

.basketItemProductImage > img {
	max-height: 80px;
}

.wrapper {
	height: 100%;
}

.deliveryDateTime {
	margin-top: 10px;
	display: grid;
	font-weight: 500;
	grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
}

.deliveryDateTime > span > div:first-of-type {
	margin-bottom: 6px;
}

.deliveryDateTime > span > div {
	display: flex;
	gap: 5px;
}

.iconColor {
	flex-shrink: 0;
	color: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
}

.orderNumber {
	margin-bottom: 20px;
	color: var(--section-background-text-color);
}

.header {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
}

.headerAligner {
	width: 96px;
}

.mapContainer {
	height: 200px;
}

.googleMapsButtons {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 10px;
	column-gap: 10px;
}

.googleMapButton {
	cursor: pointer;
	border-radius: 26px;
	padding: 10px;
	color: var(--accent-inside-color);
	background-color: var(--accent-color);
	box-shadow: 0 1px 6px #00000029;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.googleMapButton:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.orderImagesWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	background-color: var(--module-box-color);
	border-radius: 6px;
	min-height: 100px;
}

.orderImagesWrapper img {
	max-width: 120px;
	width: 120px;
	height: 120px;
	max-height: 120px;
	padding: 2px;
	border-radius: 16px;
	box-shadow: 0 1px 6px #00000029;
	cursor: pointer;
}

.orderImagesWrapper img:hover {
	border: 1px solid var(--chosen-border-color);
}

.loggedInUserWrapper {
	display: flex;
	gap: 10px;
	margin-top: 5px;
}

.profilePic {
	width: 34px;
	min-width: 34px;
	height: 34px;
	min-height: 34px;
	overflow: hidden;
	border-radius: 100px;
	background-color: rgb(165, 165, 165);
	cursor: pointer;
}

.profilePic img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.noProfilePic {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
	color: black;
}

.horizontalScrollCell {
	display: flex;
}

.tabSelector {
	font-size: 20px;
	width: 100%;
	border-bottom: 2px solid var(--section-border-color);
	padding-bottom: 10px;
	color: var(--section-background-text-color);
	display: flex;
	justify-content: center;
	cursor: pointer;
	min-width: 100px;
	padding-top: 15px;
}

.tabSelector:hover {
	background-color: var(--section-hover-color);
	border-bottom: 8px solid var(--accent-color);
}

.selectedTab {
	font-weight: 600;
	border-bottom: 8px solid var(--accent-color);
}

div.statusElement {
	border-radius: 6px;
	font-weight: 600;
	width: 110px;
}

.navHorizontalScroll {
}

.navHorizontalScroll > div:first-of-type {
	left: 24px;
}

.navHorizontalScroll > div:last-of-type {
	right: 24px;
}

.tabSelectorAmount {
	display: flex;
	border-radius: 100px;
	width: 20px;
	font-size: 10px;
	align-items: center;
	justify-content: center;
	height: 20px;
	font-weight: 600;
	line-height: 12px;
	margin: 2px 0 auto 5px;
}

.notificationColor {
	background-color: var(--client-notification-color);
	color: var(--client-notification-inside-color);
}

.accentColor {
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
}

.zeroAmountCircle {
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
}

.spacedRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}

.editIcon,
.okIcon,
.cancelIcon {
	padding: 5px;
	cursor: pointer;
	width: 32px;
	height: 32px;
}

.editIcon:hover,
.okIcon:hover,
.cancelIcon:hover {
	box-shadow: 0 1px 6px #00000029;
	border-radius: 8px;
}

.loadingIcon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.editIcon svg,
.loadingIcon svg {
	stroke: var(--module-box-icon-color);
	fill: none;
}

.cancelIcon svg {
	stroke: var(--invalid-color);
	fill: none;
	cursor: pointer;
}

.okIcon svg {
	color: var(--valid-color);
	width: 100%;
	height: 100%;
}

.input {
	width: 100%;
	height: 100%;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	border-radius: 6px;
}

.input:focus {
	outline: 0.7px solid var(--chosen-border-color);
}

.pillButton {
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	border-radius: 32px;
	padding: 5px 15px;
	min-width: 115px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.pillButton:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.editCustomOrderReferenceWrapper {
	font-size: 16px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.editCustomOrderReferenceWrapper label {
	flex: 1;
	height: 62px;
}

.vatPolicyToggleWrapper {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
}

.vatPolicyToggleText {
	color: var(--module-box-text-color);
}

.transportationMethod {
	color: var(--module-box-text-color);
	border-radius: 6px;
	background-color: var(--module-box-color);
	padding: 15px;
	margin-top: 15px;
	margin-bottom: 10px;
	font-size: 16px;
}

@media (max-width: 1100px) {
	.headerAligner {
		width: 66px;
	}
}

@media (max-width: 710px) {
	.tabSelector {
		font-size: 16px;
	}
}

@media (max-width: 500px) {
	.productInfoBox {
		grid-template-areas:
			"infoText    image"
			".    amount";
		grid-template-columns: 1fr 1fr;
		height: auto;
	}

	.productInfoBox > div:nth-of-type(1) {
		grid-area: infoText;
	}

	.productInfoBox > div:nth-of-type(2) {
		grid-area: amount;
		margin: 0 auto;
	}

	.productInfoBox > div:nth-of-type(3) {
		grid-area: image;
	}
}

@media (max-width: 270px) {
	.productInfoBox {
		grid-template-areas:
			"infoText"
			"image"
			"amount";
		grid-template-columns: 1fr;
	}
}
