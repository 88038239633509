.sidebar {
	position: fixed;
	right: 0;
	background-color: var(--sidebar-color);
	z-index: 996;
	top: 100px;
	height: calc(var(--100vh) - 100px);
	width: 350px;
	padding-left: 25px;
	padding-right: 35px;
	padding-bottom: 15px;
}

.headerText {
	font-size: 28px;
	font-weight: 600;
	color: var(--sidebar-text-color);
	margin-left: 10px;
}

.backdrop {
	position: fixed;
	top: 100px;
	left: 0;
	width: var(--100vw);
	height: var(--100vh);
	z-index: 990;
	background-color: rgba(0, 0, 0, 0.55);
	backdrop-filter: blur(2px);
}

.closeButtonWrapper {
	display: flex;
	height: 55px;
	justify-content: space-between;
	padding-top: 15px;
	position: sticky;
	background-color: var(--sidebar-color);
	top: 0;
	align-items: center;
}

.closeX {
	line-height: 2em;
	width: 2em;
	height: 2em;
	border-radius: 1em;
	background-color: transparent;
	cursor: pointer;
	text-align: center;
	z-index: 1100;
}

.closeXIcon {
	width: unset !important;
	height: unset !important;
	display: block !important;
	margin-top: 2px;
}

.closeXIconInside {
	stroke: var(--sidebar-icon-color);
	fill: none;
}

.optionsHeader {
	font-size: 20px;
	padding: 10px 0 10px 10px;
	cursor: pointer;
}

.optionsHeader:hover {
	background-color: var(--sidebar-hover-color);
	border-radius: 12px;
}

.selectionItemsWrapper {
	margin-bottom: 10px;
	overflow-y: auto;
	height: calc(100% - 210px);
}

.unselectOptionButton {
	height: 20px;
	font-size: 12px;
	vertical-align: middle;
	box-shadow: none;
	border: none;
	cursor: pointer;
	color: var(--sidebar-text-color);
	border-radius: 6px;
}

.clearAllFiltersButtonWrapper {
	display: flex;
	justify-content: flex-end;
}

.clearAllFiltersButton {
	background-color: transparent;
	border: 1px solid black;
	font-size: 14px;
	border-radius: 6px;
	cursor: pointer;
	color: var(--sidebar-text-color);
}

.clearAllFiltersButton:disabled {
	cursor: not-allowed;
}

.dateSelectInput {
	font-weight: 600;
	border-style: none;
	border-radius: 1000px; /*Capsule shape*/
	cursor: pointer;
	opacity: 80%;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	padding-left: 10px;
	font-size: 12px;
	height: 30px;
}

.datePickersWrapper {
	display: flex;
	gap: 5px;
	align-items: center;
	flex-wrap: wrap;
}

:global(.react-datepicker-wrapper),
.dateSelectInput {
	width: 105px;
}

.backArrow path {
	fill: var(--sidebar-icon-color);
}

.finalizeButton {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.finalizeButtonClear {
	position: absolute;
	bottom: 50px;
	width: 100%;
	background-color: var(--header-color);
}

.input {
	width: 16px;
	height: 16px;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid var(--sidebar-icon-color);
	-webkit-appearance: none;
	cursor: pointer;
	flex-shrink: 0;
}

.input + label {
	cursor: pointer;
}

.input:checked {
	background-color: var(--sidebar-chosen-color);
	border: none;
}

.searchInputWrapper {
	display: flex;
	margin-bottom: 20px;
}

.searchInputWrapper input {
	width: 100%;
	height: 35px;
	border-top-right-radius: 22px;
	border-bottom-right-radius: 22px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	border-left: none;
	color: var(--input-field-inside-color);
	background-color: var(--input-field-color);
	-webkit-appearance: none;
}

.searchInputWrapper input::-webkit-textfield-decoration-container {
	margin-right: 5px;
}

.searchInputWrapper input:focus-visible {
	outline: none !important;
}

.searchInputWrapper > span {
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	border-top-left-radius: 22px;
	border-bottom-left-radius: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--input-field-inside-color);
	background-color: var(--input-field-color);
}

.searchInputWrapper > span > svg {
	width: 20px;
	margin-left: 10px;
	margin-right: 10px;
}

.optionsHeader {
	color: var(--sidebar-text-color);
}

.optionsHeader > svg {
	color: var(--sidebar-icon-color);
}

.selectionItemsWrapper label {
	color: var(--sidebar-text-color);
}

.selectionItemsWrapper > div {
	margin-bottom: 20px;
}

.wasteIcon {
	fill: var(--sidebar-icon-color);
	stroke: var(--sidebar-icon-color);
}

.navigationArrow path {
	fill: var(--sidebar-icon-color);
}

@media (max-width: 1100px) {
	.sidebar {
		top: 60px;
		height: calc(calc(var(--100vh)) - 60px);
	}

	.backdrop {
		top: 60px;
	}
}

@media (max-width: 360px) {
	.sidebar {
		width: 300px;
	}
}

@media (max-width: 300px) {
	.sidebar {
		width: 220px;
	}
}
