.modal {
	max-height: calc(var(--100vh) * 0.85);
}

.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.wrapper form {
	display: flex;
	height: 100%;
	flex-direction: column;
}

.wrapper form > button {
	margin-top: auto;
}

.newSendInviteModalHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.newSendInviteModalInputField {
	width: calc(100% - 2px);
	margin-left: 1px;
	height: 2.625em;
	border-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	margin-bottom: 10px;
}
.newSendInviteModalInputField:focus,
.newSendInviteModalInputTextArea:focus {
	outline: 0.7px solid var(--chosen-border-color);
}

.newSendInviteModalInputTextArea {
	width: 100%;
	height: 5em;
	border-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	margin-bottom: 10px;
	padding-top: 1rem;
}

.newSendInviteModalInputField:disabled {
	cursor: not-allowed;
}

.newInviteButton {
	background-color: var(--accent-color);
	border-radius: 40px;
	border: 0;
	margin-bottom: 1rem;
	height: 42px;
	padding: 10px 15px;
	color: var(--accent-inside-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.newInviteButton:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
	cursor: pointer;
}

.newInviteButtonText {
	font-size: 16px;
	font-weight: 600;
}

.newInviteButton:hover .newInviteButtonContent {
	color: var(--accent-hover-inside-color);
}

.finalize {
	min-height: 42px;
}

@media (max-width: 710px) {
	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}
}
