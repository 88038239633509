.orderHeading {
	font-size: 20px;
	font-weight: bold;
	margin-top: 2.5rem;
	margin-bottom: 1rem;
	color: var(--text-color-1);
}

.wrapper {
	color: var(--text-color-1);
	background-color: var(--main-background-color);
	max-width: 900px;
	margin: 0 auto;
	margin-bottom: 5rem;
}

.sendButton {
	background-color: var(--finalize-color);
	color: var(--finalize-inside-color);
	margin-top: 1rem;
	width: 100%;
	font-weight: bold;
	font-size: 16px;
	border-style: none;
	border-radius: 6px;
	padding-top: 0.9rem;
	padding-bottom: 0.9rem;
	margin-bottom: 0.7rem;
}

.footerText {
	text-align: center;
	line-height: 1.1rem;
	color: var(--text-color-3);
	font-size: 13px;
	font-weight: 500;
}

.lottieWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.lottieHeaderWrapper {
	width: 60%;
	display: flex;
	justify-content: center;
	justify-self: center;
}

.lottieHeader {
	width: 60%;
	margin: 0 auto;
	color: var(--finalize-color);
	font-size: 3.2rem;
	text-align: center;
	font-weight: bold;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	display: flex;
	justify-content: center;
}

.lottieText {
	font-size: 2rem;
	text-align: center;
}

.lottieAnimation {
	color: var(--accent-color);
	height: clamp(100px, 36vh, 500px);
}

@media (max-width: 768px) {
	.orderHeading {
		margin-top: 1rem;
		margin-bottom: 1.3rem;
	}

	.wrapper {
		margin: 0 1rem;
		margin-bottom: 5rem;
	}

	.sendButton {
		margin-top: 1rem;
		padding-bottom: 0.6rem;
		padding-top: 0.6rem;
	}

	.lottieHeader {
		width: 90%;
		font-size: 1.8rem;
	}

	.lottieText {
		font-size: 1rem;
		width: 90%;
		margin: 0 auto;
	}

	.lottieAnimation {
		height: clamp(100px, 20vh, 300px);
	}
}
