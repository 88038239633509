.background {
	width: 100%;
	height: var(--100vh);
	overflow-y: auto;
	margin: 0;
	background-color: #381533;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container {
	/*width: clamp(500px, 80vw, 792px);*/
	max-width: 792px;
	font-size: clamp(24px, 4vmin, 30px);
	margin: 0 12px 100px;
}

.buttonWrapper {
	margin: 30px 0;
}

.container button {
	background-color: #7e5081;
	color: white;
	display: inline-block;
	height: 3em;
	width: 100%;
	margin: 20px 0;
	border: 0;
	border-radius: 1000px;
	font-size: 0.667em;
	font-weight: 600;
	cursor: pointer;
}

.container button[disabled] {
	cursor: not-allowed;
	filter: brightness(80%);
}

button.secondary {
	background-color: #befcda;
	color: black;
}

.logo {
	width: 160px;
	margin-top: 1.667em;
	margin-bottom: 3.333em;
}

.topImage {
	max-width: 240px;
	max-height: 8.267em;
	aspect-ratio: 1/1;
	color: #7bd2b4;
}

.container h3 {
	color: #fff400;
	font-size: 0.733em;
	font-weight: normal;
}

.container h1 {
	color: white;
	font-size: 1.333em;
	font-weight: bold;
}

.container h2 {
	color: white;
	font-size: 1.167em;
	font-weight: 600;
}

.bullet {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.2em;
	margin: 1.2em 0;
}

.bullet:last-child {
	margin-bottom: 10px;
}

.bullet svg {
	flex-shrink: 0;
}

.bullet p {
	margin: 12px 0;
}

.arrowBox {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	padding-left: 50px;
	gap: 40px;
}

.psst {
	color: #7e5081;
	font-size: 0.833em;
	font-weight: bold;
}

.psst div {
	color: white;
}

.formSection {
	background: #4a2345;
	border-radius: 6px;
	box-shadow: 0 3px 6px #00000029;
	padding: clamp(12px, 3vw, 26px);
	margin-bottom: 34px;
	max-height: 1000px;
	transition: all ease 0.5s, opacity ease 0.2s 0.3s;
}

.formSection.hide {
	visibility: hidden;
	max-height: 0;
	opacity: 0;
	margin: 0;
	padding: 0;
	transition: all ease 0.5s, opacity ease 0.2s;
}

.sectionHeader {
	margin-bottom: 0.667em;
}

.sectionHeader div {
	display: inline-block;
}

.icon {
	width: 2.667em;
}

.icon svg {
	width: 1.8em;
	aspect-ratio: 1/1;
	color: #7bd2b4;
	fill: #7bd2b4;
}

.sectionHeader h1 {
	font-size: 0.933em;
	font-weight: 600;
	margin: 0;
}

.sectionHeader h1 + p {
	font-size: 0.533em;
	font-weight: normal;
	margin: 0;
}

.sectionInputs {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	column-gap: 1.533em;
}

.formSection label {
	display: block;
	font-size: 0.68em;
	font-weight: 600;
	color: white;
}

.formSection label input,
.formSection label textarea {
	margin: 8px 0;
	width: 100%;
	color: white;
	/*height: 3em;*/
	/*font-size: 0.7em;*/
	padding: 0.7em;
	background: #5f3b5a;
	border: 0;
	border-radius: 6px;
}

.sectionSelectCards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
	gap: 12px;
}

.selectableCard {
	position: relative;
	background: #4a2344;
	border: 1px solid #4a2344;
	box-shadow: 0 3px 6px #00000029;
	border-radius: 11px;
	min-height: 98px;
	padding: 12px 68px 12px 12px;
	font-size: 0.9em;
	font-weight: 500;
	transition: all ease 0.2s;
}

.selectableCard:hover {
	filter: brightness(95%);
}

.selectableCard.selected {
	border: 1px solid #ffffff;
}

.selectableCard svg {
	position: absolute;
	color: #befcda;
	height: 90px;
	right: 8px;
	top: 4px;
}

.radioGradient {
	display: grid;
	grid-template-columns: 2fr repeat(5, 1fr) 2fr;
	align-items: end;
	gap: 2px;
	margin-top: 1.467em;
}

.radioGradient *:first-child {
	text-align: end;
}

.radioGradient input[type="radio"] {
	appearance: none;
	border: 5px solid #befcda;
	width: 1.1em;
	height: 1.1em;
	border-radius: 50%;
	display: grid;
	place-content: center;
	margin-top: 0.9em;
	background-color: unset;
	padding: unset;
}

.radioGradient input[type="radio"]::before {
	content: "";
	display: block;
	width: 0.5em;
	height: 0.5em;
	border-radius: 50%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em #befcda;
}

.radioGradient input[type="radio"]:checked::before {
	transform: scale(1);
}

.sectionRadioGradient label {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1em;
	font-weight: 600;
}

.ratingStars {
	display: flex;
	align-items: flex-end;
	gap: 2px;
}

.star {
	width: clamp(40px, 10vmin, 50px);
	aspect-ratio: 1/1;
	color: #00000000;
	--star-border: #befcda;
	transition: all ease 0.4s;
}

.starFilled {
	color: #ddfdec;
	transition: all ease 0.4s;
}

.star:hover {
	filter: brightness(110%);
}

.privacyNotice {
	color: #fff400;
	font-size: 0.667em;
	margin: 20px 0;
	font-weight: normal;
	text-align: left;
	display: block;
	text-decoration: none;
}
