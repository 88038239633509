.mbactH1 {
	margin: 0;
	font-size: 40px;
	color: var(--main-background-text-color);
}

@media (max-width: 1100px) {
	.mbactH1 {
		font-size: 20px !important;
	}
}
