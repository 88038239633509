.incrementor {
	display: flex;
	align-items: center;
}

.incrementor button {
	cursor: pointer;
	border-radius: 50%;
	padding: 0;
	border: 0;
	width: 1.2em;
	height: 1.2em;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
}

.incrementor button:first-child {
	margin-right: 4px;
}

.incrementor button:last-child {
	margin-left: 4px;
}

.incrementor button:disabled,
.incrementor button[disabled] {
	pointer-events: none;
	filter: brightness(1.02);
}

.incrementor input {
	width: 30px;
	border: 0;
	text-align: center;
	background-color: var(--main-background-color);
	color: var(--text-color-1);
	-moz-appearance: textfield;
}

.incrementor input:focus::placeholder {
	color: transparent;
}

.incrementor input::-webkit-outer-spin-button,
.incrementor input::-webkit-inner-spin-button {
	appearance: none;
}

.incrementorField {
	position: relative;
	color: var(--accent-inside-color);
}

.nestedInput {
	border: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

.errorField {
	box-shadow: 0 0 2px 2px var(--validation-error);
}

.wrapper {
	max-width: 140px;
	height: 40px;
	width: 100%;
	justify-self: end;
	border: 0.125em solid var(--main-background-color);
	border-radius: 1000px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--main-background-color);
	opacity: 1;
}

.noWrapper {
	border: none !important;
	width: 35px !important;
	height: 35px;
	margin-right: 1px;
	background-color: transparent;
}

.wrapper,
.noWrapper,
.buttons,
.value {
	transition: width 0s, opacity 0.2s ease-in-out;
}

.buttons {
	aspect-ratio: 1/1;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	border-radius: 1000px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-color: transparent;
	font-size: 26px;
	font-weight: 600;
	cursor: pointer;
	height: 34px;
	width: 34px;
	padding: 6px 1px;
}

.buttons:not(:disabled):not([disabled]):hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.buttons:disabled {
	cursor: not-allowed;
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
}

.value {
	font-size: 1.25em;
	font-weight: 500;
	color: var(--module-box-text-color);
	cursor: default;
	width: 60px;
	display: block;
	text-align: center;
	border: none;
	background: none;
}

.value::-webkit-outer-spin-button,
.value::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.dNone {
	overflow: hidden;
	opacity: 0;
	height: 0 !important;
	width: 0 !important;
	border: none;
	display: none;
}

input[type="number"].dNone {
	padding: 0;
}
