@value desktopMinWidth, mobileMaxWidth from "./../../Responsiveness/breakpoints.module.css";

.customStyles h1 {
	font-size: 48px;
}
.customStyles h2 {
	font-size: 40px;
	margin: 0.2em;
}
.customStyles h3 {
	font-size: 28px;
	margin: 0.2em;
}
.customStyles h4 {
	font-size: 20px;
}
.customStyles p {
	font-size: 16px;
}

@media (max-width: calc(mobileMaxWidth - 1px)) {
	.customStyles h1 {
		font-size: 32px;
	}
	.customStyles h2 {
		font-size: 28px;
	}
	.customStyles h3 {
		font-size: 20px;
	}
	.customStyles h4 {
		font-size: 16px;
	}
	.customStyles p {
		font-size: 14px;
	}
}

/* ContentAndImageWidget */
.sixtyFortySplit {
	display: grid;
	grid-template-columns: 60% 40%;
	max-width: 1600px;
	margin: 0 auto;
}

.sixtyFortySplit.flipOrder {
	grid-template-columns: 40% 60%;
}

.flipOrder .contentPart {
	order: 2;
}

.flipOrder .imagePart {
	order: 1;
}

@media (max-width: 760px) {
	.sixtyFortySplit,
	.sixtyFortySplit.flipOrder {
		grid-template-columns: 100%;
	}

	.sixtyFortySplit .contentPart {
		order: 1;
	}

	.sixtyFortySplit.flipOrder .contentPart {
		order: 2;
	}
}

/* TopContentBottomBoxes */
.boxesWrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;
	margin-left: 20px;
	margin-right: 20px;
}

.box {
	min-height: 200px;
	max-height: 500px;
	display: flex;
	flex-direction: column;
	min-width: 160px;
	max-width: 350px;
}

.box p {
	margin-top: 0;
	margin-bottom: 0;
}

.box.noTopHalf {
	height: auto;
	grid-template-rows: 100%;
}

.topHalf {
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	max-height: 155px;
	min-height: 155px;
}

.topHalf img {
	width: 100%;
	height: 80%;
	object-fit: contain;
	padding: 10px;
}

.bottomHalf {
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	font-size: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
	padding: 10px 15px 25px;
}

.sidewaysContent .bottomHalf {
	padding: 25px 15px 25px;
}

.bottomHalf .header {
	font-size: 24px;
	font-weight: 600;
	word-break: break-word;
}

.bottomHalf .body {
	margin-top: 5px;
	white-space: pre-line;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.clickableBox:hover {
	cursor: pointer;
	box-shadow: 0 0 9px 2px #00000029;
	border-radius: 12px;
}

@media (min-width: 711px) {
	.box.sidewaysContent {
		flex-direction: row;
		max-width: 100%;
	}

	.sidewaysContent .topHalf {
		border-radius: 12px 0 0 12px;
		max-height: 500px;
		max-width: 500px;
		width: 100%;
	}

	.sidewaysContent .bottomHalf {
		border-radius: 0 12px 12px 0;
		max-height: 500px;
		max-width: 500px;
		width: 100%;
		height: auto;
		padding-left: 12%;
	}

	.sidewaysContent {
		width: auto !important;
	}
}

@media (max-width: 710px) {
	.box.sidewaysContent {
		width: 100% !important;
	}

	.boxesWrapper {
		margin-left: 8px;
		margin-right: 8px;
	}

	.boxesWrapper > .box {
		width: calc(33% - 10px);
	}
}

@media (max-width: 530px) {
	.boxesWrapper > .box {
		width: calc(50% - 10px);
	}
}

@media (max-width: 360px) {
	.boxesWrapper > .box {
		width: calc(100% - 10px);
	}
}

/* CallToActionWidget */

.callToActionButtonsWrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	row-gap: 10px;
	column-gap: 10px;
	padding-bottom: 60px;
	padding-left: 20px;
	padding-right: 20px;
}

@media (max-width: 710px) {
	.callToActionButtonsWrapper {
		padding-left: 8px;
		padding-right: 8px;
	}

	.callToActionButtonsWrapper a:first-child:nth-last-child(3) ~ a {
		max-width: unset;
	}

	.callToActionButtonsWrapper a:only-child,
	.callToActionButtonsWrapper a:first-child:nth-last-child(3) {
		max-width: unset;
		flex: 0 1 100%;
	}
}
