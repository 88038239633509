.modal {
	height: 700px;
	max-height: calc(var(--100vh) * 0.85);
}

.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
}

.fields {
	height: auto;
	overflow-y: scroll;
	margin-bottom: 10px;
	grid-auto-rows: auto !important;
	grid-template-rows: unset;
	gap: 12px;
	padding-bottom: 2px;
}

.fields > label {
	height: auto !important;
}

.finalize {
	margin-top: auto;
	min-height: 42px;
}

.profilePicture {
	width: 56px;
	height: 56px;
	background-color: #6f6f70;
	border-radius: 100px;
	display: block;
	overflow: hidden;
	position: relative;
}

.profilePicture > img {
	width: 56px;
	height: 56px;
	border-radius: 100px;
	object-fit: cover;
}

.item {
	cursor: pointer;
	display: grid;
	grid-template-columns: 75px auto 110px;
}

@media (max-width: 710px) {
	.item {
		grid-template-columns: 75px auto;
	}

	.item > button {
		display: none;
	}

	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}

	.fields {
		max-height: unset;
	}
}
