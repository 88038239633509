* {
	font-family: "hk-grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-overflow-scrolling: touch;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

html,
body {
	font-size: 16px;
	height: calc(100%);
}

*::-webkit-scrollbar {
	background-color: transparent;
	width: 13px;
}

*::-webkit-scrollbar-track {
	background-color: #fafafa;
	border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
	background-color: #c7c7c7;
	border-radius: 16px;
	border: 3px solid #fafafa;
}

*::-webkit-scrollbar-button {
	display: none;
}

@media (min-resolution: 150dpi) and (min-width: 1101px) {
	body {
		zoom: 65%;
	}
}

@media (min-resolution: 180dpi) and (min-width: 1101px) {
	body {
		zoom: 75%;
	}
}

.oneLineClamp {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
	word-break: break-all;
}

.twoLineClamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
	word-break: break-all;
}

.collapsible-wrapper {
	width: 100%;
	user-select: none;
	max-width: 450px;
}

.collapsible-wrapper .collapsible-title {
	display: flex;
	justify-content: space-between;
	background-color: var(--collapsible-title-background-color);
	color: var(--collapsible-title-color);
	padding: 10px 15px 10px 10px;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	line-height: 18px;
	align-items: center;
}

.collapsible-title .collapsible-toggle-icon {
	font-size: 20px;
}

.collapsible-wrapper .collapsible-content {
	padding: 15px;
	margin-top: 5px;
	background-color: var(--collapsible-content-background-color);
	color: var(--collapsible-content-color);
	border-radius: 6px;
	user-select: none;
}

.collapsible-wrapper-closed .collapsible-toggle-icon-open {
	display: none;
}

.collapsible-wrapper-open .collapsible-toggle-icon-closed {
	display: none;
}

.collapsible-wrapper-closed .collapsible-content {
	display: none;
}
