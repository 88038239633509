.wrapper {
	overflow-y: auto;
	background-color: var(--section-background-color);
	padding: 3rem;
	border-radius: 12px;
	max-width: 600px;
	width: 100%;
	min-height: 100px;
	margin-left: auto;
	margin-right: auto;
}

.formRow {
	display: grid;
	grid-template-columns: 48% 48%;
	column-gap: 1rem;
}
.formInputSection {
	flex-grow: 1;
}

.formInput {
	width: 100%;
	height: 2.625em;
	border-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	margin-bottom: 10px;
}

.senderMessageArea {
	background-color: var(--input-field-color);
	min-height: 6rem;
	width: 100%;
	padding: 0.5rem;
	margin-top: 0.5rem;
	border-radius: 6px;
	text-align: left;
}

.profilePic {
	width: 60px;
	min-width: 60px;
	height: 60px;
	min-height: 60px;
	overflow: hidden;
	border-radius: 100px;
	cursor: pointer;
	background-color: rgb(165, 165, 165);
	position: relative;
}

.profilePic > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.noProfilePic {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
}

.presentConsumer {
	width: 100%;
	display: flex;
	flex-flow: row;
	background-color: var(--module-box-color);
	margin-top: 0.5rem;
	border-radius: 6px;
	padding-left: 0.25rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.presentConsumerNoBg {
	width: 100%;
	display: flex;
	flex-flow: row;
	margin-top: 0.5rem;
	border-radius: 6px;
	padding-left: 0.25rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.currentlyLinkedConsumers {
	max-height: 200px;
	overflow-y: scroll;
	margin-bottom: 1rem;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.currentlyLinkedConsumers::-webkit-scrollbar {
	display: none;
}
