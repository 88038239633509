.wrapper {
	display: grid;
	grid-template-columns: 0.5fr minmax(0, 6fr) minmax(0, 6fr);
	align-content: center;
	column-gap: 30px;
}

.projectInfoBox {
	margin-bottom: 15px;
	border-radius: 6px;
	background-color: var(--module-box-color);
	padding: 10px;
}

.projectInfoBox h5 {
	font-weight: 600;
}

.dateAndTimeInfoWrapper {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: 25px;
	column-gap: 15px;
}

.dateAndTimeInfoWrapper p {
	font-size: 17px;
}

.dateAndTimeInfoWrapper > div {
	display: flex;
	align-items: center;
}

.dateAndTimeInfoIcon,
.timeIcon {
	color: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
	stroke-width: 2px;
}

.timeIcon {
	width: 18px;
	height: 18px;
}

.dateAndTimeInfoText {
	margin-left: 6px;
	font-weight: 600;
	color: var(--module-box-text-color);
}

.reviewSection {
	padding: 25px 24px 42px;
	border-radius: 6px;
	background-color: var(--section-background-color);
	margin-bottom: 15px;
}

.sectionHeader {
	margin-bottom: 45px;
}

.sectionHeader p {
	margin-top: 0;
}

.commentForClient {
	margin-top: 35px;
}

.productInfoBox {
	border-radius: 6px;
	background-color: var(--module-box-color);
	padding: 10px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: minmax(auto, 1000px) auto 140px;
	min-height: 100px;
	height: auto;
}

.productInfoBox p {
	font-size: 16px;
}

.productInfoBoxMarkingText p {
	font-weight: 600;
}

.tableCellWrapper {
	display: table-cell;
	vertical-align: middle;
}

.orderItemProductAmount {
	display: flex;
	justify-content: space-evenly;
	border-radius: 50px;
	background-color: var(--confirmed-amount-color);
	font-size: 20px;
	font-weight: 600;
	color: var(--confirmed-amount-inside-color);
	padding: 2px 10px;
	min-width: 85px;
}

.basketItemProductImage {
	display: flex;
	height: 100%;
	max-height: 80px;
	max-width: 80px;
	width: auto;
	aspect-ratio: 1/1;
	margin: 0 auto;
	margin-right: 10px;
}

.basketItemProductImage > img {
	max-height: 80px;
	max-width: 80px;
}

.deliveryInfoInput {
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border-radius: 4px;
	padding-top: 12px;
	padding-left: 12px;
	width: 100%;
	border: none;
}

.deliveryInfoInput:focus-visible {
	outline: var(--chosen-border-color) 0.7px solid;
}

.projectSelectionDropdown {
	margin-bottom: 15px;
}

.summarySection {
	padding: 25px 24px 42px;
	border-radius: 4px;
	background-color: var(--section-background-color);
}

.priceSummarySection {
	margin-top: 10px;
}

.termsAndConditionsSection {
	padding: 20px;
	border-radius: 4px;
	background-color: var(--section-background-color);
	margin-top: 20px;
	margin-bottom: 15px;
}

.termsLink,
.termsLink:visited,
.termsLink:active {
	color: var(--section-background-text-color);
	text-decoration: underline;
	font-weight: 600;
}

.basketCollapseButton {
	border-radius: 200px;
	width: 40px;
	height: 40px;
	border: none;
	cursor: pointer;
	padding: 0;
	color: black;
	background-color: transparent;
}

.basketCollapseButtonIcon {
	padding-top: 5px;
	width: 25px;
	height: 25px;
}

.timeline {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-inline-start: 0;
}

.timelineLine,
.timelineLineComplete {
	margin-left: 5px;
	margin-right: 5px;
	padding: 0 20px;
}

.timelineLine {
	border-top: 3px solid var(--disable-color);
}

.timelineLineComplete {
	border-top: 3px solid var(--confirmed-amount-color);
}

.timeline > li > div:before {
	content: "";
	display: block;
	width: 15px;
	height: 15px;
	background-color: var(--disable-color);
	border-radius: 25px;
}

.timeline > li.complete > div:before {
	background-color: var(--confirmed-amount-color);
	border: 2px solid var(--confirmed-amount-color);
}

.timeline > li.current > div:before {
	background-color: transparent;
	border: 2px solid var(--confirmed-amount-color);
}

.backArrowAndProgressBar ul {
	display: none;
}

.backArrow {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.backArrow svg,
.backArrow svg path {
	fill: var(--main-background-text-color);
}

.sectionHeader > h1 {
	margin: 0;
	font-size: 40px;
	color: var(--main-background-text-color);
}

.articleTextWrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.licenseToggleWrapper {
	margin-bottom: 25px;
	display: flex;
	align-items: center;
}

.licenseToggleUrl {
	margin-left: 20px;
	word-break: break-word;
	hyphens: auto;
	color: var(--section-background-text-color);
}

.licenseToggleText {
	margin-left: 20px;
	word-break: break-word;
	color: var(--section-background-text-color);
}

.orderItemZone {
	background-color: var(--module-box-color);
	border-radius: 10px;
	display: flex;
	padding: 10px;
	justify-content: space-between;
	height: 65px;
	align-items: center;
}

.zoneMapIcon {
	fill: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
}

.orderItemProductToggleSection {
	display: flex;
	justify-content: space-between;
	background-color: var(--module-box-color);
	padding: 10px;
	align-items: center;
	margin-bottom: 10px;
	border-radius: 6px;
}

.orderItemProductToggleSection > div {
	display: flex;
	gap: 20px;
}

.productCategoryImage {
	width: 25px;
	max-height: 80px;
	fill: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
	stroke-width: 0.3px;
	margin: 0 auto;
}

.productCategoryImage path[data-name="stroke"] {
	stroke: var(--section-icon-color);
}

.orderItemProductToggleCategoryIcon {
	position: relative;
	background-color: var(--module-box-color);
	border: thin solid #939393;
	border-radius: 100px;
	padding: 5px;
	width: 40px;
	height: 40px;
	display: flex;
}

.customerInfoWrapper {
	background-color: var(--module-box-color);
	border-radius: 4px;
	padding: 10px 10px 10px 20px;
	display: none;
}

.customerInfo {
	color: var(--module-box-text-color);
	word-break: break-all;
}

.paymentContainer {
	display: block;
	margin-top: 20px;
	background-color: var(--section-background-color);
	padding: 25px 24px 42px;
	border-radius: 6px;
}

.klarnaContainer {
	display: none;
	padding: 10px;
	margin-top: 20px;
	background-color: var(--module-box-color);
}

.klarnaContainer.visible {
	display: block;
}

.paymentContainer .inputWrapper {
	display: flex;
	gap: 15px;
}

.paymentContainer .inputWrapper label {
	color: var(--section-background-text-color);
}

.paymentContainer .inputWrapper > label > strong {
	font-size: 18px;
}

.paymentContainer .input {
	width: 28px;
	height: 28px;
	background-color: var(--section-background-color);
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid var(--section-icon-color);
	-webkit-appearance: none;
	cursor: pointer;
	flex-shrink: 0;
}

.paymentContainer .input:checked {
	background-color: var(--accent-color);
	border: none;
}

.paymentContainer .input:disabled {
	background-color: var(--disable-color);
	cursor: not-allowed;
}

.rightSide p,
.rightSide h1 {
	color: transparent !important;
}

.amountCellWrapper {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.mapContainer {
	width: 100%;
	height: 180px;
	margin-bottom: 5px;
}

.addressAndMiniMapWrapper {
	margin-top: 15px;
	margin-bottom: 15px;
	padding: 10px;
	background-color: var(--module-box-color);
	height: auto;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
}

.mapAndAddressDelimiter {
	margin-top: 20px;
	background-color: #bebebe;
	margin-bottom: 15px;
	width: 100%;
	height: 1px;
	border: none;
}

.orderItemDateAndTimeWrapper {
	display: grid;
	column-gap: 10px;
	row-gap: 15px;
	margin-bottom: 15px;

	--grid-layout-gap: 10px;
	--grid-column-count: 2;
	--grid-item--min-width: 220px;

	/**
	 * Calculated values.
	 */
	--gap-count: calc(var(--grid-column-count) - 1);
	--total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
	--grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

	grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
}

.orderItemDateAndTime {
	display: flex;
	border-radius: 6px;
	padding: 10px;
	gap: 10px;
	align-items: center;
	background-color: var(--module-box-color);
	color: var(--module-box-text-color);
}

.orderItemDateAndTime > span {
	font-weight: 500;
}

.addExactDeliveryWrapper,
.addOrderItemImagesWrapper {
	background-color: var(--module-box-color);
	padding: 10px;
	border-radius: 6px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 600;
}

.addOrderItemImagesWrapper {
	min-height: 48px;
}

.addExactDeliveryWrapper > span,
.addOrderItemImagesWrapper > span {
	color: var(--module-box-text-color);
}

.addExactDeliveryWrapper > button,
.addOrderItemImagesWrapper > .fileInput {
	-webkit-appearance: none;
	border: none;
	background-color: var(--accent-color);
	border-radius: 50px;
	padding: 5px 20px;
	font-weight: 600;
	cursor: pointer;
	height: 28px;
	width: 100px;
	min-width: 100px;
	color: var(--accent-inside-color);
}

.addExactDeliveryWrapper > button:hover,
.addOrderItemImagesWrapper > .fileInput:hover,
.addOrderItemImagesWrapper > .fileInput:hover > label {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.addOrderItemImagesWrapper > .fileInput > label {
	font-weight: 600;
	color: var(--accent-inside-color);
}

.addOrderItemImagesWrapper > .fileInput > label {
	cursor: pointer;
}

.addOrderItemImagesWrapper > .fileInput > input {
	display: none;
}

.orderItemSelectedImages {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

.orderItemSelectedImages .imageWrapper {
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: 120px;
	max-width: 120px;
	max-height: 120px;
	width: 120px;
	height: 120px;
	position: relative;
}

.imageWrapper img:hover {
	border: 1px solid var(--chosen-border-color);
}

.imageWrapper img {
	padding: 2px;
	border-radius: 16px;
	box-shadow: 0 1px 6px #00000029;
	cursor: pointer;
}

.imageWrapper .loader,
.imageWrapper .error {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #8080808f;
	border-radius: 16px;
	justify-content: center;
	align-items: center;
}

.imageWrapper .loader > svg {
	font-size: 26px;
	color: black;
}

.imageWrapper .error > svg {
	font-size: 26px;
	color: var(--invalid-color);
	cursor: pointer;
}

.errorText {
	color: var(--invalid-color);
	font-size: 14px;
	font-weight: 700;
	max-width: 120px;
	text-align: center;
}

.orderItemSelectedImages .imageDeleteIconWrapper {
	position: absolute;
	top: 5px;
	right: 5px;
	background-color: var(--module-box-color);
	border-radius: 100px;
	padding: 4px;
}

.orderItemSelectedImages .imageDeleteIconWrapper:hover {
	cursor: pointer;
	border: 1px solid black;
}

.orderItemSelectedImages .imageDeleteIconWrapper .imageDeleteIcon {
	stroke: var(--module-box-icon-color);
	fill: var(--module-box-icon-color);
}

.orderItemSelectedImages .imageWrapper > img {
	width: 100%;
	height: 100%;
}

.markingAndContactInfoText {
	font-size: 16px;
	color: var(--module-box-text-color);
}

.markingAndContactInfoText > span {
	height: 22px;
	display: flex;
	align-items: flex-end;
	line-height: 16px;
}

.markingAndContactInfoText svg {
	fill: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
}

.markingAndContactInfoText .contactIcon {
	min-width: 22px;
}

.address {
	font-size: 20px;
	color: var(--module-box-text-color);
}

.moduleBoxColor {
	background-color: var(--module-box-color);
	border-radius: 100px;
}

.moduleBoxIconColorStroke {
	stroke: var(--module-box-icon-color);
}

.moduleBoxIconColorFill {
	fill: var(--module-box-icon-color);
}

.editExactDeliveryActions {
	display: flex;
	gap: 15px;
	align-items: center;
}

.editExactDeliveryActions > div {
	cursor: pointer;
}

.stickyOnDesktop {
	position: sticky;
	top: 0;
	min-height: calc(var(--100vh) - 100px - 135px);
	max-height: calc(var(--100vh) - 100px - 50px);
	display: block;
	overflow-y: auto;
	padding-bottom: 65px;
}

.orderItemZone {
	background-color: var(--module-box-color) !important;
}

.alternativeTransportHeader {
	font-size: 20px;
	font-weight: 600;
	color: var(--section-background-text-color);
}

.beforeAndAfter {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	margin: 20px 0;
}

.beforeAndAfter .transportName {
	color: var(--section-background-text-color);
	font-size: 22px;
	margin-bottom: 10px;
}

.beforeAndAfter .navigationArrowWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px 0;
}

.beforeAndAfter .navigationArrow * {
	fill: var(--section-background-text-color);
}

.reviewSectionsWrapper {
	padding-bottom: 65px;
}

@media (max-width: 1100px) {
	.sectionHeader > h1 {
		font-size: 28px;
	}

	.reviewSection > h2,
	.summarySection > h2,
	.articleTextWrapper > h2,
	.paymentContainer > h2 {
		font-size: 20px;
	}

	.projectInfoBox > h5,
	.fields > label > h4 {
		font-size: 16px;
	}

	.projectInfoBox p,
	.productInfoBoxNameAndCategory > p {
		font-size: 14px;
	}

	.basketCollapseButton {
		width: 24px;
		height: 24px;
	}

	.basketCollapseButtonIcon {
		width: 20px;
		height: 20px;
	}

	.backArrowAndProgressBar {
		margin-bottom: 30px;
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-template-areas: unset;
	}

	.backArrowAndProgressBar ul {
		display: flex;
	}

	.wrapper {
		grid-template-areas:
			"arrow    arrow"
			"review    customerinfo";
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
	}

	.wrapper > div:nth-of-type(1) {
		grid-area: arrow;
	}

	.wrapper > div:nth-of-type(2) {
		grid-area: review;
	}

	.wrapper > div:nth-of-type(3) {
		grid-area: customerinfo;
	}

	.productInfoBox {
		grid-template-areas: "infoText    amount    image";
		height: auto;
	}

	.orderConfirm.productInfoBox {
		grid-template-areas:
			"infoText    image"
			".    amount";
		grid-template-columns: 1fr 1fr;
	}

	.orderConfirm.productInfoBox .amountCellWrapper {
		justify-content: end;
	}

	.productInfoBox > div:nth-of-type(1) {
		grid-area: infoText;
	}

	.productInfoBox > div:nth-of-type(2) {
		grid-area: amount;
		margin: 0 auto;
	}

	.productInfoBox > div:nth-of-type(3) {
		grid-area: image;
	}

	.stickyOnDesktop {
		min-height: calc(var(--100vh) - 60px - 135px);
		max-height: calc(var(--100vh) - 60px);
	}
}

@media (max-width: 852px) {
	.stickyOnDesktop {
		position: unset;
		top: unset;
		min-height: unset;
		max-height: unset;
		display: unset;
		overflow-y: unset;
		padding-bottom: unset;
	}

	.reviewSectionsWrapper {
		padding-bottom: unset;
	}

	.wrapper {
		grid-template-areas:
			"arrow"
			"review"
			"customerinfo";
		grid-template-columns: 1fr;
		column-gap: 0;
	}

	.reviewSection,
	.summarySection,
	.termsAndConditionsSection {
		max-width: unset;
	}

	.sectionHeader {
		margin-bottom: 20px;
	}

	.paymentContainer {
		max-width: unset;
	}

	.productInfoBox,
	.orderConfirm.productInfoBox {
		grid-template-areas: "infoText    amount    image";
		grid-template-columns: minmax(auto, 1000px) auto 140px;
	}

	.amountCellWrapper {
		justify-content: end;
	}

	.customerInfoWrapper {
		display: block;
	}
}

@media (max-width: 710px) {
	.reviewSection,
	.summarySection,
	.paymentContainer,
	.termsAndConditionsSection {
		padding-left: 12px;
		padding-right: 12px;
	}
}

@media (max-width: 500px) {
	.productInfoBox,
	.orderConfirm.productInfoBox {
		grid-template-areas:
			"infoText    image"
			".    amount";
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 460px) {
	.dateAndTimeInfoWrapper {
		grid-template-columns: 1fr;
	}
}

@media (max-width: 375px) {
	.btnIndividualOrCompanySwitch input[type="checkbox"]:checked + label.btnIndividualOrCompanySwitchInner:after {
		left: calc(100% - 106px);
	}

	.btnIndividualOrCompanySwitch > label.btnIndividualOrCompanySwitchInner {
		width: auto;
	}

	.basketItemProductImage,
	.basketItemProductImage > img {
		max-height: 50px;
		max-width: 50px;
	}
}

@media (max-width: 350px) {
	.orderConfirm.productInfoBox {
		grid-template-areas:
			"infoText"
			"image"
			"amount";
		grid-template-columns: 1fr;
	}

	.orderItemDateAndTimeWrapper {
		grid-template-columns: auto;
	}

	.addExactDeliveryWrapper,
	.addOrderItemImagesWrapper {
		flex-direction: column;
	}
}
