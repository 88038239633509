.wrapper {
	margin: 0;
	margin-bottom: 0.25em;
}

.message {
	color: var(--invalid-color);
	font-weight: 600;
	font-size: 0.875em;
}

.message:before {
	content: "\00a0";
}
