@media (max-width: 710px) {
	.finalize {
		position: absolute;
		bottom: 0;
	}

	.normalTimeslot {
		grid-template-columns: auto;
	}

	.normalTimeslot button {
		display: none;
	}

	.specificTimeslot {
		grid-template-columns: auto 8em !important;
	}
}

.normalTimeslot,
.specificTimeslot {
	cursor: pointer;
}

.timeSlotsWrapper {
	overflow-y: scroll;
	max-height: calc(calc(var(--100vh)) - 250px);
}

.timeSlotsWrapper::-webkit-scrollbar {
	display: none;
}

.timeSelectInput {
	justify-self: end;
}

.timeslotDescription {
	color: var(--module-box-text-color);
	font-weight: normal;
	font-size: 0.85em;
	margin: 0;
}
