.basketIcon {
	fill: var(--basket-main-icon-color);
	stroke: var(--basket-main-icon-color);
}

.mobileBasketMainHeaderText {
	padding: 0;
	margin: 0;
	font-size: 28px;
	color: var(--basket-main-text-color);
}

.regularBasketHeaderSection {
	background-color: var(--basket-main-color);
	margin: 0 -20px 0 -20px;
	padding: 20px 20px 0 20px;
	box-shadow: -3px 5px 4px #0000002c;
	z-index: 0;
	position: relative;
	min-height: 96px;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
}

.sectionTextColor {
	color: var(--basket-section-text-color);
}

.hr {
	height: 0;
	border-top: 1px solid #bebebe;
	margin-bottom: 16px;
}

.orderNumberAndButtonsWrapper {
	display: flex;
	justify-content: space-between;
}

.orderNumberText {
	display: flex;
	align-self: center;
	font-size: 20px;
	font-weight: normal;
}

.orderItemRemoveIconWrapper {
	border-radius: 100px;
	background-color: var(--basket-module-color);
}

.orderItemRemoveIcon {
	stroke: var(--basket-module-icon-color);
	fill: var(--basket-module-icon-color);
}

.toggleOrderItemButton {
	width: 34px;
	min-width: 34px;
	max-width: 34px;
	height: 34px;
	min-height: 34px;
	max-height: 34px;
	border-radius: 100px;
	background-color: var(--basket-module-color);
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-appearance: none;
	border: none;
	cursor: pointer;
}

.toggleOrderItemButton svg {
	min-width: 22px;
	min-height: 18px;
	color: var(--basket-module-icon-color);
}

.orderItemCityAndStreetWrapper {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #bebebe;
	padding-bottom: 8px;
	margin-bottom: 8px;
}

.orderItemCityAndStreetWrapper .streetAndCity {
	font-size: 20px;
	line-height: 34px;
	font-weight: normal;
}

.editOrderItemButton {
	background-color: var(--basket-section-color);
}

.editOrderItemButton svg {
	stroke: var(--basket-section-icon-color);
}

.basketItemSection {
	background-color: var(--basket-section-color);
	padding: 1em;
	border-radius: 10px;
	margin-bottom: 10px;
}

.selectedBasketItem {
	border-left: 6px solid var(--accent-color);
}

.projectSubTextWithIcon {
	display: flex;
	align-items: center;
	gap: 6px;
	font-size: 16px;
}

.projectSubTextWithIcon > span {
	height: 22px;
	display: flex;
	align-items: flex-end;
	line-height: 16px;
}

.projectSubTextWithIcon svg {
	fill: var(--basket-section-icon-color);
	stroke: var(--basket-section-icon-color);
}

.orderItemPriceSummaryRow {
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	color: var(--basket-section-text-color);
}

.orderItemPriceSummaryHeader {
	font-size: 16px;
	margin-bottom: 5px;
	color: var(--basket-section-text-color);
}

.orderItemPriceSummaryTotal,
.orderItemPriceSummaryTotalSubRow {
	display: flex;
	justify-content: space-between;
	color: var(--basket-section-text-color);
}

.orderItemPriceSummaryTotal {
	font-size: 20px;
	font-weight: 600;
}

.orderItemSelectionList {
	display: flex;
	gap: 10px;
	overflow-x: scroll;
}

.orderItemSelectionList::-webkit-scrollbar {
	display: none;
}

.orderItemSelectionListItem {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	min-height: 28px;
	gap: 10px;
}

.orderItemSelectionListItemContent span {
	font-size: 20px;
	line-height: 20px;
	color: var(--basket-main-text-color);
}

.orderItemSelectionListItemContent svg {
	color: var(--basket-main-icon-color);
}

.orderItemSelectionListItemContent {
	padding-left: 8px;
	padding-right: 8px;
	display: flex;
	gap: 5px;
	align-items: center;
}

.orderItemSelectionListItemIndicatorRow {
	background-color: var(--accent-color);
	height: 8px;
	width: 100%;
}

.orderItemRemoveAndToggleButtonsWrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
}

.orderItemTransportZone {
	margin-bottom: 15px;
}

.orderItemTransportZone div {
	color: var(--basket-module-text-color) !important;
}

.orderItemTransportZone svg {
	fill: var(--basket-module-icon-color) !important;
	stroke: var(--basket-module-icon-color) !important;
}

.regularBasketFinalize {
	position: relative;
	bottom: 0;
	width: auto;
	background-color: var(--basket-main-color);

	margin: 0 -20px 0 -20px;
	padding: 10px 20px 0 20px;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	box-shadow: 0 -3px 4px #0000002c;
}

.regularBasketFinalize span,
.basketMainTextColor {
	color: var(--basket-main-text-color);
}

.tooltipIcon {
	fill: var(--basket-main-icon-color);
}

.mobileBasketFinalizeButton {
	position: relative;
	margin-top: auto;
	margin-bottom: 0;
	background-color: var(--basket-main-color);
}

.arrowLeftIcon path {
	fill: var(--basket-main-icon-color);
	stroke: var(--basket-main-icon-color);
}

:global(.orderItemSelectionListSticky) {
	position: fixed;
	top: 60px;
	z-index: 998;
	background-color: var(--basket-main-color);
	width: 100%;
	left: 0;
	padding-top: 15px;
	padding-left: 20px;
	padding-right: 20px;
}

.errorsListWrapper {
	position: absolute;
	width: 100%;
	height: 250px;
	bottom: 92px;
	background-color: var(--basket-section-color);
	left: 0;
	box-shadow: 0 -4px 6px #00000029;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	padding: 20px 20px 0 20px;
	display: flex;
	flex-direction: column;
}

.errorsListWrapper.multipleOrders {
	bottom: 130px;
}

.errorsListWrapper.multipleOrders.pricesEnabled {
	bottom: 150px;
}

.errorsListHeader {
	display: flex;
	justify-content: space-between;
	color: var(--basket-section-text-color);
	margin-bottom: 15px;
}

.errorsListWrapper .closeIcon {
	cursor: pointer;
	border-radius: 100px;
	background-color: var(--basket-module-color);
	padding: 4px;
}

.errorsListWrapper .closeIcon svg {
	stroke: var(--basket-module-icon-color);
}

.errorsListWrapper .errorRows {
	overflow-y: auto;
	padding-bottom: 10px;
}

.errorsListWrapper .errorRow {
	background-color: var(--basket-module-color);
	border-radius: 12px;
	padding: 10px 15px;
	color: var(--basket-module-text-color);
	cursor: pointer;
}

.errorsListWrapper .errorRow:not(:last-of-type) {
	margin-bottom: 10px;
}

.errorRow:hover {
	border: 1px solid var(--chosen-border-color);
	padding: 9px 14px;
}

.errorRow .errorHeader {
	font-weight: 600;
}

.errorRow .errorDescription {
	font-size: 14px;
}

.transportationPriceElement {
	background-color: var(--basket-module-color);
	border-radius: 4px;
	padding: 15px;
	height: fit-content;
	min-height: 65px;
	align-items: center;
	display: flex;
	font-size: 16px;
	color: var(--basket-module-text-color);
	column-gap: 10px;
}

.transportationPriceElement > span {
	word-break: break-word;
	hyphens: auto;
}

@media (max-width: 1100px) {
	.errorsListWrapper,
	.errorsListWrapper.pricesEnabled {
		bottom: 118px;
	}

	.errorsListWrapper.multipleOrders:not(.pricesEnabled) {
		bottom: 156px;
	}

	.errorsListWrapper.multipleOrders.pricesEnabled {
		bottom: 178px;
	}
}
