.seeAllConsumersButton {
	height: 50px;
	display: flex;
	flex-flow: row;
	border-radius: 12px;
	max-width: 230px;
	margin-right: auto;
	color: var(--main-background-text-color);
	cursor: pointer;
	margin-bottom: 10px;
}

.seeAllConsumersButton:hover {
	background-color: var(--section-hover-color);
}

.seeAllConsumersButtonText {
	margin: auto 0.5rem;
}

.seeAllConsumersButton path {
	fill: var(--main-background-text-color);
}

.consumerManagerArea {
	gap: 16px;
	margin-bottom: 20px;
}

.noConsumerSelected.consumerManagerArea {
	display: flex;
	height: auto;
}

.noConsumerSelected.consumerManagerArea > div {
	max-height: unset;
}

.selectedConsumerArea {
	display: flex;
}

.selectedConsumerSection {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	gap: 10px;
}

.consumerInfoAndInvoicingInformation {
	margin-bottom: 60px;
	display: grid;
	grid-template-columns: calc(50% - 8px) calc(50% - 8px);
	gap: 16px;
}

.usersAndContactPersons {
	margin-bottom: 60px;
	display: grid;
	grid-template-columns: calc(45% - 8px) calc(55% - 8px);
	gap: 16px;
}

.selectedConsumerNavWrapper {
	display: flex;
	flex-direction: column;
}

.selectedConsumerNavWrapper .firstRow {
	display: flex;
	padding-left: 170px;
	position: relative;
	margin-bottom: 10px;
	flex-wrap: wrap-reverse;
	column-gap: 30px;
	row-gap: 10px;
}

.selectedConsumerNavWrapper .secondRow {
	background-color: var(--section-background-color);
	border-radius: 12px;
	margin-bottom: 45px;
}

.selectedConsumerNavWrapper .secondRow .horizontalScroll {
	display: flex;
	width: 100%;
	height: 64px;
}

.horizontalScrollWrapper {
	margin-left: 155px;
}

.secondRow .horizontalScroll .item {
	width: 150px;
	font-size: 22px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding-bottom: 8px;
	min-width: 150px;
}

.secondRow .horizontalScroll .item:hover {
	background-color: var(--section-hover-color);
}

.secondRow .horizontalScroll .selectedItem,
.secondRow .horizontalScroll .item:hover {
	padding-bottom: 0;
	border-bottom: 8px solid var(--accent-color);
}

.firstRow .profilePicture {
	width: 115px;
	height: 115px;
	background-color: var(--section-background-color);
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--section-background-text-color);
	font-size: 40px;
	position: absolute;
	left: 40px;
	bottom: -65px;
	box-shadow: 0 3px 6px #00000029;
}

.informationBoxesWrapper {
	display: flex;
	align-items: center;
	margin-left: auto;
	gap: 10px;
	flex-wrap: wrap;
}

.informationBox,
.informationBoxDropdownBox {
	border-radius: 12px;
	height: 45px;
	width: auto;
	padding: 0 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	font-size: 22px;
	font-weight: 600;
}

.informationBoxDropdownBox {
	gap: 10px;
	background-color: var(--accent-secondary-color);
	color: var(--accent-secondary-inside-color);
	cursor: pointer;
	justify-content: space-between;
}

.informationBoxDropdownBox svg {
	stroke: var(--accent-secondary-inside-color);
	height: 100%;
}

.informationBoxDropdownBox:hover {
	background-color: var(--accent-secondary-hover-color);
}

.informationBoxDropdownContent {
	position: absolute;
	top: 50px;
	right: 0;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	z-index: 2;
	width: var(--100vw);
	max-width: 220px;
}

.informationBoxDropdownItem {
	background-color: var(--accent-secondary-color);
	color: var(--accent-secondary-inside-color);
	padding: 10px 20px;
}

.informationBoxDropdownItem:first-of-type {
	border-radius: 12px 12px 0 0;
}

.informationBoxDropdownItem:last-of-type {
	border-radius: 0 0 12px 12px;
}

.informationBoxDropdownItem:hover {
	background-color: var(--accent-secondary-hover-color);
}

.profilePicture img {
	width: inherit;
	height: inherit;
	border-radius: inherit;
	object-fit: cover;
}

.firstRow .consumerName {
	font-size: 40px;
	font-weight: 600;
}

.consumerInfo {
	margin: 0 auto;
}

.projectsWrapper {
	width: 100%;
}

.ordersWrapper {
	width: 100%;
}

.box {
	position: relative;
	background-color: var(--section-background-color);
	padding: 15px 20px 28px 20px;
	border-radius: 12px;
	width: 100%;
	max-width: 600px;
	height: fit-content;
	margin: 0 auto;
}

.reAcceptTextAreaHeader {
	color: var(--section-background-text-color);
	font-size: 28px;
	font-weight: 600;
	word-break: break-word;
	hyphens: auto;
}

.reAcceptTextAreaInfoText {
	color: var(--section-background-text-color);
	font-size: 16px;
	margin-bottom: 35px;
	word-break: break-word;
	hyphens: auto;
}

.reAcceptanceButton {
	margin-top: 20px;
	margin-bottom: 10px;
	justify-content: space-between;
}

.consumerAcceptanceSection {
	background-color: var(--section-background-color);
	padding: 20px;
	border-radius: 12px;
	width: 100%;
	max-width: 600px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
}

.commentTextAreaHeader {
	color: var(--section-background-text-color);
	font-size: 28px;
	font-weight: 600;
	word-break: break-word;
	hyphens: auto;
}

.commentTextAreaInfoText {
	color: var(--section-background-text-color);
	font-size: 16px;
	word-break: break-word;
	hyphens: auto;
}

.commentTextAreaSubHeader {
	color: var(--section-background-text-color);
	font-size: 22px;
	font-weight: 600;
	word-break: break-word;
	hyphens: auto;
}

.commentTextArea {
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border-radius: 4px;
	padding-top: 12px;
	padding-left: 12px;
	width: 100%;
	border: none;
	margin-top: 25px;
}

.commentTextArea:disabled {
	background-color: var(--disable-color);
	cursor: not-allowed;
}

.commentTextArea:focus-visible {
	outline: var(--chosen-border-color) 0.7px solid;
}

.acceptanceButtons {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	gap: 10px;
	flex-wrap: wrap;
}

.acceptanceButtons button {
	flex: 1;
	min-width: 150px;
}

.backArrowWrapper {
	display: flex;
	gap: 10px;
	align-items: center;
	color: var(--section-icon-color);
	cursor: pointer;
	padding: 0 10px 0 10px;
	border-radius: 12px;
}

.backArrowWrapper:not(.backArrowWrapperDisabled):hover {
	background-color: rgba(217, 217, 217, 0.8);
}

.backArrow svg,
.backArrow svg path {
	fill: var(--section-icon-color);
}

.backArrowWrapperDisabled {
	cursor: not-allowed;
	color: var(--disable-color);
}

.backArrowWrapperDisabled .backArrow svg,
.backArrowWrapperDisabled .backArrow svg path {
	fill: var(--disable-color);
}

.crossIcon {
	margin-left: auto;
	stroke: var(--section-icon-color);
	fill: none;
	cursor: pointer;
}

.crossIcon svg {
	height: 100%;
	max-height: unset;
}

@media (max-width: 1100px) {
	.consumerManagerArea {
		display: flex;
		flex-direction: column;
	}

	.selectedConsumerNavWrapper .firstRow {
		padding-left: 0;
	}

	.firstRow .consumerName {
		display: flex;
		font-size: 20px;
		font-weight: 600;
		align-items: center;
	}

	.firstRow .profilePicture {
		position: unset;
		width: 42px;
		height: 42px;
		font-size: 18px;
	}

	.horizontalScrollWrapper {
		margin-left: 0;
	}

	.usersAndContactPersons {
		margin-bottom: 0;
		grid-template-columns: 100%;
	}

	.consumerManagerArea > div {
		max-height: unset;
	}

	.secondRow .horizontalScroll .item {
		font-size: 16px;
		min-width: 100px;
		width: 100px;
	}

	.informationBox,
	.informationBoxDropdownBox {
		border-radius: 6px;
		height: 28px;
		font-size: 14px;
	}

	.informationBoxDropdownContent {
		top: 32px;
		max-width: 150px;
		font-size: 14px;
	}

	.selectedConsumerNavWrapper .secondRow .horizontalScroll {
		height: 54px;
	}
}

@media (max-width: 850px) {
	.consumerInfoAndInvoicingInformation {
		margin-bottom: 0;
		grid-template-columns: 100%;
	}

	.consumerInfoAndInvoicingInformation > * {
		margin: 0 auto;
	}
}

@media (max-width: 300px) {
	.acceptanceButtons {
		flex-direction: column;
	}

	.acceptanceButtons button {
		min-height: 42px;
		min-width: unset;
	}
}
