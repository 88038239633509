.wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
}

.productImage {
	height: 100%;
	max-width: 100%;
	align-self: center;
	justify-self: center;
}
