/* Start regular incrementor CSS */

.incrementorPill {
	min-width: 125px;
	display: flex;
	justify-content: space-evenly;
	border-radius: 50px;
	background-color: var(--input-field-color);
	padding: 6px 14px;
	align-items: center;
	border: 1px solid var(--chosen-border-color);
}

.incrementorPill > input {
	font-size: 16px;
	width: 55px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border-radius: 4px;
	border: none;
	height: auto;
	text-align: center;
}

.incrementorPill > input:focus-visible {
	outline: none;
}

.incrementorPill > input::-webkit-outer-spin-button,
.incrementorPill > input::-webkit-inner-spin-button {
	appearance: none;
}

.incrementorPill > span {
	font-size: 18px;
	font-weight: 600;
	color: var(--input-field-inside-color);
}

.incrementorPill .unitText {
	font-size: 12px;
	align-self: flex-end;
}

/* Start expandable incrementor css */

.expandableButtonWrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.expandableButton {
	background-color: var(--accent-color);
	height: 100%;
	width: 100%;
	color: var(--accent-inside-color);
	border-radius: 1000px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-color: transparent;
	font-size: 26px;
	font-weight: 600;
	cursor: pointer;
	margin-right: 2px;
	padding: 0;
}

.expandableButton:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}
