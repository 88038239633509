.orderItemProductToggleSection {
	display: flex;
	justify-content: space-between;
	background-color: var(--module-box-color);
	padding: 10px 10px 10px 20px;
	align-items: center;
	margin-bottom: 10px;
	border-radius: 4px;
}

.orderItemProductToggleSection > div {
	display: flex;
	gap: 20px;
}

.basketCollapseButton {
	border-radius: 200px;
	width: 2.5em;
	height: 2.5em;
	border: none;
	cursor: pointer;
	padding: 0;
	color: black;
	background-color: transparent;
}

.basketCollapseButtonIcon {
	padding-top: 5px;
	width: 25px;
	height: 25px;
}

@media (max-width: 1599px) {
	.basketCollapseButton {
		width: 1.5em !important;
		height: 1.5em !important;
	}

	.basketCollapseButtonIcon {
		width: 20px !important;
		height: 20px !important;
	}
}

.orderItemPriceSummaryHeader {
	color: var(--module-box-text-color);
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 5px;
}

.orderItemPriceSummaryRow {
	display: flex;
	justify-content: space-between;
	color: var(--module-box-text-color);
	column-gap: 15px;
}

.orderItemZoneName {
	font-weight: 600;
	font-size: 16px;
	color: var(--module-box-text-color);
}

.orderItemPriceSummaryZoneRow {
	display: flex;
	justify-content: space-between;
	color: var(--module-box-text-color);
	column-gap: 15px;
}

.orderItemDiscountSummaryTotal,
.orderItemPriceSummaryTotal,
.orderItemPriceSummaryTotalSubRow {
	display: flex;
	justify-content: space-between;
	color: var(--module-box-text-color);
}

.orderItemPriceSummaryTotal {
	font-size: 24px;
	font-weight: 600;
	flex-wrap: wrap;
}

.orderItemDiscountSummaryTotal {
	font-size: 18px;
	font-weight: 600;
	flex-wrap: wrap;
}

.orderItemPriceSummaryWrapper {
	background-color: var(--module-box-color);
	margin-bottom: 10px;
	padding: 10px 10px 10px 20px;
	border-radius: 6px;
}

@media (max-width: 1599px) {
	.orderItemPriceSummaryWrapper > h2 {
		font-size: 20px !important;
	}
}

.allOrdersPriceSummaryWrapper {
	background-color: var(--module-box-color);
	padding: 20px 10px 20px 20px;
	color: var(--module-box-text-color);
	border-radius: 4px;
}

.allOrdersPriceSummaryWrapper .grandTotal {
	display: flex;
	justify-content: space-between;
	font-size: 24px;
	font-weight: 600;
	flex-wrap: wrap;
}

.allOrdersPriceSummaryWrapper .grandTotalTaxes {
	display: flex;
	justify-content: space-between;
}

.listOrdersIcon {
	stroke: var(--module-box-icon-color);
}
