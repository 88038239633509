.wrapper {
	width: 100%;
	padding-bottom: 400px;
}

.wrapper.regularBasketHidden {
	max-width: 100%;
}

.content {
	width: calc(100% - 24px);
}

.regularBasketHidden .content {
	padding-right: 0;
}

.sectionH1 {
	margin-bottom: 0.4em;
}

.mobileSeeCart {
	display: none;
	position: fixed;
	z-index: 10;
	height: 82px;
	width: 100%;
	bottom: 0;
	left: 0;
	background-color: transparent;
}

.mobileSeeCartButtonWrapper {
	display: flex;
	justify-content: space-evenly;
	margin-left: 20px;
	margin-right: 20px;
	height: 100%;
}

.showMobileBasketFinalizeButton {
	width: 100%;
	max-width: 812px;
	margin-top: auto;
	margin-bottom: auto;
	box-shadow: 0 2px 4px #00000029;
	background-color: var(--basket-finalize-color);
	color: var(--basket-finalize-inside-color);
}

.mobileSeeCartButtonWrapper .showMobileBasketFinalizeButton:not(:disabled):hover {
	background-color: var(--basket-finalize-color);
	opacity: 80%;
}

.mobileBasketWrapper {
	display: none;
}

.mobileBasketWrapper::-webkit-scrollbar {
	display: none;
}

.section {
	margin-bottom: 52px;
}

.regularBasketWrapper {
	height: calc(var(--100vh) - 150px);
	position: fixed;
	right: 16px;
	top: 100px;
	z-index: 1;
}

.regularBasketWrapper .basketWrapper {
	width: 480px;
	padding: 0 20px 0 20px;
	background: var(--basket-background-color);
	box-shadow: 0 3px 6px #00000029;
	min-height: 96px;
	height: 100%;
}

.regularBasketContentWrapper {
	display: block;
	overflow-y: scroll;
	height: calc(100% - 276px);
	padding-top: 1.4em;
	margin-left: -20px;
	margin-right: -20px;
}

.noPrices.regularBasketContentWrapper {
	height: calc(100% - 255px);
}

.regularBasketContentWrapper.noOrderItems {
	height: calc(100% - 188px);
}

.regularBasketContentWrapper.oneOrderItemOnly {
	height: calc(100% - 217px);
}

.regularBasketContentWrapper::-webkit-scrollbar,
.basketItemsWrapper::-webkit-scrollbar {
	display: none;
}

/* The size of the + and - buttons */
.mobileBasketWrapper .basketItemProduct > div:nth-of-type(4) > div > button {
	width: 35px;
	height: 35px;
	padding: 0;
}

.mobileBasketWrapper .orderItemSelectionListItemMobile {
	scroll-margin-top: 75px;
}

.mobileBasketHeader {
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 15px;
	background-color: var(--basket-main-color);
}

.projectCell {
	width: 100%;
	height: auto;
	display: grid;
	gap: 1em;
	grid-template-columns: calc(50% - 0.5em) calc(50% - 0.5em);
}

.categoryCell {
	display: grid;
	gap: 1em;
	grid-auto-flow: column;
	grid-auto-columns: minmax(150px, 240px);
}

.categoryCell .productCategory {
	min-width: 100px;
}

.serviceCell {
	width: 100%;
	display: grid;
	gap: 1em;
	grid-template-columns: repeat(3, minmax(0, 390px));
}

.productCell {
	width: 100%;
	display: grid;
	gap: 1em;
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.subCategoryCell {
	display: flex;
	margin-bottom: 0.8em;
	gap: 1em;
	overflow-x: scroll;
}

.subCategoryCard {
	height: auto;
	min-width: 120px;
	flex-shrink: 0;
	padding: 0.4em;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
}

.subCategoryCard span {
	color: var(--section-background-text-color);
	font-size: 20px;
	font-weight: 500;
	word-break: keep-all;
}

.subCategoryCell::-webkit-scrollbar {
	display: compact;
}

.sectionBox {
	background-color: var(--section-background-color);
	border: var(--section-background-color) 1px solid;
	border-radius: 0.375em;
	transition: border 0.2s;
}

.selectExistingProjectSection {
	height: 75px;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	background-color: var(--selected-section-background-color);
	border: var(--chosen-border-color) 1px solid;
	border-radius: 0.375em;
	color: var(--section-background-text-color);
	font-size: 20px;
	font-weight: 500;
	display: grid;
	grid-template-columns: minmax(0, 1fr) 20px;
	padding: 10px 12px;
}

.sectionBox:hover:not(.selectedSectionBox) {
	border: var(--hover-border-color) 1px solid;
}

.sectionOption {
	height: 75px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 1.5em;
	cursor: pointer;
}

.sectionOption span {
	color: var(--section-background-text-color);
	font-size: 1.25em;
	font-weight: 500;
}

.existingProjectPlus,
.newProjectPlus {
	margin-left: auto;
	font-size: 35px;
}

.existingProjectPlus {
	color: var(--section-background-text-color);
}

.newProjectPlus {
	color: var(--accent-inside-color);
}

.newProjectButton {
	border-radius: 1000px; /*capsule shape*/
	background-color: var(--accent-color);
}

.newProjectButton span {
	color: var(--accent-inside-color);
}

.newProjectButton:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.newProjectButton:hover span,
.newProjectButton:hover .newProjectPlus {
	color: var(--accent-hover-inside-color);
}

.productCategory {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 5px;
	height: 155px;
}

.productCategory span {
	color: var(--section-background-text-color);
	font-size: 20px;
	font-weight: 500;
	word-break: break-all;
}

.productCategoryImage {
	fill: var(--section-icon-color);
}

.productCategoryImage path[data-name="stroke"] {
	stroke: var(--section-icon-color);
}

.serviceSelector {
	height: 4em;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.serviceSelector span {
	color: var(--section-background-text-color);
	font-size: 20px;
	font-weight: 500;
	word-break: break-all;
}

.productCard {
	position: relative;
	height: auto;
	min-height: 19em;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 14px;
	transform: translate3d(0, 0, 1px);
	background-color: var(--product-section-color);
	border: var(--product-section-color) 1px solid;
}

.productInfoIcon {
	position: absolute;
	right: 0.75em;
	top: 0.75em;
	width: 1.4em;
	height: 1.4em;
	color: var(--section-icon-color);
	border: 2px solid var(--section-icon-color);
	border-radius: 50%;
	font-weight: bolder;
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.productInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.productInfo hr {
	border-top: 1px solid var(--product-border-color);
	border-bottom: none;
	width: calc(100% - 2px);
	margin: 0 0 10px 0;
}

.productImageWrapperFill,
.productImageWrapperFit {
	height: 7.6em;
	margin-top: 25px;
	cursor: pointer;
}

.productImageWrapperFit {
	display: flex;
	width: 100%;
	padding: 0 14px 14px 14px;
}

.productImageWrapperFill {
	display: block;
}

.productTitle {
	font-size: 20px;
	font-weight: 500;
	color: var(--product-section-text-color);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
	min-height: 32px;
	max-height: 44px;
}

.productDescription {
	margin-top: 0.4em;
	font-size: 0.875em;
	font-weight: 400;
	color: var(--product-section-text-color);
}

.productPurchaseCell {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 2.375em;
	align-items: center;
	color: var(--section-background-text-color);
}

.productUnitCell {
	margin-top: 0.4em;
	font-size: 14px;
	font-weight: 600;
	color: var(--product-section-text-color);
}

.productIncrementer {
	margin-left: auto;
}

.basketFinalizeButton {
	margin-top: 20px;
	margin-bottom: 20px;
}

.selectedProjectSection {
	background-color: var(--selected-section-background-color);
	border: var(--chosen-border-color) 1px solid;
	border-radius: 0.375em;
	transition: border 0.2s;
	height: auto;
	cursor: pointer;
	color: var(--section-background-text-color);
	font-size: 1.25em;
	font-weight: 500;
	display: grid;
	grid-template-columns: minmax(0, 1fr) 20px;
	padding: 10px 12px;
	gap: 6px;
}

.selectedSectionBox {
	border: var(--chosen-border-color) 1px solid;
	background-color: var(--selected-section-background-color);
}

.infoText {
	color: color-mix(in srgb, white 50%, var(--main-background-text-color));
}

.addProductButton {
	font-weight: 500;
	border-style: none;
	border-radius: 1000px;
	width: 5em;
	height: 2.625em;
	cursor: pointer;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	justify-self: end;
}

.addProductButton:disabled,
.addProductButton[disabled] {
	cursor: not-allowed;
}

.addProductButton:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.basketTopInfoText {
	color: var(--main-background-text-color);
	font-size: 14px;
}

.moduleBoxColor {
	background-color: var(--module-box-color);
	border-radius: 100px;
}

.moduleBoxIconColorStroke {
	stroke: var(--module-box-icon-color);
}

.moduleBoxIconColorFill {
	fill: var(--module-box-icon-color);
}

.basketModuleIconColorStroke {
	stroke: var(--basket-module-icon-color);
}

.basketModuleIconColorFill {
	fill: var(--basket-module-icon-color);
}

.basketItemHeaderSection {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	flex-direction: column;
}

.mobileBasketWrapper .basketItemHeaderSection > div > h2 {
	font-size: 20px;
}

.mobileBasketWrapper .basketItemProduct > div > h5:first-of-type {
	font-size: 16px;
}

.basketItemProduct {
	background-color: var(--basket-module-color);
	border-radius: 10px;
	padding: 5px 15px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: minmax(100px, auto) minmax(auto, 140px);
	grid-template-rows: minmax(90px, auto);
}

.basketItemProductImage {
	max-height: 50px;
}

.basketItemProductRemove {
	display: grid;
	grid-template-columns: 20px 60px;
	align-items: center;
	font-size: 14px;
	margin-top: 4px;
}

.removeBasketItemButton {
	cursor: pointer;
	border: none;
	background-color: transparent;
	padding: 0;
}

.removeBasketItemButton > div {
	width: 34px !important;
	height: 34px !important;
}

.removeBasketItemButton > div > svg {
	width: 22px !important;
}

.basketItemProductRemoveButton {
	cursor: pointer;
	width: 70px;
	display: flex;
	align-items: center;
}

.basketItemProductRemoveButton > div {
	padding: 0;
	margin-right: 5px;
}

.dateAndTimeSelectorsWrapper {
	display: grid;
	grid-template-columns: calc(50% - 5px) 10px calc(50% - 5px);
}

.dateSelector,
.timeSelector {
	display: grid;
	grid-template-columns: auto 15px;
	background-color: var(--section-value-box-color);
	grid-template-rows: 45px;
	margin-bottom: 15px;
	border-radius: 10px;
	padding-left: 15px;
	padding-right: 15px;
	align-items: center;
	color: var(--section-value-box-inside-color);
	font-size: 16px;
	height: 45px;
	cursor: pointer;
}

.dateSelector:not(.dateSelectorSelected):hover {
	color: var(--section-value-box-hover-inside-color);
}

.timeSelector:not(.timeSelectorSelected):hover {
	color: var(--section-value-box-hover-inside-color);
}

.dateSelectorIcon {
	stroke: var(--section-value-box-inside-color);
	fill: var(--section-value-box-inside-color);
	stroke-width: 2px;
}

.dateSelector:not(.dateSelectorSelected):hover .dateSelectorIcon {
	stroke: var(--section-value-box-hover-inside-color);
	fill: var(--section-value-box-hover-inside-color);
}

.dateSelectorIcon > svg {
	fill: var(--section-value-box-inside-color);
}

.dateSelector:not(.dateSelectorSelected):hover .dateSelectorIcon > svg {
	fill: var(--section-value-box-hover-inside-color);
}

.timeSelectorIcon {
	width: 18px;
	height: 18px;
}

.dateSelector,
.dateSelectorSelected {
	grid-column: 1;
}

.timeSelector,
.timeSelectorSelected {
	grid-column: 3;
}

.timeSelectorDisabled {
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
	cursor: not-allowed;
}

.timeSelectorDisabled:hover {
	background-color: var(--disable-color);
}

.dateSelector:hover,
.timeSelector:hover {
	background-color: var(--section-value-box-hover-color);
}

.dateSelectorSelected:hover,
.timeSelectorSelected:hover {
	background-color: var(--selected-section-value-box-hover-color);
}

.dateSelectorSelected,
.timeSelectorSelected {
	display: grid;
	grid-template-columns: auto 15px;
	background-color: var(--selected-section-value-box-color);
	grid-template-rows: auto;
	margin-bottom: 15px;
	border-radius: 10px;
	padding-left: 15px;
	padding-right: 15px;
	align-items: center;
	color: var(--selected-section-value-box-inside-color);
	font-size: 16px;
	height: auto;
	min-height: 45px;
	cursor: pointer;
	border: var(--chosen-border-color) 1px solid;
	font-weight: 500;
}

.dateAndTimeSelectorsSelectedIcon {
	stroke: var(--selected-section-value-box-inside-color);
}

.sectionIconColor {
	color: var(--section-icon-color);
}

.basketCollapseButton {
	border-radius: 200px;
	width: 2.5em;
	height: 2.5em;
	border: none;
	background-color: var(--basket-main-color);
	cursor: pointer;
}

.basketCollapseButtonIcon {
	width: 25px;
	height: 25px;
	color: var(--section-icon-color);
}

.timeline {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-inline-start: 0;
}

.timelineLine,
.timelineLineComplete {
	margin-left: 5px;
	margin-right: 5px;
	padding: 0 20px;
}

.timelineLine {
	border-top: 3px solid var(--disable-color);
}

.timelineLineComplete {
	border-top: 3px solid var(--confirmed-amount-color);
}

.timeline > li > div:before {
	content: "";
	display: block;
	width: 15px;
	height: 15px;
	background-color: var(--disable-color);
	border-radius: 25px;
}

.timeline > li.complete > div:before {
	background-color: var(--confirmed-amount-color);
	border: 2px solid var(--confirmed-amount-color);
}

.timeline > li.current > div:before {
	background-color: transparent;
	border: 2px solid var(--confirmed-amount-color);
}

.mobileBasketBackArrowAndStatusWrapper {
	margin-top: 17px;
	margin-bottom: 18px;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

.orderItemWasteTypeText {
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.mobileOnly {
	display: none;
}

.projectMainText,
.projectSubText {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
}

.projectMainText {
	font-size: 20px;
}

.projectSubText {
	font-size: 16px;
}

.noProductsWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100% - 200px);
}

.noProductsMessage {
	color: var(--main-background-text-color);
	font-size: 28px;
}

.orderItemProductServiceText,
.orderItemWasteTypeText {
	font-size: 16px;
}

.customLD10 {
	width: 75%;
}

.customLD5 {
	width: 70%;
}

.customLDD10 {
	width: 75%;
}

.customLDL8 {
	width: 75%;
}

.customLDL8WOH {
	width: 75%;
}

.customLDT10 {
	width: 65%;
}

.customLV11K {
	width: 75%;
}

.customLV15K {
	width: 75%;
}

.customLVT15K {
	width: 84%;
}

.productCategoryName {
	margin-bottom: 10px;
	flex-grow: 1;
	flex-shrink: 0;
}

.productCategoryName,
.serviceName {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	min-width: 0;
	width: calc(100%);
	text-align: center;
}

.productImageFill {
	/* The calcs are used here to offset the margins and paddings normally applied on the regular product images */
	width: 100%;
	margin-top: calc(-25px);
	border-top-left-radius: 0.375em;
	border-top-right-radius: 0.375em;
	object-fit: cover;
	height: calc(100% + 25px);
}

.productImageFit {
	max-width: 100%;
	object-fit: contain;
	margin: 0 auto;
}

.productInfoIconOutlined {
	/* box-shadow instead of outline because apple makes the outline square instead of following the shape of the object*/
	box-shadow: 0 0 1px 1px rgba(255, 255, 255, 1);
}

.productInfoIconOutlined > strong {
	-webkit-text-stroke: 0.5px white;
}

.productCardPriceCell {
	margin-top: 8px;
	font-weight: 500;
	color: var(--section-background-text-color);
}

.orderItemPriceCell {
	font-size: 18px;
	font-weight: 600;
	color: var(--basket-module-text-color);
	margin-top: 5px;
	display: flex;
}

.orderItemZone {
	background-color: var(--basket-module-color);
	border-radius: 4px;
	display: grid;
	grid-template-columns: minmax(50px, auto) 140px;
	padding: 15px;
	justify-content: space-between;
	height: auto;
	min-height: 65px;
	align-items: center;
}

.orderItemZoneNotFound {
	font-weight: 600;
	grid-template-columns: auto;
}

.orderItemZoneNotFound > div {
	hyphens: auto;
}

.orderItemZoneName {
	font-size: 16px;
	color: var(--module-box-text-color);
}

.orderItemPriceSummaryZoneRow {
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	color: var(--section-background-text-color);
}

.zoneMapIcon {
	fill: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
}

.zoneMapIconWrapper {
	justify-self: center;
}

.categorySelectionHorizontalScroll > div:first-of-type {
	left: 25px;
}

@media (min-width: 2300px) {
	.wrapper {
		min-width: 1250px;
	}
}

@media (min-width: 1650px) {
	.wrapper.regularBasketHidden {
		min-width: 1500px;
	}
}

@media (min-width: 1751px) {
	.productCell {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
}

@media (min-width: 1601px) and (max-width: 1750px) {
	.productCell {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

@media (min-width: 1601px) {
	.regularBasketHidden .productCell {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

@media (max-width: 1600px) and (min-width: 1301px) {
	.productCell {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	.regularBasketHidden .productCell {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
}

@media (max-width: 1300px) and (min-width: 1101px) {
	.productCell {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	.regularBasketHidden .productCell {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

@media (min-width: 1640px) {
	.wrapper:not(.regularBasketHidden) {
		/* This calculation is kinda mysterious at first glance... but basically it's just the width of the screen minus
		the offsets of the sidebar and some padding (145px), the basket (480px), and the padding which aligns the left edge of the
		content to the left edge of the logo (calc(calc(var(--100vw) - 1640px) / 2)).

		The -30px is just to add some spacing against the content on the right

		By doing this the calculated value is exactly the portion of the screen's area that we want :)
	  	*/
		max-width: calc(var(--100vw) - 145px - 480px - 30px - calc(calc(var(--100vw) - 1640px) / 2));
	}
}

@media (max-width: 1639px) and (min-width: 1101px) {
	.wrapper:not(.regularBasketHidden) {
		width: calc(100% - 490px);
	}
}

@media (min-width: 1101px) {
	.wrapper {
		margin-top: -48px;
		padding-top: 48px;
	}
}

@media (max-width: 1100px) {
	.content {
		width: 100%;
	}

	.categorySelectionHorizontalScroll > div:first-of-type {
		left: 0;
	}

	.productCell {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	.categoryCell {
		width: calc(var(--100vw) - 40px);
		grid-auto-columns: minmax(100px, 1fr);
	}

	.subCategoryCell {
		width: calc(var(--100vw) - 40px);
		grid-auto-columns: minmax(100px, 1fr);
	}

	.productCategory span,
	.subCategoryCard span,
	.sectionOption span,
	.selectedProjectSection,
	.serviceSelector span,
	.productTitle {
		font-size: 16px;
	}

	.wrapper {
		max-width: unset;
		overflow-y: visible;
	}

	.productImageWrapperFill,
	.productImageWrapperFit {
		margin-top: 20px;
	}

	.orderItemProductServiceText,
	.orderItemWasteTypeText {
		font-size: 14px;
	}

	.projectMainText {
		font-size: 16px;
	}

	.projectSubText {
		font-size: 14px;
	}

	.mobileOnly {
		display: flex;
	}

	.regularBasketWrapper {
		display: none;
	}

	.mobileSeeCart {
		display: unset;
	}

	.mobileBasketWrapper {
		position: fixed;
		top: 63px;
		left: 0;
		width: 100%;
		z-index: 800;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		background-color: var(--basket-background-color);
		height: calc(var(--100vh) - 63px);
	}

	.mobileBasketWrapper > .basketItemsWrapper {
		margin-left: 20px;
		margin-right: 20px;
		overflow-y: visible;
		margin-bottom: 52px;
	}

	.mobileBasketWrapper > .basketItemsWrapper:last-of-type {
		margin-bottom: 0;
	}

	.mobileBasketWrapper > div:not(.mobileBasketHeader):not(.basketItemsWrapper) {
		display: flex;
		flex-wrap: wrap;
	}

	.mobileBasketWrapper > div > button {
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.mobileBasketWrapper > div > button,
	.mobileBasketWrapper > .basketTopInfoText {
		margin-left: 20px;
		margin-right: 20px;
	}

	.productCategory {
		height: 130px;
	}
}

@media (max-width: 710px) {
	.wrapper {
		padding-bottom: 180px;
	}

	.categoryCell {
		width: calc(var(--100vw) - 16px);
	}

	.subCategoryCell {
		width: calc(var(--100vw) - 16px);
	}
}

@media (max-width: 900px) and (min-width: 701px) {
	.productCell {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media (max-width: 700px) {
	.productCell {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

@media (max-width: 650px) {
	.newProjectButton span {
		display: none;
	}

	.newProjectButton {
		width: 54px;
		padding: 0;
		border-radius: 6px;
	}

	.newProjectButtonFullWidth {
		grid-column-start: 1;
		width: 100%;
		grid-column-end: 3;
	}

	.newProjectButtonFullWidth > span {
		display: block;
		margin-left: 16px;
	}

	.newProjectButtonFullWidth > .newProjectPlus {
		margin-right: 16px;
	}

	.newProjectButton div {
		margin: 0 auto;
	}

	.projectCell {
		grid-template-columns: 8fr 1fr;
	}

	.productCategory {
		height: 110px;
	}
}

@media (max-width: 600px) {
	.addProductButton {
		width: 100%;
	}
}

@media (max-width: 465px) {
	.dateAndTimeSelectorsWrapper {
		grid-template-areas:
			"dateSelector"
			"timeSelector";
		grid-template-columns: 100% 100%;
	}

	.dateAndTimeSelectorsWrapper > div:nth-of-type(1) {
		grid-area: dateSelector;
		margin-bottom: 10px;
	}

	.dateAndTimeSelectorsWrapper > div:nth-of-type(2) {
		grid-area: timeSelector;
	}
}

@media (max-width: 360px) {
	.productCell {
		grid-template-columns: auto;
	}
}

@media (max-width: 345px) {
	.mobileBasketWrapper .basketItemProductRemove {
		grid-row: 4;
		grid-column: 1;
		margin-top: 10px;
	}

	.mobileBasketWrapper .basketItemProduct > div:nth-of-type(2) {
		grid-row: 2;
		margin-top: 0;
		margin-bottom: 15px;
		height: 3.125em;
	}

	.mobileBasketWrapper .basketItemProduct > div:nth-of-type(4) {
		grid-row: 3;
	}

	.orderItemZone {
		grid-template-columns: auto;
	}
}

@media (max-width: 281px) {
	.selectExistingProjectSection {
		padding: 0 0 0 5px;
	}
}

@media (max-width: 250px) {
	.basketItemHeaderSection {
		flex-direction: column-reverse;
	}
}
