.slideshowContainer {
	position: relative;
	margin: auto;
}

.prev,
.next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	width: auto;
	padding: 16px;
	transition: 0.6s ease;
	border-radius: 0 3px 3px 0;
}

.next {
	right: 0;
	border-radius: 3px;
}

.slideshowArrow {
	color: var(--image-scroll-accent-inside-color);
}

.prev:hover,
.next:hover {
	background-color: var(--image-scroll-accent-hover-color);
}
.prev:hover .slideshowArrow,
.next:hover .slideshowArrow {
	transition: 0.5s;
	color: var(--image-scroll-accent-hover-inside-color);
}

.imageWrapperFit {
	display: flex;
	height: 22em;
	align-items: center;
	justify-content: center;
	background-color: var(--product-image-background-color);
}

.imageWrapperFill {
	height: 22em;
	overflow: hidden;
	position: relative;
	width: calc(100%);
}

.imageFill {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.imageFit {
	width: calc(70%);
	height: calc(100%);
	padding-top: 20px;
	object-fit: contain;
}

.productImageWrapper {
	width: 100%;
	height: 20em;
	aspect-ratio: 1 / 1;
}

.selectedDot {
	cursor: pointer;
	color: var(--section-background-text-color);
}

.regularDot {
	cursor: pointer;
	color: var(--section-background-text-color);
}
