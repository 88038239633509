div.btnContainer {
	display: block;
	width: fit-content;
}

.labelledToggleSwitch {
	width: 100%;
	display: inline-block;
	margin: 0;
	position: relative;
}

.labelledToggleSwitch > label.labelledToggleSwitchInner {
	margin: 0;
	width: 327px;
	height: 42px;
	background: white;
	border-radius: 26px;
	overflow: hidden;
	position: relative;
	transition: all 0.3s ease;
	display: block;
	cursor: pointer;
}

.labelledToggleSwitch > label.labelledToggleSwitchInner:before {
	content: attr(data-on);
	position: relative;
	font-size: 16px;
	font-weight: 500;
	top: 13px;
	margin-left: auto;
	margin-right: 0;
	width: 50%;
	align-items: center;
	display: flex;
	justify-content: space-evenly;
}

.labelledToggleSwitch > label.labelledToggleSwitchInner:after {
	content: attr(data-off);
	width: 170px;
	height: 38px;
	background: black;
	color: white;
	border-radius: 26px;
	position: absolute;
	left: 2px;
	top: 2px;
	text-align: center;
	transition: all 0.3s ease;
	box-shadow: 0 0 6px -2px #111;
	align-items: center;
	display: flex;
	justify-content: space-evenly;
}

.labelledToggleSwitch input[type="checkbox"] {
	width: 1px;
	height: 1px;
	opacity: 0;
	position: absolute;
	top: 0;
	z-index: 1;
	margin: 0;
}

.labelledToggleSwitch input[type="checkbox"]:checked + label.labelledToggleSwitchInner {
	background: white;
	color: black;
}

.labelledToggleSwitch input[type="checkbox"]:checked + label.labelledToggleSwitchInner:after {
	content: attr(data-on);
	left: 155px;
	background: #3c3c3c;
	align-items: center;
	display: flex;
	justify-content: space-evenly;
}

.labelledToggleSwitch input[type="checkbox"]:checked + label.labelledToggleSwitchInner:before {
	content: attr(data-off);
	display: flex;
	width: 50%;
	top: 13px;
	align-items: center;
	display: flex;
	justify-content: space-evenly;
	margin-left: 0;
	margin-right: auto;
}

.labelledToggleSwitch input[type="checkbox"]:checked ~ .alert {
	display: block;
}

@media (max-width: 375px) {
	.labelledToggleSwitch > label.labelledToggleSwitchInner {
		width: 177px;
		height: 85px;
	}
	.labelledToggleSwitch > label.labelledToggleSwitchInner:before {
		top: 51px;
		left: 0px;
		width: 100%;
		align-items: center;
		display: flex;
		justify-content: space-evenly;
	}

	.labelledToggleSwitch input[type="checkbox"]:checked + label.labelledToggleSwitchInner:before {
		left: 0px;
		margin-left: auto;
	}
	.labelledToggleSwitch input[type="checkbox"]:checked + label.labelledToggleSwitchInner:after {
		left: 4px;
		top: 45px;
	}
}
