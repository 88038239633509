@media (max-width: 385px) {
	.subHeader {
		margin-left: 5%;
	}
}

.header {
	height: clamp(40px, 11.41vh, 100px);
	background-color: #4a2345;
	display: flex;
	align-items: center;
	flex-flow: row wrap;
}

.header > img {
	max-height: 50%;
	object-fit: contain;
	margin-left: 5%;
}

.subHeader {
	margin-bottom: 0;
	color: #c0fada;
	overflow-wrap: anywhere;
	font-size: 16px;
}
