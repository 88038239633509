.activitiesWrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.activitiesWrapper .headerText {
	font-size: 20px;
	font-weight: 600;
	margin-top: 20px;
	margin-bottom: 15px;
}

.activitiesBox {
	display: flex;
	flex-direction: column;
	background-color: var(--module-box-color);
	border-radius: 6px;
	padding: 10px 15px 0 14px;
	box-shadow: 0 0 3px 0 #00000029;
	margin-left: 2px;
	margin-right: 2px;
	height: 100%;
	position: relative;
}

.activitiesList {
	display: flex;
	flex-direction: column-reverse;
	row-gap: 10px;
	height: 100%;
	overflow-y: auto;
	padding-bottom: 25px;
}

.activityWrapper {
	display: flex;
	position: relative;
}

.userImageWrapper {
	height: 44px;
	width: 44px;
	min-width: 44px;
	min-height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	background-color: rgb(165, 165, 165);
	margin-top: auto;
	color: white;
}

.userImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 100px;
}

.activity {
	--r: 25px; /* the radius */
	--t: 15px; /* the size of the tail */

	max-width: 320px;
	padding: 12px calc(2 * var(--r) / 3) calc(2 * var(--r) / 3);
	-webkit-mask: radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100% / calc(100% - var(--r))
			var(--t) no-repeat,
		conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2) calc(var(--r) / -2) padding-box,
		radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space padding-box;
	margin-bottom: 15px;
}

.clientActivity {
	background-color: var(--module-bubble-client-color);
	color: var(--module-bubble-client-text-color);
}

.consumerActivity {
	background-color: var(--module-bubble-consumer-color);
	color: var(--module-bubble-consumer-text-color);
}

.content {
	margin-top: 5px;
	font-size: 16px;
	white-space: pre-wrap;
	word-break: break-word;
}

.nameAndDate {
	display: flex;
	gap: 5px;
	align-items: center;
}

.nameAndDate .name {
	font-size: 14px;
	font-weight: 500;
}

.nameAndDate .date {
	font-size: 12px;
	white-space: nowrap;
}

.left .activity {
	--_d: 0%;
	border-left: var(--t) solid #0000;
	margin-right: var(--t);
	place-self: start;
}

.right .activity {
	--_d: 100%;
	border-right: var(--t) solid #0000;
	margin-left: var(--t);
	place-self: end;
}

.right.activityWrapper {
	flex-direction: row-reverse;
}

.activitiesFooter {
	display: flex;
	box-shadow: 0 -2px 6px 0 #00000029;
	gap: 10px;
	margin-left: -15px;
	margin-right: -15px;
	padding: 15px 15px 25px 15px;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	justify-content: space-between;
}

.addFileButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	min-width: 40px;
}

.addFileButton label {
	font-size: 40px;
	color: var(--finalize-inside-color);
	cursor: pointer;
}

.addFileButton label[aria-disabled="true"],
.addFileButton input:disabled {
	cursor: not-allowed;
}

.sendIcon {
	stroke: var(--finalize-color);
	rotate: 45deg;
	cursor: pointer;
	margin-left: 5px;
	min-width: 34px;
}

.sendIcon:hover {
	stroke: var(--finalize-hover-color);
}

.disabledSend {
	stroke: var(--disable-color);
	cursor: not-allowed;
}

.disabledSend:hover {
	stroke: var(--disable-color);
}

.textInput {
	width: 100%;
	background-color: var(--input-field-color);
	border: none;
	border-radius: 24px;
	padding: 11px 5px 0 15px;
	resize: none;
	height: 40px;
	max-height: 132px;
}

.textInput::-webkit-scrollbar-thumb {
	background-color: rgba(199, 199, 199, 0.7);
}

.textInput::-webkit-scrollbar-track {
	background-color: transparent;
}

.textInput:focus-visible {
	outline: none;
}

.textInput:disabled {
	border: none;
	background-color: var(--disable-color);
}

.textInput:disabled::placeholder {
	color: var(--disable-inside-color);
}

.stagedFiles {
	display: flex;
	box-shadow: 0 -2px 6px 0 #00000029;
	margin-left: -15px;
	margin-right: -15px;
	padding: 10px 15px;
	gap: 15px;
}

.stagedFile {
	cursor: pointer;
	height: 45px;
	width: 45px;
	box-shadow: 0 1px 6px #00000029;
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.stagedFile .image {
	height: 45px;
	width: 45px;
	border-radius: 16px;
}

.stagedFile .genericFile {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
}

.stagedFile .genericFile svg {
	height: 35px;
	max-height: 35px;
	width: 35px;
}

.stagedRemoveBg {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.35);
	position: absolute;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.stagedRemoveBg .crossIcon {
	color: var(--invalid-color);
	opacity: 70%;
	font-size: 26px;
}

.stagedRemoveBg:hover {
	background-color: rgba(0, 0, 0, 0.65);
}

.stagedRemoveBg:hover .crossIcon,
.stagedRemoveBg:hover .checkmark {
	opacity: 100%;
}

.stagedRemoveBg .checkmark {
	color: var(--valid-color);
	opacity: 70%;
	font-size: 26px;
}

.activity div.imageWrapper {
	flex-basis: 80px;
	max-width: 80px;
	max-height: 80px;
	width: 80px;
	height: 80px;
}

.activity .genericFile {
	padding: 6px;
	border-radius: 16px;
	box-shadow: 0 1px 6px #00000029;
	cursor: pointer;
	min-height: 80px;
}

.activity .genericFile:hover {
	border: 1px solid var(--chosen-border-color);
}

.activity .removedFile {
	color: #262626;
	font-size: 14px;
	display: flex;
}

.uploadError {
	position: absolute;
	bottom: 60px;
	color: var(--invalid-color);
	background-color: var(--section-background-color);
	box-shadow: 0 -2px 6px 0 #00000029;
	left: 0;
	right: 0;
	padding: 8px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dismissUploadError {
	stroke: var(--invalid-color);
	cursor: pointer;
}

.submitBtnSpinner {
	margin-top: auto;
	margin-bottom: auto;
	font-size: 26px;
	color: black;
}

.activitiesFooterErrorDismiss {
	color: var(--invalid-color);
	padding: 5px;
	font-size: 20px;
	cursor: pointer;
}

.activitiesFooterErrorDismiss:hover {
	box-shadow: 0 1px 6px #00000029;
	border-radius: 32px;
}

.clientAndConsumerName {
	font-size: 14px;
	font-weight: 600;
}

.stagedFileDropUp {
	position: absolute;
	bottom: 55px;
	background-color: var(--section-value-box-color);
	padding-bottom: 10px;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	width: 70px;
	left: 0;
	color: var(--section-value-box-inside-color);
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.stagedFileDropUp > div {
	padding: 10px;
}

.stagedFileDropUp > div:hover {
	background-color: var(--section-value-box-hover-color);
	color: var(--section-value-box-hover-inside-color);
}

.stagedFileDropUp > div:first-of-type:hover {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.stagedFileImagePreviewWrapper {
	position: absolute;
	z-index: 3;
	max-width: calc(100% - 30px);
	width: 100%;
	max-height: calc(100% - 20px);
	height: 100%;
	display: flex;
	background-color: lightgray;
}

.stagedFileImagePreviewWrapper > img {
	box-shadow: 0 -2px 6px 0 #00000029;
	object-fit: contain;
	height: 100%;
	width: 100%;
}

@media (max-width: 710px) {
	.activitiesWrapper .headerText {
		opacity: 0;
		margin: 0;
	}
}
