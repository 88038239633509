.searchAndNew {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 2.75em;
	margin: 1.4em 0 2em;
}

.newProjectButton {
	width: 11.875em;
	border-radius: 1000px; /*capsule shape*/
	background-color: var(--accent-color);
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.newProjectButtonText {
	color: var(--accent-inside-color);
	padding-left: 1.5em;
	font-size: 16px;
	font-weight: 500;
}

.newProjectButton:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.newProjectButtonPlus {
	margin-left: auto;
	font-size: 29px;
	color: var(--accent-inside-color);
	padding-right: 0.8em;
}

.searchBox {
	background-color: var(--input-field-color);
	height: 40px;
	border-radius: 50px;
	padding: 10px;
	display: flex;
	margin-right: 10px;
	max-width: 286px;
}
.searchInput {
	outline: none;
	border: none;
	background: none;
	width: 0;
	padding: 0;
	color: var(--input-field-inside-color);
	float: left;
	font-size: 16px;
	transition: 0.3s;
	line-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.searchInput::placeholder {
	color: color-mix(in srgb, white 50%, var(--input-field-inside-color));
	filter: brightness(90%);
}
.searchBtn {
	color: var(--input-field-inside-color);
	height: 40px;
	border-radius: 50px;
	text-decoration: none;
	transition: 0.3s;
	cursor: default;
}
.searchInput:focus,
.searchInput:not(:placeholder-shown) {
	text-overflow: ellipsis;
	overflow: hidden;
}

.searchInput::-webkit-search-cancel-button {
	/*FIXME This have not effect, yet...*/
	color: var(--input-field-inside-color);
}

.projectBoxesWrapper {
	max-height: 475px;
	height: calc(100% - 206px);
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
}

.wrapper {
	max-height: 650px;
	height: calc(calc(var(--100vh)) - 100px);
}

.projectRow {
	cursor: pointer;
}

.clientNoProjectsTextWrapper {
	color: rgb(102, 102, 102);
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column;
}

@media (min-width: 711px) {
	.searchBox > .searchInput {
		width: calc(var(--100vw) - 134px);
		padding: 0 6px;
	}
}

@media (max-width: 710px) {
	.wrapper {
		max-height: unset;
	}

	.projectBoxesWrapper > label > div > button {
		display: none;
	}

	.projectBoxesWrapper > label > div {
		grid-template-columns: auto !important;
	}

	.projectBoxesWrapper {
		max-height: unset;
		height: calc(calc(var(--100vh)) - 310px);
	}

	.finalizeButton {
		position: absolute;
		bottom: 0;
	}

	.searchBox:hover > .searchInput,
	.searchInput:focus,
	.searchInput:not(:placeholder-shown) {
		width: calc(var(--100vw) - 134px) !important;
		padding: 0 6px;
	}
}

@media (max-width: 381px) {
	.searchBox:focus-within {
		width: calc(var(--100vw) - 96px);
	}
}
