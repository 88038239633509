.outerWrapper {
	overflow-y: auto;
	height: 100%;
}

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.bottomSection {
	margin-top: auto;
}

.title {
	margin-bottom: 40px;
	margin-top: 4px;
	text-align: center;
}

.infoText {
	margin: 20px 0;
	text-align: center;
	font-size: 16px;
	color: var(--section-background-text-color);
	font-weight: 600;
}

.fields {
	margin-bottom: 40px;
	margin-top: 20px;
}

.fields input:focus-visible {
	outline: var(--chosen-border-color) 0.7px solid;
}

.input {
	width: 100%;
	height: 2.625em;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
}

.inputWrapper {
	margin-bottom: 10px;
	display: flex;
}

.inputWrapper > div {
	background-color: var(--input-field-color);
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	display: flex;
}

.inputWrapper > svg,
.inputWrapper > div > svg {
	width: 20px;
	align-self: center;
	margin-left: 10px;
	margin-right: 15px;
}

.forgotPassword {
	font-size: 14px;
	font-weight: 600;
	color: var(--section-background-text-color);
	text-align: right;
	cursor: pointer;
	width: fit-content;
	margin: 0 0 0 auto;
}

.infoChangeType {
	margin-top: 50px;
	margin-bottom: 20px;
	text-align: center;
	font-size: 1.25em;
	font-weight: 600;
	color: var(--section-background-text-color);
}
