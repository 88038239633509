.modal {
	height: calc(var(--100vh) - 20px);
	overflow-y: scroll;
	max-height: 990px;
}

@media (max-width: 710px) {
	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}

	.form {
		height: calc(100% - 20px);
		display: flex;
		flex-direction: column;
		min-height: 920px;
		padding-bottom: 20px;
	}

	.form > button {
		margin-top: auto;
	}
}
