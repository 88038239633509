.orderHeader {
	background-color: var(--section-background-color);
	font-size: 14px;
	color: var(--text-color-4);
	line-height: 2rem;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: "left right";
}

.compressedOrderHeader {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	margin-bottom: 0.7rem;
}

.orderHeaderLeft {
	grid-area: left;
	padding-left: 1.2rem;
}

.orderHeaderRight {
	grid-area: right;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.orderHeaderRightInfo {
	font-weight: bold;
	font-size: 14px;
	width: 30%;
	height: 60%;
	margin-right: 1rem;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.orderNumber {
	font-weight: bold;
	padding-left: calc(calc(var(--100vw)) * 0.005);
}

.accepted {
	background-color: var(--accent-color);
}

.declined {
	background-color: var(--icon-color-2);
}

.sent {
	background-color: var(--finalize-color);
	color: var(--finalize-inside-color);
}

.roundedBottom {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.icon {
	padding-right: 0.5rem;
}

.iconWhite {
	fill: var(--icon-color-3);
}

.iconNormal {
	fill: var(--icon-color-1);
}

@media (max-width: 768px) {
	.orderHeaderRightInfo {
		width: 50%;
		max-width: 70%;
		padding-top: 0.1rem;
		padding-bottom: 0.1rem;
		margin-right: 1rem;
	}
}
