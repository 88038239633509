.pageBody {
	flex-grow: 1;
	margin-bottom: 50px;
	display: flex;
}

.pageBodyContent {
	width: 100%;
	height: 100%;
}

.pageBackground {
	display: flex;
	background-color: #381533;
	flex-direction: column;
	min-height: 100vh;
}

.requestOnboardingBody {
	background-color: #381533;
	align-items: center;
	justify-content: center;
	padding-bottom: 50px;
}

.pageSideFiller {
	width: 5%;
	background-color: #381533;
	margin: 0;
}

.groupTitle {
	color: white;
	margin-top: 0;
	margin-left: 0;
	padding-top: 1rem;
	padding-bottom: 0;
	margin-bottom: 0;
	overflow-wrap: anywhere;
	font-size: 28px;
}

.tabTitle {
	color: white;
	margin-top: 0;
	padding-top: 1rem;
	padding-bottom: 0;
	margin-bottom: 0;
	overflow-wrap: anywhere;
	font-size: 28px;
}

.sectionTitle {
	color: white;
	margin-top: 0;
	margin-left: 0;
	padding-top: 1rem;
	padding-bottom: 0;
	margin-bottom: 0;
	overflow-wrap: anywhere;
	font-size: 20px;
}

.pageContentHeader {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.pageTitle {
	color: white;
	margin-top: 0;
	margin-left: 0.5rem;
	padding-top: 1.5rem;
	padding-bottom: 0;
	margin-bottom: 0;
	font-size: 42px;
	width: fit-content;
	overflow-wrap: anywhere;
}

.pageTitleLogoText {
	text-align: center;
	color: #c0fada;
	font-size: small;
}

.displayArea {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	flex-direction: row;
	margin-left: 0.5rem;
}

.productDefinitionCard {
	background-color: #4a2345;
	border: 1px solid white;
	height: fit-content;
	max-width: 300px;
	flex-grow: 1;
	border-radius: 8px;
	padding: 0.5rem;
	margin-bottom: 0.5rem;
	margin-right: 0.5rem;
}

.productDefinitionListItem {
	border-radius: 8px;
	border: 1px solid white;
	height: 100px;
	min-width: 210px;
	max-width: 235px;
	display: flex;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-right: 0.5rem;
	background-color: #4a2345;
	color: white;
	margin: 0.25rem;
	flex-basis: 19%;
	flex-grow: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.wasteTypeListItem {
	border-radius: 8px;
	border: 1px solid white;
	height: 100px;
	min-width: 210px;
	max-width: 237px;
	display: flex;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-right: 0.5rem;
	background-color: #4a2345;
	color: white;
	margin: 0.25rem;
	flex-basis: 19%;
	flex-grow: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.productDefinitionListItemSmall {
	border-radius: 8px;
	border: 1px solid white;
	height: 100px;
	min-width: 210px;
	display: flex;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-right: 0.5rem;
	background-color: #4a2345;
	color: white;
	margin: 0.25rem;
	flex-basis: 19%;
	flex-grow: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}
.wasteTypeListItem:focus,
.transportationListItem:focus,
.productDefinitionListItemSmall:focus,
.productDefinitionListItem:focus {
	-webkit-animation: fadeOut 1s ease-in-out backwards;
	animation: fadeOut 1s ease-in-out backwards;
}
.wasteTypeListItem:active,
.transportationListItem:active,
.productDefinitionListItemSmall:active,
.productDefinitionListItem:active {
	-webkit-animation: none;
}

@keyframes fadeOut {
	0% {
		background-color: #c0fada;
		color: black;
	}
	100% {
		background-color: #4a2345;
		color: white;
	}
}

@-moz-keyframes fadeOut {
	0% {
		background-color: #c0fada;
		color: black;
	}
	100% {
		background-color: #4a2345;
		color: white;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		background-color: #c0fada;
		color: black;
	}
	100% {
		background-color: #4a2345;
		color: white;
	}
}

@-o-keyframes fadeOut {
	0% {
		background-color: #c0fada;
		color: black;
	}
	100% {
		background-color: #4a2345;
		color: white;
	}
}

@-ms-keyframes fadeOut {
	0% {
		background-color: #c0fada;
		color: black;
	}
	100% {
		background-color: #4a2345;
		color: white;
	}
}

.headerActionArea {
	vertical-align: center;
	align-items: center;
	margin-left: auto;
	height: 100%;
	margin-bottom: 1rem;
}

.headerActionButton {
	float: right;
	border-radius: 30px;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	margin-top: 2rem;
	border: none;
	background-color: #befcda;
	height: 2.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	width: 215px;
	color: black;
	-webkit-appearance: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalXButton {
	float: right;
	border-radius: 30px;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	margin-top: 2rem;
	border: none;
	background-color: white;
	height: 2.5rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	width: 40px;
	height: 40px;
	-webkit-appearance: none;
	color: black;
}

.modalCloseButton {
	border-radius: 30px;
	margin-top: 1rem;
	border: none;
	background-color: #befcda;
	height: 2.5rem;
	padding: 0;
	width: 100%;
	-webkit-appearance: none;
	color: black;
}

.saveCommentButton:hover,
.modalXButton:hover,
.modalCloseButton:hover,
.headerActionButton:hover {
	background-color: #7bd2b4;
	transition: 1.5s;
	-webkit-appearance: none;
}

.transportationListItem {
	background-color: #4a2345;
	color: white;
	border: 1px solid white;
	border-radius: 8px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.5rem;
	flex-grow: 1;
	margin: 0.25rem;
	max-width: 480px;
}

.transportationListItemImage {
	background-color: white;
	border: none;
	height: fit-content;
	border-radius: 8px;
	margin-bottom: 0.5rem;
	padding-left: 1rem;
}

.productDimensionArea {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	width: 100%;
}

.serviceTimeSlotGrid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
}

.inputTextArea {
	width: 100%;
	height: 100px;
	border-radius: 6px;
	border: none;
	resize: none;
	background-color: #5f3b5a;
	color: white;
	padding: 0.5rem;
}

.inputTextAreaSlim {
	width: 100%;
	height: 50px;
	border-radius: 6px;
	resize: none;
	background-color: #5f3b5a;
	color: white;
	padding: 0.5rem;
}

.fitContent {
	width: fit-content;
}

.sectionIcon {
	width: fit-content;
	margin-left: 1rem;
	margin-top: 1rem;
	margin-right: 0.5rem;
}

.commentSectionIcon {
	width: fit-content;
	margin-left: 0.5rem;
	margin-top: 1rem;
	margin-right: 0.5rem;
}

.commentSection {
	width: fit-content;
	margin-left: 0.5rem;
	margin-top: 1rem;
	margin-right: 0.5rem;
}

.inputTextBox {
	height: 2rem;
	width: 100%;
	border-radius: 6px;
	border: none;
	background-color: #5f3b5a;
	color: white;
	padding-left: 0.5rem;
}

.clientDetailsContent {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	width: 100%;
}

.section {
	background-color: #4a2345;
	border-radius: 6px;
	padding-bottom: 1rem;
	margin-top: 1rem;
	margin-right: 0.5rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	margin-bottom: 0;
	flex-grow: 1;
}

.sectionInputArea {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: flex-start;
}

.sectionInput {
	flex-grow: 1;
	margin: 0.5rem;
	min-width: 180px;
	max-width: 350px;
}

.clientSectionHeader {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	width: 100%;
	height: fit-content;
}

.sectionDescriptionInHeader {
	margin-left: 1rem;
	margin-top: 0.5rem;
	display: inline-block;
}

.sectionDescriptionBelowHeader {
	display: none;
	margin-left: 0.5rem;
}

.dimensionInput {
	margin: 0.25rem;
	flex-grow: 1;
	flex-basis: 30%;
}

.dimensionInputTextBox {
	height: 2rem;
	padding-right: 10px;
	border-radius: 6px;
	border: none;
	background-color: #5f3b5a;
	color: white;
	padding-left: 0.5rem;
	width: 100%;
}

.dimensionInputTextBoxUnit {
	margin-left: -30px;
	padding-left: 0.1rem;
	color: white;
}

.inputTextBox::-webkit-outer-spin-button,
.inputTextBox::-webkit-inner-spin-button,
.dimensionInputTextBox::-webkit-outer-spin-button,
.dimensionInputTextBox::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.slimTitle {
	font-weight: lighter;
	width: fit-content;
	margin-left: 0;
	margin-bottom: 0.25rem;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	display: inline-block;
	color: white;
	font-size: 16px;
}

.intervallOffset {
	margin-top: 0.25rem;
}

.productImageMedium {
	height: 10em;
	width: 10em;
}

.productImageBackground {
	padding-bottom: 0.5rem;
	background-color: #f7f7f7;
	border-radius: 6px;
	padding-top: 1rem;
}

.transportImageBackground {
	padding-bottom: 0.5rem;
	background-color: #f7f7f7;
	border-radius: 6px;
	padding-top: 1rem;
	padding-left: 1rem;
}

.productImageSmallInList {
	height: 5rem;
	width: 5rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.productImageSmallTitle {
	width: 100%;
	margin-top: 0.25rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0;
	overflow-wrap: anywhere;
}

.indentSection {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	margin-top: 0.5rem;
}

.tabFrame {
	margin-left: 0.5rem;
	margin-bottom: 1rem;
}

.commentSectionFrame {
	margin-left: 0.5rem;
	margin-bottom: 1rem;
	background-color: #4a2345;
	border-radius: 10px;
	margin-top: 1rem;
	margin-right: 0.5rem;
	padding-left: 0.5rem;
}

.commentHeaderActionOffset {
	margin-right: 0.5rem;
}
.stickySection {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	height: fit-content;
	background-color: #4a2345;
	border-radius: 10px;
	z-index: 2;
}

.productImageSmallTitleSection {
	width: 100%;
	position: relative;
}

.transportationModalFooter {
	width: 100%;
	position: relative;
	height: 35px;
}

@media (max-width: 1024px) {
	.tabName {
		display: none;
	}

	.containerIcon {
		margin: 0;
	}
	.tabButton {
		margin-left: 0;
	}
	.tabButtonSelected {
		margin-left: 0;
	}
	.commentSectionArea {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
@media (min-width: 932px) {
	.commentHistory {
		margin-right: 0.5rem;
	}
}
@media (min-width: 450px) and (max-width: 932px) {
	.commentSubSection {
		min-width: 400px;
	}
}

@media (max-width: 450px) {
	.commentSubSection {
		min-width: 200px;
	}
}

@media (max-width: 425px) {
	.sectionDescriptionInHeader {
		display: none;
	}

	.sectionDescriptionBelowHeader {
		display: block;
		margin-top: -1rem;
		margin-bottom: 1rem;
	}
}

@media (min-width: 425px) and (max-width: 850px) {
	.addTemplateButton {
		display: none;
	}
	.headerActionButton {
		padding-left: 0.75rem;
		padding-right: 0;
		-webkit-appearance: none;
		width: fit-content;
		height: 40px;
		width: 40px;
	}
	.addCommentButtonIcon {
		margin-left: -0.35rem;
	}
}

@media (max-width: 370px) {
	.addTemplateButton {
		display: none;
	}
	.headerActionButton {
		padding-left: 0.75rem;
		padding-right: 0;
		-webkit-appearance: none;
		width: fit-content;
		height: 40px;
		width: 40px;
	}
	.addCommentButtonIcon {
		margin-left: -0.35rem;
	}
}
.addTemplateButton {
	margin-right: 1rem;
}
.tabName {
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	font-size: 14px;
}

.tabGroups {
	display: flex;
	border-bottom: 2px solid white;
	flex-flow: row;
}

.tabButton {
	font-size: 1rem;
	color: white;
	padding-bottom: 0;
	padding-top: 2rem;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	margin-left: 1rem;
}

.tabButtonSelected {
	font-size: 1rem;
	color: white;
	border-bottom: 4px solid #c0fada;
	padding-bottom: 0;
	padding-top: 2rem;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	margin-left: 1rem;
}

.plusButtonDiv {
	position: absolute;
	bottom: 0;
	right: 0;
}

.plusButton {
	border-radius: 30px;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	margin-top: auto;
	border: none;
	background-color: #befcda;
	color: black;
	width: 30px;
	height: 30px;
	-webkit-appearance: none;
}

.wasteTypeListLargeIcon {
	height: 3rem;
	width: 3rem;
	margin: 0.5rem;
}

.listLargeIconSection {
	background-color: white;
	margin-left: 0.5rem;
	border-radius: 6px;
	height: fit-content;
}

.wasteTypeCardLargeIcon {
	height: 4rem;
	width: 4rem;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.modalFooter {
	width: 100%;
	position: sticky;
	bottom: 0.5rem;
}

.footerContent {
	display: flex;
	flex-flow: row-reverse;
	justify-content: space-between;
}

.flexAddButton {
	border-radius: 30px;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	margin-top: auto;
	border: none;
	background-color: #befcda;
	-webkit-appearance: none;
	color: black;
	width: 30px;
	height: 30px;
}

.commentFooter {
	display: flex;
	flex-flow: row-reverse;
	justify-content: space-between;
	padding-bottom: 1rem;
}

.sendCommentButton {
	float: right;
	border-radius: 30px;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	border: none;
	background-color: #befcda;
	height: 2.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	min-width: 40px;
	min-height: 40px;
	-webkit-appearance: none;
	color: black;
}

.containerIcon {
	margin: auto;
}

.thrashIcon {
	stroke: #cc2319;
	fill: #cc2319;
}

.timeSlotItemText {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	margin-left: 0.5rem;
}

.timeSlotToggle {
	margin-left: 0.5rem;
}

.intervallRow {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.timeOfDay {
	margin-right: 0.5rem;
}

.timeOfDayDisabled {
	margin-right: 0.5rem;
	background-color: gray;
}

.tabSection {
	background-color: #4a2345;
	border-radius: 10px;
	margin-top: 1rem;
	margin-right: 0.5rem;
	padding-bottom: 1rem;
	padding-left: 0.5rem;
}

.templateModal {
	background-color: #4a2345;
	border-radius: 10px;
	margin-bottom: auto;
	padding-bottom: 1rem;
	border: 1px solid black;
	max-width: 1000px;
	padding-left: 0.25rem;
	padding-right: 0.25rem;
	overscroll-behavior: contain;
}

dialog::backdrop {
	background-color: black;
	opacity: 50%;
}

.disableVerticalScroll {
	overflow: hidden;
}

.sectionDescriptionText {
	color: white;
	overflow-wrap: break-word;
	font-size: 14px;
}

.tabDescriptionText {
	color: white;
	overflow-wrap: break-word;
	font-size: 16px;
}

.radioGroup {
	color: white;
	display: inline-block;
	width: 100%;
}

.radioGroupItem {
	background-color: #5f3b5a;
	display: flex;
	justify-content: space-between;
	height: 32px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 6px;
	margin-bottom: 0.5rem;
}

.radioGroupItemLabel {
	color: white;
	margin-top: 0.5rem;
}

.radioGroupItemSwitch {
	margin-top: 0.5rem;
}

.pageFooterButtonGroup {
	justify-content: space-around;
	display: flex;
	margin-bottom: 0.5rem;
	margin-right: 0.5rem;
	margin-left: 0.51rem;
	flex-flow: row wrap;
}

.leftPageFooterButton {
	font-weight: bold;
	font-size: 20px;
	flex-grow: 1;
	background-color: #4a2345;
	color: white;
	height: 50px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border: none;
	-webkit-appearance: none;
}

.saveMiddleButton {
	font-weight: bold;
	font-size: 20px;
	flex-grow: 3;
	background-color: #4a2345;
	color: white;
	height: 50px;
	border-top: none;
	border-bottom: none;
	border-right: 1px solid #381533;
	border-left: 1px solid #381533;
	-webkit-appearance: none;
}

.saveLeftButton {
	font-weight: bold;
	font-size: 20px;
	flex-grow: 4;
	background-color: #4a2345;
	color: white;
	height: 50px;
	border-top: none;
	border-bottom: none;
	border-right: 1px solid #381533;
	border-left: 1px solid #381533;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	-webkit-appearance: none;
}

.saveRightButton {
	font-weight: bold;
	font-size: 20px;
	flex-grow: 4;
	background-color: #4a2345;
	color: white;
	height: 50px;
	border-top: none;
	border-bottom: none;
	border-right: 1px solid #381533;
	border-left: 1px solid #381533;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	-webkit-appearance: none;
}

.rightPageFooterButton {
	font-weight: bold;
	font-size: 20px;
	flex-grow: 1;
	background-color: #4a2345;
	color: white;
	height: 50px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: none;
	-webkit-appearance: none;
}

.sendOnboardingRequest {
	font-weight: bold;
	font-size: 20px;
	background-color: #4a2345;
	color: white;
	height: 50px;
	border: none;
	border-radius: 10px;
	width: 100%;
	margin-top: 2rem;
	-webkit-appearance: none;
}

.sendOnboardingRequest:hover:enabled,
.leftPageFooterButton:hover:enabled,
.saveLeftButton:hover:enabled,
.saveMiddleButton:hover:enabled,
.saveRightButton:hover:enabled,
.rightPageFooterButton:hover:enabled {
	background-color: #c0fada;
	color: black;
	transition: 0.5s;
	-webkit-appearance: none;
}

.sendOnboardingRequest:disabled {
	background-color: gray;
}

.lottieWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-top: 0.5rem;
}

.lottieWrapperNoMargin {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.completOnboardingTitle {
	color: white;
	text-align: center;
}

.completOnboardingSubTitle {
	color: white;
	text-align: center;
}

.requestOnboardingPageContent {
	display: flex;
}

.requestOnboardingPageFiller {
	flex-grow: 1;
}

.requestOnboardingFormInputArea {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	max-width: 800px;
	margin-left: 1rem;
	margin-right: 1rem;
}

.requestOnboardingFormInputField {
	flex-grow: 1;
	max-width: 400px;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
	flex-basis: 49%;
}

.requestOnboardingFormInputTitle {
	font-weight: lighter;
	margin-left: 0;
	margin-bottom: 0.25rem;
	margin-top: 1rem;
	display: inline-block;
	color: white;
}

.lottieAnimation {
	margin: auto;
	height: clamp(240px, 40vh, 400px);
}

.lottieSmallAnimation {
	margin: auto;
	height: clamp(50px, 7.5vh, 75px);
}

.commentSectionArea {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
}

.commentSubSection {
	flex-grow: 1;
	border: none;
	resize: none;
	border-radius: 6px;
	color: white;
	flex-basis: 49%;
	margin-bottom: 1rem;
}
.commentEditor {
	background-color: #5f3b5a;
	resize: none;
	height: 300px;
	width: 100%;
	color: white;
	border: none;
	border-radius: 6px;
	padding: 1rem;
	margin-top: 0.5rem;
}
.saveCommentButton {
	border: none;
	background-color: #befcda;
	height: 40px;
	float: right;
	border-radius: 30px;
	margin-right: 10px;
	color: black;
	-webkit-appearance: none;
	margin-top: -60px;
	position: relative;
	z-index: 1;
	padding-left: 1rem;
	padding-right: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.saveCommentButtonText {
	font-size: 17px;
	margin-top: 0.65rem;
	margin-bottom: auto;
}

.commentHistory {
	height: 300px;
	flex-basis: 45%;
	border-radius: 6px;
	overflow: auto;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-top: 0.5rem;
}

.commentHistory > textarea {
	width: 100%;
	height: 100%;
	color: white;
	background-color: #5f3b5a;
}

.commentHistoryTimeStamp {
	text-transform: capitalize;
	font-size: 12px;
}

.commentHistoryText {
	margin-top: 0.5rem;
	text-transform: capitalize;
	font-size: 14px;
}

.commentEditor::placeholder {
	color: rgb(190, 187, 187);
	opacity: 1;
}

.tabDropdown {
	width: auto;
	z-index: 3;
	padding: 0;
	margin-left: 0.5rem;
	border-bottom: none;
	border-left: none;
	border-right: none;
	border-top: 3px solid #381533;
	left: 0.5rem;
}

.tabDropdownButton {
	display: flex;
	justify-content: flex-start;
	padding-left: 0.5rem;
	padding-top: 0.5rem;
}

.tabDropdownButton:hover {
	display: flex;
	justify-content: flex-start;
	padding-left: 0.5rem;
	padding-top: 0.5rem;
	background-color: #d0f3e7;
}

.tabDropdownButtonIcon {
	margin: 0 1rem 0.5rem 0;
}

.tabDropdownButtonName {
	margin: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
}

.tabDropdownButtonDescription {
	font-size: 14px;
}

.savingIcon {
	position: fixed;
	width: 100px;
	height: 100px;
	border-radius: 100px;
	background-color: #381533;
	top: 1rem;
	right: 1rem;
	z-index: 5;
}

.savedIcon {
	position: fixed;
	width: 100px;
	height: 100px;
	border-radius: 100px;
	background-color: #381533;
	top: 1rem;
	right: 1rem;
	z-index: 5;
	opacity: 0;
	width: 0;
	height: 0;
	transition: width 10s 10s, height 10s 10s, opacity 5s;
}

.chevronDown {
	transform: rotate(180deg);
	margin-bottom: 1rem;
}

.chevronUp {
	margin-bottom: 0;
	margin-top: 0.5rem;
}

.requestOnboardingLottieSize {
	height: 20rem;
}

.centerTitle {
	margin-top: auto;
	margin-bottom: auto;
}

@media (max-width: 150px) {
	.saveCommentButtonText {
		display: none;
	}
	.saveCommentButtonIcon {
		margin-left: 0.35rem;
	}
}
