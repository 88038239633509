.modal {
	height: calc(var(--100vh) - 20px);
	max-height: 450px;
}

.wrapper {
	display: flex;
	flex-direction: column;
}

.fields {
	height: calc(100vh - 182px);
	overflow-y: scroll;
	max-height: 282px;
	margin-bottom: 10px;
	grid-auto-rows: minmax(min-content, max-content) !important;
	grid-template-rows: minmax(min-content, max-content);
}

.fields > label {
	min-height: 90px !important;
}

.header {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
}

.profilePicture {
	width: 56px;
	height: 56px;
	background-color: #6f6f70;
	border-radius: 100px;
	display: block;
	overflow: hidden;
	position: relative;
}

.profilePicture > img {
	width: 56px;
	height: 56px;
	border-radius: 100px;
	object-fit: cover;
}

.itemContent {
	grid-template-columns: 75px auto;
	display: grid;
}

.finalize {
	margin-top: 10px;
}

.item {
	cursor: pointer;
}

@media (max-width: 710px) {
	.item {
		grid-template-columns: auto;
	}

	.item > button {
		display: none;
	}

	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}

	.fields {
		max-height: unset;
	}
}
