.modal {
	max-height: calc(var(--100vh) * 0.85);
}

.header {
	color: var(--section-background-text-color);
	font-size: 28px;
	margin-bottom: 20px;
	font-weight: 600;
}

.inputField input {
	width: 100%;
	height: 2.625em;
	border-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	margin-bottom: 10px;
}

.inputField input:focus {
	outline: 0.7px solid var(--chosen-border-color);
}

.iconColorStroke {
	stroke: var(--section-icon-color);
}

.iconColorFill {
	fill: var(--section-icon-color);
}

.iconColorStroke.disabled {
	stroke: var(--disable-inside-color);
}

.iconColorFill.disabled {
	fill: var(--disable-inside-color);
}

.deleteContactPerson {
	display: flex;
	gap: 10px;
	margin-bottom: 30px;
	align-items: center;
	cursor: pointer;
	color: var(--section-background-text-color);
}

.deleteContactPerson .disabled {
	color: var(--disable-inside-color);
	cursor: not-allowed;
}

.deleteContactPerson:hover {
	color: var(--section-hover-color);
}

.deleteContactPerson:hover .iconColorStroke:not(.disabled) {
	stroke: var(--section-hover-color);
}

.deleteContactPerson:hover .iconColorFill:not(.disabled) {
	fill: var(--section-hover-color);
}

.deleteContactPerson > span {
	font-weight: 600;
}

.modal form > button {
	margin-top: 40px;
}

.sbt {
	color: var(--section-background-text-color);
}

@media (max-width: 710px) {
	.modal {
		max-height: none;
	}

	.modal form {
		display: flex;
		flex-direction: column;
		height: calc(100% - 52px);
	}

	.modal form > button {
		margin-top: auto;
		min-height: 2.625em;
	}
}
