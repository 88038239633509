.modal {
	padding-top: 0;
}

.productImageWrapper {
	width: 100%;
	height: 20em;
	aspect-ratio: 1 / 1;
	background-color: var(--product-image-background-color);
}

.productImageWrapper > svg {
	width: calc(100% * 0.7);
}

.productUrlImageWrapperFit {
	display: flex;
	height: 22em;
	align-items: center;
	justify-content: center;
}

.productUrlImageWrapperFill {
	height: 22em;
	overflow: hidden;
	position: relative;
	width: calc(100%);
}

.productUrlImageFill {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.productUrlImageFit {
	width: calc(70%);
	height: calc(100%);
	padding-top: 20px;
	object-fit: contain;
}

.urlImageFillModalWrapper {
	padding-left: 0;
	padding-right: 0;
}

.contentWrapperImageFill {
	padding-left: 1.375em;
	padding-right: 1.375em;
	display: block;
}

.wrapper {
	padding-top: 20px;
	overflow-y: scroll;
	height: calc(var(--100vh) * 0.8);
}

.contentAndImageWrapperImageFill {
	padding-top: 0;
	border-top-left-radius: 0.75em;
	border-top-right-radius: 0.75em;
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	min-height: calc(100% - 356px);
	padding-bottom: 3.5rem;
}

.productDetails {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.wrapper::-webkit-scrollbar {
	display: none;
}

.hrSection {
	background-color: var(--section-background-text-color);
	border-top: 1px solid var(--section-background-text-color);
	width: calc(100% - 2px); /* 1px border on right and left */
}

.sectionText {
	color: var(--section-background-text-color);
}

.mbText {
	color: var(--module-box-text-color);
}

.productNameWrapper {
	display: flex;
	justify-content: space-between;
	color: var(--section-background-text-color);
	position: relative;
	align-items: center;
	margin-top: 10px;
	row-gap: 15px;
	column-gap: 10px;
}

.productNameWrapper > .productName {
	margin: 0;
	font-size: 1.75em;
	font-weight: 600;
}

.productDescription {
	margin-top: 5px;
	color: var(--section-background-text-color);
}

.productDescription > h2:first-child {
	margin-top: 0;
	margin-block-start: 0;
}

.twoLineClamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
}

.subHeader {
	font-size: 22px;
	font-weight: 600;
	color: var(--section-background-text-color);
}

.productDescriptionWrapper {
	margin: 0;
	border-radius: 9px;
}

.productDescriptionExpandHeader {
	display: flex;
	justify-content: space-between;
	padding: 10px 15px 10px 10px;
	font-size: 20px;
	background-color: var(--collapsible-title-background-color);
	color: var(--collapsible-title-color);
	border-radius: 6px;
	cursor: pointer;
	flex-wrap: wrap;
}

.productDescriptionExpandWrapper {
	padding-left: 15px;
	padding-right: 15px;
	height: 0;
	overflow: hidden;
}

.productDescriptionExpandWrapper .transportName {
	color: var(--section-background-text-color);
	font-size: 22px;
	font-weight: 600;
}

.productDescriptionExpandWrapper .infoText {
	font-weight: 500;
	font-size: 20px;
	color: color-mix(in srgb, white 50%, var(--section-background-text-color));
}

.productDescriptionExpanded {
	height: auto;
	margin-top: 10px;
}

.productDescriptionToggle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.productDescriptionToggle > span {
	font-size: 16px;
	font-weight: 600;
}

.serviceSelector {
	font-size: 20px;
	padding: 8px 15px 8px 15px;
	background-color: var(--accent-secondary-color);
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: min(100%, 220px);
	gap: 10px;
	cursor: pointer;
	color: var(--accent-secondary-inside-color);
	align-self: flex-start;
	position: relative;
	width: 0;
}

.serviceSelector > span {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.serviceSelector:hover:not(.serviceSelectorOpen) {
	background-color: var(--accent-secondary-hover-color);
}

.serviceSelectorDropdown {
	position: absolute;
	top: 40px;
	right: 0;
	width: calc(var(--100vw) - 22px);
	border-radius: 6px;
	max-width: 220px;
}

.serviceSelectorDropdownItem {
	cursor: pointer;
	padding: 15px;
	border-radius: 6px;
	background-color: var(--accent-secondary-color);
	color: var(--accent-secondary-inside-color);
	word-break: break-all;
}

.serviceSelectorDropdownItem:hover {
	background-color: var(--accent-secondary-hover-color);
}

.serviceSelectorDropdown .serviceSelectorDropdownItem {
	border-radius: 0;
}

.serviceSelectorDropdown .serviceSelectorDropdownItem:first-child {
	border-radius: 6px 6px 0 0;
}

.serviceSelectorDropdown .serviceSelectorDropdownItem:last-child {
	border-radius: 0 0 6px 6px;
}

.productSelectionNameAndRowsWrapper {
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	margin: 0;
	padding-left: 1px;
	padding-right: 1px;
}

.productSelectionRowsHeader {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 15px;
}

.productSelectionRow {
	margin: 0;
	background-color: var(--module-box-color);
	box-shadow: 0 1px 2px #00000029;
	border-radius: 13px;
	padding: 15px 15px 10px 15px;
	font-size: 18px;
	font-weight: 600;
	display: grid;
	gap: 10px;
	grid-template-columns: 2fr auto;
	height: auto;
	min-height: 120px;
}

.productSelectionRowName {
	color: var(--module-box-text-color);
	display: flex;
	flex-direction: column;
	gap: 5px;
	justify-content: space-between;
}

.productSelectionRowIncrementor {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-height: 45px;
	margin-top: auto;
}

.finalize {
	margin-top: auto;
	box-shadow: 0 1px 6px #0000004d;
	width: calc(100% - 46px);
	position: fixed;
	left: 23px;
	bottom: 20px;
}

svg.wasteIcon {
	fill: var(--module-box-text-color);
	stroke: var(--module-box-text-color);
	min-width: 34px;
	min-height: 34px;
	margin-right: 3px;
	margin-left: auto;
}

.price {
	color: var(--module-box-text-color);
	font-weight: normal;
	font-size: 16px;
}

.productSelectionRow .informationIcon {
	fill: var(--module-box-text-color);
}

.searchBox {
	background-color: var(--input-field-color);
	height: 44px;
	border-radius: 50px;
	padding: 10px;
	display: flex;
	max-width: min(100%, 300px);
	margin-bottom: 15px;
	width: auto;
}

.searchBtn {
	color: var(--input-field-inside-color);
	border-radius: 50px;
	text-decoration: none;
	transition: 0.3s;
	cursor: default;
	align-self: center;
}

.searchInput {
	outline: none;
	border: none;
	background: none;
	width: 0;
	padding: 0;
	color: var(--input-field-inside-color);
	float: left;
	font-size: 16px;
	transition: 0.3s;
}

.searchInput::-webkit-search-cancel-button {
	display: none;
}

.searchInput::placeholder {
	color: color-mix(in srgb, white 50%, var(--input-field-inside-color));
	filter: brightness(90%);
}

.searchInput:focus,
.searchInput:not(:placeholder-shown) {
	width: 280px;
	padding: 0 6px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.searchBox:hover > .searchInput {
	width: 280px;
	padding: 0 6px;
}

.serviceSelectorIcon {
	display: none;
}

@media (max-width: 710px) {
	.contentAndImageWrapperImageFill {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.wrapper {
		height: calc(var(--100vh) - 80px);
	}
}

@media (max-width: 450px) {
	.serviceSelector {
		min-width: unset;
		width: auto;
	}

	.serviceSelectorValue {
		display: none;
	}

	.serviceSelectorIcon {
		display: block;
	}
}

@media (max-width: 370px) {
	.productSelectionRow {
		height: auto;
		grid-template-columns: auto;
	}

	.productNameWrapper .twoLineClamp {
		align-self: flex-start;
	}
}
