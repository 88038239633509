:root {
	/* Branding Colors */
	--header-color: #381433;
	--header-divider-color: #381433;
	--header-shadow-color: #cccccc;
	--header-icon-color: #ffffff;
	--header-notification-color: #ffb900;
	--header-notification-inside-color: #ffffff;
	--header-text-color: #ffffff;
	--header-chosen-color: #441843;
	--header-hover-color: #501b4c;

	--header-button-color: #ffffff;
	--header-button-inside-color: #000000;
	--header-button-inside-hover-color: #c71b1b;

	--header-dropdown-main: #ffffff;
	--header-dropdown-main-text: #000000;

	--header-dropdown-hover: #ffbcd4;

	--header-dropdown-chosen: #f58c99;
	--header-dropdown-chosen-inside: #ffffff;

	--header-dropdown-second-text: #f61c33;

	--header-dropdown-accent: #ff919d;
	--header-dropdown-accent-inside: #000000;
	--header-dropdown-accent-hover: #e5e5e5;
	--header-dropdown-accent-hover-inside: #000000;

	--header-dropdown-selected-color: #ff919d;
	--header-dropdown-selected-color-inside: #ffffff;
	--header-dropdown-selected-hover-color: #e67380;
	--header-dropdown-selected-hover-color-inside: #ffffff;

	--chat-notification-color: #aaaaaa;
	--chat-notification-inside-color: #aaaaaa;
	--chat-notification-hover-color: #aaaaaa;

	--footer-color: #381433;
	--footer-logo-color: #ffffff;

	--accent-color: #feba02;
	--accent-hover-color: #f9ce58;
	--accent-hover-inside-color: #000000;
	--accent-inside-color: #050505;

	--finalize-color: #84dabc;
	--finalize-hover-color: #b6ecda;
	--finalize-hover-inside-color: #ffffff;
	--finalize-inside-color: #ffffff;

	--disable-color: #dedede;
	--disable-inside-color: #fdfdfd;

	--section-value-box-color: #febb00;
	--section-value-box-hover-color: #84dabc;
	--section-value-box-hover-inside-color: #ffffff;
	--section-value-box-inside-color: #000000;

	--selected-section-value-box-color: #f5f5f5;
	--selected-section-value-box-hover-color: #e6e6e6;
	--selected-section-value-box-inside-color: #222222;

	--confirmed-amount-color: #febb00;
	--confirmed-amount-inside-color: #464645;

	--section-background-color: #f5f5f5;
	--section-background-text-color: #222222;
	--selected-section-background-color: #ffffff;
	--section-icon-color: #222222;

	--module-box-color: #ebebeb;
	--module-box-icon-color: #222222;
	--module-box-text-color: #222222;
	--module-box-discount-color: #7bd2b4;

	--input-field-color: #fdfdfd;
	--input-field-inside-color: #222222;

	--main-background-color: #ffffff;
	--main-background-text-color: #4a4a4a;

	--hover-border-color: #b7b7b7;
	--chosen-border-color: #575756;
	--invalid-color: #ea0000;
	--valid-color: #07d837;

	--product-base-color: #bbb;
	--product-secondary-color: #777778;
	--product-details-color: #969696;
	--product-image-background-color: #f5f5f5;
	--product-section-color: #f5f5f5;
	--product-section-text-color: #222222;
	--product-border-color: #d2d2d2;

	/* Branding Scalar values */
	--header-logo-height: 50%;

	--client-notification-color: #00f5ff;
	--client-notification-inside-color: #151253;

	--filter-button-color: #92d8bf;
	--filter-button-hover-color: #78b5a1;
	--filter-button-inside-color: #ffffff;
	--filter-button-hover-inside-color: #ffffff;

	--section-header-color: #381633;
	--section-header-inside-color: #ffffff;
	--section-border-color: #ffffff;
	--section-hover-color: #ececec;
	--section-discount-color: #7bd2b4;

	--accent-secondary-color: #90cfb6;
	--accent-secondary-hover-color: #111322;
	--accent-secondary-inside-color: #ffffff;

	--status-not-managed-color: #fce738;
	--status-not-managed-inside-color: #000000;

	--status-accepted-color: #7fd0b5;
	--status-accepted-inside-color: #ffffff;

	--status-denied-color: #c22425;
	--status-denied-inside-color: #ffffff;

	--status-done-color: #226728;
	--status-done-inside-color: #ffffff;

	--sidebar-color: #f6f6f6;
	--sidebar-chosen-color: #e3fff0;
	--sidebar-hover-color: #e3fff0;
	--sidebar-icon-color: #000000;
	--sidebar-text-color: #000000;
	--sidebar-logo-color: #000000;

	--input-field-color-mback: #f6f5f6;

	--delivery-color: #000000;
	--delivery-measurement-color: #befcda;
	--delivery-measurement-insite-color: #000000;

	--under-header-color: #c0bfc0;
	--under-header-inside-color: #ffffff;
	--under-header-chosen-color: #604860;
	--under-header-chosen-inside-color: #ffffff;
	--under-header-hover-color: #486048;
	--under-header-hover-inside-color: #ffffff;

	--basket-main-color: #ededed;
	--basket-main-text-color: #6e6e6e;
	--basket-main-icon-color: #404040;

	--basket-background-color: #ffffff;
	--basket-background-text-color: #303030;
	--basket-background-icon-color: #0d0d0d;

	--basket-module-color: #e8e8e8;
	--basket-module-text-color: #0d0d0d;
	--basket-module-icon-color: #feba02;

	--basket-section-color: #f5f5f5;
	--basket-section-text-color: #000000;
	--basket-section-icon-color: #141414;

	--basket-finalize-color: #2ec6c6;
	--basket-finalize-inside-color: #ffffff;
	--basket-finalize-icon-color: #f2bef5;
	--basket-finalize-accent-color: #befcda;
	--basket-finalize-accent-inside-color: #c62ea9;

	--module-bubble-consumer-color: #d2dde8;
	--module-bubble-consumer-text-color: #171717;

	--module-bubble-client-color: #4e91dc;
	--module-bubble-client-text-color: #171717;

	--image-scroll-accent-inside-color: #050505;
	--image-scroll-accent-hover-color: #f9ce58;
	--image-scroll-accent-hover-inside-color: #feba02;

	--collapsible-title-background-color: #feba02;
	--collapsible-title-color: #050505;
	--collapsible-content-background-color: #ffffff;
	--collapsible-content-color: #050505;

	--toggle-background-color: #ebebeb;
	--toggle-background-inside-color: #000000;
	--toggle-selected-color: #ffffff;
	--toggle-selected-inside-color: #000000;
}
