/* Switch */

div.btnContainer {
	display: block;
	width: 100%;
}

label {
	font-size: 16px;
	color: #424242;
	font-weight: 500;
}

.btnIndividualOrCompanySwitch {
	width: 100%;
	display: inline-block;
	margin: 0;
	position: relative;
}

.btnIndividualOrCompanySwitch > label.btnIndividualOrCompanySwitchInner {
	margin: 0;
	width: 216px;
	height: 42px;
	background: white;
	border-radius: 26px;
	overflow: hidden;
	position: relative;
	transition: all 0.3s ease;
	display: block;
	cursor: pointer;
}

.btnIndividualOrCompanySwitch > label.btnIndividualOrCompanySwitchInner:before {
	content: attr(data-on);
	position: absolute;
	font-size: 16px;
	font-weight: 500;
	top: 13px;
	right: 10px;
}

.btnIndividualOrCompanySwitch > label.btnIndividualOrCompanySwitchInner:after {
	content: attr(data-off);
	width: 105px;
	height: 38px;
	background: black;
	color: white;
	border-radius: 26px;
	position: absolute;
	left: 2px;
	top: 2px;
	text-align: center;
	transition: all 0.3s ease;
	box-shadow: 0 0 6px -2px #111;
	align-items: center;
	display: flex;
	justify-content: space-evenly;
}

.btnIndividualOrCompanySwitch input[type="checkbox"] {
	width: 1px;
	height: 1px;
	opacity: 0;
	position: absolute;
	top: 0;
	z-index: 1;
	margin: 0;
}

.btnIndividualOrCompanySwitch input[type="checkbox"]:checked + label.btnIndividualOrCompanySwitchInner {
	background: white;
	color: black;
}

.btnIndividualOrCompanySwitch input[type="checkbox"]:checked + label.btnIndividualOrCompanySwitchInner:after {
	content: attr(data-on);
	left: 110px;
	background: #3c3c3c;
}

.btnIndividualOrCompanySwitch input[type="checkbox"]:checked + label.btnIndividualOrCompanySwitchInner:before {
	content: attr(data-off);
	right: auto;
	left: 30px;
	top: 13px;
}

.btnIndividualOrCompanySwitch input[type="checkbox"]:checked ~ .alert {
	display: block;
}

/* End Switch */

.fields input:focus-visible {
	outline: var(--chosen-border-color) 0.7px solid;
}

.input {
	width: 100%;
	height: 2.625em;
	border-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	margin-bottom: 10px;
}

.input:disabled {
	cursor: not-allowed;
}

.mandatoryFieldInformation {
	color: var(--section-background-text-color);
	font-size: 1em;
	text-align: end;
	margin: 0 0 10px;
}

.moduleBoxIconColorStroke {
	stroke: var(--module-box-icon-color) !important;
}

.cursorPointer {
	cursor: pointer;
}

.modal {
	max-height: calc(var(--100vh) * 0.95);
}

.wrapper {
	height: 100%;
	border-radius: 0.75em;
	z-index: 1002;
	position: relative;
	min-height: 115px;
	display: flex;
	flex-direction: column;
}

.wrapper button {
	margin-top: 20px;
	min-height: 42px;
}

.customerInfoWrapper {
	position: relative;
	background-color: var(--module-box-color);
	border-radius: 4px;
	padding: 10px;
	min-height: 80px;
}

.customerInfo {
	color: var(--module-box-text-color);
}

.customerInfoHeader > h5 {
	margin-bottom: 5px;
	font-weight: 500;
}

.customerInfo > div:not(:first-of-type) {
	font-size: 14px;
}

.customerInfoHeader {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 1599px) {
	.customerInfoHeader > h2 {
		font-size: 20px !important;
	}
}

@media (max-width: 710px) {
	.modal {
		padding-top: 0;
	}

	.wrapper {
		overflow-y: auto;
		padding-top: 32px;
	}

	.wrapper button {
		margin-top: auto;
	}

	.modal {
		max-height: unset;
	}
}
