.wrapper {
	position: relative;
	background-color: var(--section-background-color);
	padding: 15px 20px 20px 20px;
	border-radius: 12px;
	width: 100%;
	min-height: 250px;
}

.header {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
	margin-bottom: 1.5rem;
}

.usersWrapper {
	display: flex;
	flex-direction: column;
	height: fit-content;
	max-height: 800px;
	overflow-y: auto;
	margin-bottom: 20px;
}

.userRow {
	display: grid;
	grid-template-columns: 50px auto;
	background-color: var(--module-box-color);
	padding: 12px;
	border-radius: 6px;
	min-height: 65px;
	margin-bottom: 10px;
	color: var(--module-box-text-color);
}

.userRow:hover {
	border: 1px solid var(--hover-border-color);
	cursor: pointer;
}

.newUserButtonWrapper {
	margin-top: 25px;
}

.newUserButtonWrapper button {
	-webkit-appearance: none;
	border: none;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	padding: 8px 20px 8px 20px;
	border-radius: 26px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	font-weight: 600;
}

.newUserButtonWrapper button:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
	cursor: pointer;
}

.newUserButtonWrapper button > span:nth-of-type(1) {
	font-size: 14px;
}

.newUserButtonWrapper button > span:nth-of-type(2) {
	font-size: 24px;
}

.userRole {
	padding: 5px 15px 5px 15px;
	border-radius: 26px;
	border: 1px solid var(--chosen-border-color);
	font-size: 16px;
	font-weight: 600;
	display: flex;
	align-items: center;
	line-height: 16px;
	justify-content: center;
}

.noProfilePic {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
	color: black;
}

.profilePic {
	width: 40px;
	min-width: 40px;
	height: 40px;
	min-height: 40px;
	overflow: hidden;
	border-radius: 100px;
	background-color: rgb(165, 165, 165);
	cursor: pointer;
}

.profilePic img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.twoLineClamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
}

.moduleBoxIconColorStroke {
	stroke: var(--module-box-icon-color);
}

.moduleBoxIconColorFill {
	fill: var(--module-box-icon-color);
}

.removeUser {
	margin-left: auto;
}

.removeUser:hover {
	cursor: pointer;
}

.userRow .userRowName {
	font-size: 14px;
	padding-right: 10px;
	margin-top: auto;
	margin-bottom: auto;
}

.invitationRow {
	background-color: var(--module-box-color);
	padding: 12px;
	border-radius: 6px;
	min-height: 65px;
	margin-bottom: 10px;
}

.invitationMainDetails {
	display: grid;
	grid-template-columns: auto 25px;
}

.userRow .userRowRole {
	margin-top: auto;
	margin-bottom: auto;
}

.inviteRowName {
	font-size: 14px;
	padding-right: 10px;
	margin-top: auto;
	margin-bottom: auto;
}
.trashIcon svg {
	stroke: var(--module-box-icon-color);
	fill: var(--module-box-icon-color);
	margin-top: 0.25rem;
}
.resendIcon svg {
	stroke: var(--module-box-icon-color);
	fill: var(--module-box-icon-color);
	margin-bottom: 0.25rem;
}

.inviteExtraDetailSection {
	display: grid;
	grid-template-columns: 59% 39%;
	column-gap: 1rem;
	row-gap: 1rem;
}

.inviteExtraDetailSectionButton {
	display: flex;
	flex-direction: row;
	padding: 0.5rem;
	border-radius: 15px;
}

.inviteExtraDetailSectionButton:hover {
	display: flex;
	background-color: var(--accent-hover-color);
	transition: 1.5s;
	padding: 0.5rem;
	border-radius: 15px;
}

.inviteExtraDetailSectionButtonText {
	margin-left: 1rem;
	margin-top: auto;
	margin-bottom: auto;
}

.inviteExtraDetailInfo {
	display: flex;
	flex-direction: column;
}

@media (max-width: 1100px) {
	.header {
		font-size: 20px;
	}
}
