.metaHeading {
	font-weight: bold;
	margin-bottom: 0.5rem;
	color: var(--text-color-1);
}

.table {
	text-align: left;
	align-content: center;
	border-collapse: collapse;
	width: 100%;
}

.listRow {
	border-bottom: 1px solid var(--product-base-color);
}

.listCell {
	padding-bottom: 0.6rem;
	padding-top: 0.6rem;
}

.iconColor {
	fill: var(--icon-color-1);
}

@media (max-width: 768px) {
	.listCell {
		padding-bottom: 0.6rem;
		padding-top: 0.6rem;
		width: 50%;
	}

	.tableBody:first-child {
		border-top: 1px solid var(--product-base-color);
	}
}
