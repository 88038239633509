.modal {
	height: calc(var(--100vh) - 20px);
	overflow-y: scroll;
	max-height: 562px;
}

.modalWrapper {
	max-width: 520px;
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 510px;
	min-width: 300px;
}

.form {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-grow: 1;
}

.form > button {
	margin-top: auto;
}

.form input {
	margin-bottom: 0;
}

.inputWrapper {
	margin-bottom: 10px;
	display: flex;
}

.inputWrapper > input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.inputWrapper > div {
	background-color: var(--input-field-color);
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	display: flex;
	height: 42px;
}

.inputWrapper > svg,
.inputWrapper > div > svg {
	width: 20px;
	align-self: center;
	margin-left: 10px;
	margin-right: 15px;
	color: var(--input-field-inside-color);
}

.wrapper {
	max-width: 480px;
	min-width: 350px;
	flex: 1;
	flex-shrink: 2;
}

.toggleWrapper {
	margin-bottom: 25px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	row-gap: 10px;
}

@media (max-width: 710px) {
	.modalWrapper {
		max-width: unset;
		min-width: unset;
	}

	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}

	.finalize {
		margin-top: auto;
		min-height: 42px;
	}
}
