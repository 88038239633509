.wrapper {
	background-color: var(--section-background-color);
	min-width: 300px;
	max-width: 600px;
	border-radius: 8px;
	width: 100%;
	padding: 0 20px 20px 20px;
	height: 100%;
	min-height: 400px;
	overflow-y: auto;
}

.searchInputWrapper {
	display: flex;
	flex-wrap: nowrap;
	height: 45px;
	flex-grow: 1;
}

.searchIcon {
	border-top-left-radius: 22px;
	border-bottom-left-radius: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--input-field-inside-color);
	background-color: var(--input-field-color);
	padding-left: 1rem;
	cursor: pointer;
}

.searchInput {
	margin-bottom: 1rem;
	width: 100%;
	padding-left: 20px;
	height: 45px;
	-webkit-appearance: none;
	border: none;
	color: var(--input-field-inside-color);
	background-color: var(--input-field-color);
	border-radius: 0;
}

.searchInput:focus {
	border: none;
	outline: none;
}

.clearSearchButton {
	border: 0;
	background-color: var(--input-field-color);
	border-top-right-radius: 22px;
	border-bottom-right-radius: 22px;
	padding-right: 1rem;
}

.closeXIcon {
	cursor: pointer;
	background-color: var(--input-field-color);
}

.closeXIconInside {
	stroke: var(--module-box-icon-color);
	fill: none;
}

.listItem {
	cursor: pointer;
}

.statusSelectorWrapper {
	margin-bottom: 30px;
	margin-right: 4px;
}

.statusSelector {
	font-size: 20px;
	width: 100%;
	border-bottom: 1px solid var(--section-border-color);
	padding-bottom: 10px;
	color: var(--section-background-text-color);
	display: flex;
	justify-content: center;
	cursor: pointer;
	min-width: 110px;
	padding-top: 15px;
}

.statusSelector:hover {
	background-color: var(--section-hover-color);
	border-bottom: 8px solid var(--accent-color);
}

.selectedStatus {
	font-weight: 600;
	border-bottom: 8px solid var(--accent-color);
}

.statusButton {
	height: 1.625em;
}

.newProjectButton {
	max-width: 200px;
	display: flex;
	justify-content: space-between;
	padding: 10px 15px;
	align-items: center;
	gap: 20px;
	width: unset;
}

.listWrapper {
	max-height: 400px;
	overflow-y: scroll;
}

.horizontalScrollCell {
	display: flex;
}

@media (max-width: 1100px) {
	.listItem {
		grid-template-columns: auto 85px;
	}

	.listItem h5 {
		font-size: 18px;
	}

	.statusSelector {
		font-size: 16px;
		min-width: 90px;
	}
}
