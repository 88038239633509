/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 37px;
	min-width: 37px;
	height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--disable-color);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	display: flex;
	align-items: center;
	padding-left: 3px;
}

.slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	left: 0;
	bottom: 0;
	background-color: var(--disable-inside-color);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: var(--finalize-color);
}

input:checked + .slider:before {
	background-color: var(--finalize-inside-color);
	-webkit-transform: translateX(19px);
	-ms-transform: translateX(19px);
	transform: translateX(19px);
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

/* Rounded sliders */
.slider.round {
	border-radius: 19px;
}

.slider.round:before {
	border-radius: 50%;
}

.iconColor {
	color: var(--finalize-inside-color);
}
