.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 817px;
}

.title {
	color: var(--main-background-text-color);
	font-size: 40px;
	font-weight: 600;
	margin-bottom: 10px;
}

.subTitle {
	color: var(--main-background-text-color);
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 10px;
	text-decoration: underline;
}

.box {
	background-color: var(--section-background-color);
	border-radius: 8px;
	padding: 15px 20px 20px 20px;
	height: 100%;
}

.headerRow {
	display: flex;
	justify-content: space-between;
}

.address {
	color: var(--section-background-text-color);
	font-size: 16px;
}

.address .marking {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 6px;
}

.address .noMarking {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 6px;
	color: var(--invalid-color);
	text-decoration: underline;
}

.mapContainer {
	margin-top: 10px;
	height: 200px;
	border-radius: 12px;
}

.contactPersonsHeader {
	color: var(--section-background-text-color);
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
}

.deleteProject {
	display: flex;
	gap: 10px;
	align-items: center;
	cursor: pointer;
	color: var(--section-background-text-color);
	margin-top: 40px;
}

.deleteProject.disabled {
	color: var(--disable-inside-color);
	cursor: not-allowed;
}

.deleteProject:not(.disabled):hover {
	color: var(--section-hover-color);
}

.deleteIcon svg {
	fill: var(--section-background-text-color);
	stroke: var(--section-background-text-color);
}

.deleteProject.disabled .deleteIcon svg {
	fill: var(--disable-inside-color);
	stroke: var(--disable-inside-color);
}

.deleteProject:not(.disabled):hover .deleteIcon svg {
	stroke: var(--section-hover-color);
	fill: var(--section-hover-color);
}

.deleteProject > span {
	font-weight: 600;
}

.editMarkingNotificationText {
	text-decoration: underline;
	color: var(--section-background-text-color);
	margin-top: 10px;
	font-weight: 600;
	font-size: 14px;
}

@media (max-width: 1100px) {
	.title {
		font-size: 24px;
	}

	.statusDropdown {
		font-size: 16px;
	}
}

@media (max-width: 710px) {
	.wrapper {
		max-width: unset;
	}
}
