.wrapper {
	background-color: var(--section-background-color);
	border-radius: 12px;
	padding: 15px 20px 20px 20px;
	height: fit-content;
}

.header {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
	margin-bottom: 3px;
}

.topInfoText {
	font-size: 16px;
	color: var(--section-background-text-color);
	margin-bottom: 15px;
}

.userRow {
	display: grid;
	grid-template-columns: 50px auto 32px;
	background-color: var(--module-box-color);
	padding: 12px;
	border-radius: 6px;
	min-height: 65px;
	margin-bottom: 10px;
}

.userRow:hover {
	border: 1px solid var(--hover-border-color);
}

.userRow.noClick:hover {
	border: none;
}

.userRowName {
	font-size: 14px;
	padding-right: 10px;
	margin-top: auto;
	margin-bottom: auto;
}

.noProfilePic {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
}

.profilePic {
	width: 40px;
	min-width: 40px;
	height: 40px;
	min-height: 40px;
	overflow: hidden;
	border-radius: 100px;
	background-color: rgb(165, 165, 165);
	cursor: pointer;
}

.profilePic img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.newContactPersonBtn {
	max-width: 200px;
	display: flex;
	justify-content: space-between;
	padding: 10px 15px;
	align-items: center;
	gap: 20px;
	width: unset;
}

.editIcon {
	stroke: var(--module-box-icon-color);
	cursor: pointer;
}

@media (max-width: 1100px) {
	.header {
		font-size: 20px;
	}

	.topInfoText {
		font-size: 14px;
	}
}
