.mobileBasketFooter {
	display: none;
}

@media (max-width: 1100px) {
	.mobileBasketFooter {
		display: unset;
		width: var(--100vw);
		height: 35px;
	}
}
