.baseButton {
	font-weight: 600;
	border-style: none;
	border-radius: 1000px; /*Capsule shape*/
	width: 100%;
	height: 2.625em;
	cursor: pointer;
}

.baseButton:hover {
	opacity: 80%;
}

.baseButton:disabled,
.baseButton[disabled],
.disabled {
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
	cursor: not-allowed;
}

.accentButton {
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
}

.accentButton:not([disabled]):not(.disabled):hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
	opacity: 100%;
}

.finalizeButton {
	background-color: var(--finalize-color);
	color: var(--finalize-inside-color);
}

.finalizeButton:not([disabled]):not(.disabled):hover {
	background-color: var(--finalize-hover-color);
	color: var(--finalize-hover-inside-color);
	opacity: 100%;
}

.inProgress {
	cursor: progress;
}

.denyButton {
	background-color: var(--status-denied-color);
	color: var(--status-denied-inside-color);
}

.acceptButton {
	background-color: var(--status-accepted-color);
	color: var(--status-accepted-inside-color);
}

.doneButton {
	background-color: var(--status-done-color);
	color: var(--status-done-inside-color);
}
