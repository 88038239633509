.wrapper {
	padding: 0 0 10px;
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	justify-content: space-between;
	container: fullPageWrapper / inline-size;
}

.productBox {
	min-width: 420px;
	height: fit-content;
	max-width: 700px;
	width: 100%;
	background-color: var(--product-section-color);
	padding: 0;
	flex: 1 1;
	margin-left: auto;
}

.productBox > div {
	padding-top: 0;
}

.productBox > div > span {
	position: relative;
	padding-bottom: 1.5rem;
}

.productBox > div > div {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	padding: 20px 0;
}

.productBox > div > span > div {
	padding: 20px;
}

.productBox span > hr {
	border-top: 2px solid var(--product-border-color);
	position: absolute;
	left: 0;
	margin: 0 !important;
	border-bottom: none;
}

.productBox,
.selectionBox {
	box-shadow: 0 1px 6px #00000029;
	border-radius: 12px;
}

.selectionBox {
	height: fit-content;
	min-width: 430px;
	max-width: 700px;
	background-color: var(--section-background-color);
	padding: 20px;
}

.selectionBox .title {
	font-size: 22px;
	font-weight: 600;
	color: var(--section-background-text-color);
	margin-left: 5px;
}

.selectionBox .subTitle {
	font-size: 14px;
	color: var(--section-background-text-color);
	margin-left: 5px;
}

.selectionBox > hr {
	width: 100%;
	height: 0;
	border-top: none;
	border-bottom: 1px solid var(--section-border-color);
	margin: 10px 0;
}

.flexContentColumn {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.productBox > div {
	height: auto;
}

.secondaryTitle {
	font-size: 18px;
	font-weight: 600;
	margin-left: 5px;
}

.secondarySubTitle {
	font-size: 14px;
	margin-left: 5px;
}

.goodsSelectionContent,
.wasteSelectionContent {
	max-height: 700px;
	overflow-y: auto;
}

.goodsSelectionContent > div {
	height: unset;
	min-height: unset;
}

.widgetSection {
	margin-top: 100px;
	width: 100%;
}

.widgetSection .widgetTitle {
	font-size: 40px;
	margin-bottom: 30px;
	color: var(--main-background-text-color);
}

.productNameAndDimensionsClass > div {
	color: var(--product-section-text-color) !important;
}

.backArrow {
	cursor: pointer;
	margin-bottom: 20px;
	margin-left: 10px;
}

.backArrow svg,
.backArrow path {
	stroke: transparent !important;
	fill: var(--main-background-text-color) !important;
}

.selectionBoxesColumn {
	gap: 30px;
	flex: 1 1 0;
	min-width: 420px;
	max-width: 700px;
}

.mobileFinalizeButtonWrapper {
	position: fixed;
	bottom: 20px;
	display: flex;
	justify-content: center;
	z-index: 1;
	left: 20px;
	right: 35px;
}

.mobileFinalizeButtonsContainer {
	width: 100%;
	position: relative;
	max-width: 700px;
	container: mobileFinalizeContainer / inline-size;
}

.mobileFinalizeButton {
	padding: 0 20px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 700px;
	display: flex;
	align-items: center;
	gap: 10px;
	background-color: var(--basket-finalize-color);
	color: var(--basket-finalize-inside-color);
}

.mobileFinalizeButton:focus-visible,
.mobileAddProductSelectionsButton:focus-visible {
	outline: none !important;
}

.mobileAddProductSelectionsButton {
	font-weight: 600;
	border-style: none;
	border-radius: 1000px; /*Capsule shape*/
	height: 32px;
	cursor: pointer;
	padding: 0 20px;

	position: absolute;
	top: 5px;
	right: 6px;
	background-color: var(--basket-finalize-accent-color);
	color: var(--basket-finalize-accent-inside-color);
}

.mobileFinalizeButtonWrapper .mobileFinalizeButton:not(:disabled):hover {
	background-color: var(--basket-finalize-color);
	color: var(--basket-finalize-inside-color);
	opacity: 100%;
}

.mobileFinalizeIconWrapper {
	position: relative;
}

.mobileFinalizeIconWrapper .orderItemAmount {
	position: absolute;
	top: 8px;
	left: 8.5px;
	color: var(--basket-finalize-icon-color);
}

.mobileFinalizeIconWide .orderItemAmount {
	top: 11px;
	left: 6px;
}

.mobileFinalizeIconExtraWide .orderItemAmount {
	top: 13px;
	left: 3px;
}

.mobileFinalizeButton:disabled .mobileFinalizeIconWrapper .orderItemAmount {
	color: var(--disable-inside-color);
}

.mobileFinalizeButton svg {
	stroke: var(--basket-finalize-icon-color);
	fill: var(--basket-finalize-icon-color);
}

.mobileFinalizeButton:disabled svg {
	stroke: var(--disable-inside-color);
	fill: var(--disable-inside-color);
}

.mobileFinalizeButton:disabled:hover {
	opacity: 100%;
}

.leftTextSecond,
.rightTextSecond {
	display: none;
}

.widgetSectionWrapper {
	width: 100%;
}

@container fullPageWrapper (max-width: 1185px) and (min-width: 800px) {
	.wrapper > div {
		margin: 0 auto;
	}
}

@container mobileFinalizeContainer (max-width: 400px) {
	.leftTextFirst {
		display: none;
	}

	.leftTextSecond {
		display: unset;
	}
}

@container mobileFinalizeContainer (max-width: 340px) {
	.rightTextFirst {
		display: none;
	}

	.rightTextSecond {
		display: unset;
	}
}

@container mobileFinalizeContainer (max-width: 265px) {
	.leftTextSecond {
		display: none;
	}
}

@media (min-width: 1640px) {
	.wrapper.regularBasketVisible,
	.widgetSectionWrapper.regularBasketVisible {
		/* This calculation is kinda mysterious at first glance... but basically it's just the width of the screen minus
		the offsets of the sidebar and some padding (145px), the basket (480px).

		The -30px is just to add some spacing against the content on the right

		By doing this the calculated value is exactly the portion of the screen's area that we want :)
	  	*/
		max-width: calc(var(--100vw) - 145px - 480px - 30px);
	}
}

@media (max-width: 1639px) and (min-width: 1101px) {
	.wrapper.regularBasketVisible,
	.widgetSectionWrapper.regularBasketVisible {
		width: calc(100% - 490px);
	}
}

@media (max-width: 1100px) {
	.widgetSection .widgetTitle {
		font-size: 28px;
	}

	.wrapper {
		flex-direction: column;
		align-items: center;
	}

	.goodsSelectionContent,
	.wasteSelectionContent {
		max-height: unset;
		overflow-y: unset;
	}

	.selectionBoxesColumn {
		width: 100%;
	}

	.wrapper.regularBasketVisible {
		padding-right: 10px;
	}
}

@media (max-width: 710px) {
	.wrapper,
	.wrapper.regularBasketVisible {
		align-items: unset;
		padding-right: 0;
		padding-left: 0;
	}

	.wrapper {
		margin-left: -8px;
		margin-right: -8px;
	}

	.productBox,
	.selectionBox,
	.selectionBoxesColumn {
		min-width: 0;
	}

	.productBox,
	.productBox > div > div {
		border-radius: 0;
	}

	.selectionBox {
		border-radius: 0;
	}

	.mobileFinalizeButtonWrapper {
		left: 20px;
		right: 20px;
	}
}
