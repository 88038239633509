.sectionsWrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	flex-wrap: wrap;
	gap: 20px;
}

.userDetailsWrapper {
	position: relative;
	background-color: var(--section-background-color);
	max-width: 530px;
	padding: 15px 20px 20px 20px;
	border-radius: 12px;
	width: 100%;
	min-height: 250px;
	min-width: 400px;
	flex: 1;
}

.editIcon {
	stroke: var(--section-icon-color);
	cursor: pointer;
}

.changePasswordBtn {
	max-width: 200px;
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
}

.changePasswordBtnIcon {
	stroke: var(--accent-inside-color);
	cursor: pointer;
}

.changePasswordBtn:hover .changePasswordBtnIcon {
	stroke: var(--accent-hover-inside-color);
}

@media (max-width: 760px) {
	.sectionsWrapper .userNotificationPreferences {
		max-width: 530px;
	}
}

@media (max-width: 400px) {
	.sectionsWrapper .userNotificationPreferences,
	.userDetailsWrapper {
		min-width: 200px;
	}
}
