.iconWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon {
	width: 100%;
	max-height: 100%;
	fill: var(--icon-color-1);
}
