.clientDetailsWrapper {
	display: flex;
	gap: 22px;
	margin-bottom: 20px;
	justify-content: center;
}

.clientDetailsWrapper .box {
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 520px;
	min-width: 420px;
	width: 100%;
}

@media (max-width: 915px) {
	.clientDetailsWrapper {
		flex-direction: column;
	}

	.clientDetailsWrapper .box,
	.clientDetailsWrapper .box > div {
		max-width: unset;
	}

	.clientDetailsWrapper .box {
		min-width: unset;
	}
}
