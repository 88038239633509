.feedbackIndicator {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/*column-gap: 16px;*/
	background-color: #381533;
	border-radius: 1000px;
	padding: 6px 6px 6px 20px;
	color: white;
	font-weight: 600;
	position: absolute;
}

.feedbackIndicator div div:first-child {
	color: #fce738;
}

.iconBox {
	display: flex;
	position: relative;
	margin: 4px;
	margin-left: 12px;
	align-items: center;
	justify-content: center;
	width: 46px;
	aspect-ratio: 1/1;
}

.icon {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	animation: rotate 3s linear infinite;
}

.icon svg {
	transform: translate(2.5px, -2.4px);
	fill: #7ecfb5;
}

.counter {
	font-weight: bold;

	/*display: flex;*/
	/*justify-content: center;*/
	/*align-items: center;*/
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
