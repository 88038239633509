.wrapper {
	container: switchWrapper / inline-size;
}

.switch {
	position: relative;
	height: 50px;
	display: flex;
	justify-content: space-around;
	background-color: var(--toggle-background-color);
	padding: 5px;
	border-radius: 50px;
}

.switch input {
	display: none;
}

.switch label {
	padding: 1rem;
	z-index: 1;
	cursor: pointer;
	font-weight: 600;
	display: flex;
	align-items: center;
	width: fit-content;
	min-width: 100px;
	justify-content: center;
	text-overflow: ellipsis;
	overflow: hidden;
}

.switch label[data-selected="true"] {
	color: var(--toggle-selected-inside-color);
}

.switch label[data-selected="false"] {
	color: var(--toggle-background-inside-color);
}

.switch::before {
	content: "";
	position: absolute;
	height: calc(100% - 10px);
	background: var(--toggle-selected-color);
	border: none;
	border-radius: 50px;
	transition: 0.5s left ease;
	cursor: pointer;
}

.switch.disabled {
	background-color: var(--disable-color);
}

.switch.disabled label[data-selected="true"] {
	cursor: not-allowed;
	color: var(--disable-color);
}

.switch.disabled label[data-selected="false"] {
	cursor: not-allowed;
	color: var(--disable-inside-color);
}

.switch.disabled::before {
	background: var(--disable-inside-color);
}

@container switchWrapper (max-width: 400px) {
	.switch {
		flex-direction: column;
		height: unset;
		border-radius: 6px;
	}

	.switch::before {
		transition: 0.5s top ease;
		height: 50px;
		border-radius: 6px;
		width: calc(100% - 10px) !important;
	}

	.switch label {
		width: 100% !important;
		max-width: unset !important;
		justify-content: flex-start;
	}
}
