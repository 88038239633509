.simpleAddressWrapper {
	width: 100%;
	background-color: var(--section-background-color);
	border-radius: 6px;
	padding: 20px;
	display: grid;
	grid-template-columns: 70fr 30fr;
	justify-content: space-between;
	align-items: center;
	max-width: 1010px;
}

.simpleAddressWrapper .header {
	font-size: 28px;
	font-weight: 600;
}

.simpleAddressWrapper .subHeader {
	font-size: 16px;
}

.simpleAddressSelector {
	width: 100%;
	border-radius: 36px;
	height: 45px;
	min-width: 150px;
	max-width: 280px;
	justify-self: flex-end;
}

.simpleAddressSelectedProjectSection {
	width: 100%;
	max-width: 250px;
	margin-right: auto;
}

.simpleAddressSelector .collapsed {
	display: none;
}

.simpleAddressText {
	word-break: break-word;
	overflow: visible;
	white-space: normal;
}

@media (max-width: 1100px) {
	.simpleAddressWrapper .header {
		font-size: 20px;
	}

	.simpleAddressWrapper .subHeader {
		font-size: 16px;
	}
}

@media (max-width: 500px) {
	.simpleAddressSelector {
		min-width: 80px;
	}

	.simpleAddressSelector .collapsed {
		display: block;
	}

	.simpleAddressSelector .normal {
		display: none;
	}
}

@media (max-width: 380px) {
	.simpleAddressWrapper {
		grid-template-columns: 100%;
		row-gap: 10px;
	}

	.simpleAddressSelector {
		max-width: unset;
	}
}
