.wrapper {
	margin-bottom: 20px;
}

.header {
	color: var(--main-background-text-color);
	font-size: 40px;
	font-weight: 600;
	margin-bottom: 10px;
}

.selectedProjectViewWrapper {
	display: grid;
	grid-template-columns: calc(50% - 10px) 20px calc(50% - 10px);
	justify-content: center;
}

.noSelectedProjectViewWrapper {
	display: flex;
	gap: 10px;
	height: 100%;
}

.backArrowWrapper {
	display: flex;
	width: 100%;
	margin-bottom: 20px;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	font-weight: 600;
	cursor: pointer;
	max-width: 230px;
	border-radius: 12px;
	height: 50px;
	padding-left: 8px;
}

.backArrowWrapper:hover {
	background-color: var(--section-hover-color);
}

.backArrow {
	cursor: pointer;
}

.backArrow path {
	fill: var(--main-background-text-color);
}

@media (max-width: 1100px) {
	.header {
		font-size: 24px;
	}
}

@media (max-width: 870px) {
	.selectedProjectViewWrapper {
		grid-template-columns: 100%;
		grid-row-gap: 20px;
	}
}

@media (max-width: 800px) {
	.noSelectedProjectViewWrapper {
		flex-direction: column;
	}

	.noSelectedProjectViewWrapper > div {
		width: 100%;
		max-width: unset;
	}
}

@media (max-width: 710px) {
	.wrapper {
		height: unset;
	}
}
