.wrapper {
	background-color: var(--section-background-color);
	min-width: 200px;
	max-width: 600px;
	border-radius: 8px;
	width: 100%;
	padding: 15px 20px 20px 20px;
	height: 100%;
}

.wrapperHeaderText {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
	margin-bottom: 22px;
}

.wrapperHeader {
	display: flex;
	gap: 15px;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--chosen-border-color);
}

.searchInputWrapper {
	display: flex;
	flex-wrap: nowrap;
	height: 45px;
	flex-grow: 1;
}

.searchIcon {
	border-top-left-radius: 22px;
	border-bottom-left-radius: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--input-field-inside-color);
	background-color: var(--input-field-color-mback);
	padding-left: 1rem;
	cursor: pointer;
}

.searchInput {
	margin-bottom: 1rem;
	width: 100%;
	padding-left: 20px;
	height: 45px;
	-webkit-appearance: none;
	border: none;
	color: var(--input-field-inside-color);
	background-color: var(--input-field-color-mback);
	border-radius: 0;
}

.searchInput:focus {
	border: none;
	outline: none;
}

.searchInput:disabled,
.disabled .searchIcon,
.disabled .clearSearchButton,
.disabled .clearSearchButton > *,
.disabled .filterButton,
.disabled .filterButton:hover {
	background-color: var(--disable-color) !important;
	color: var(--disable-inside-color) !important;
	cursor: progress !important;
}

.disabled .filterButton svg {
	fill: var(--disable-inside-color);
}

.clearSearchButton {
	border: 0;
	background-color: var(--input-field-color-mback);
	border-top-right-radius: 22px;
	border-bottom-right-radius: 22px;
	padding-right: 1rem;
}

.consumersList {
	overflow-y: auto;
	max-height: calc(100% - 142px);
}

.consumersListItemsWrapper {
	grid-template-rows: 72px;
	row-gap: 20px;
	margin: 0;
}

.consumersListItemsWrapper > label {
	height: 72px;
}

.consumerListItemWrapper {
	cursor: pointer;
	height: 100%;
}

.consumerListItem {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	padding-right: 1rem;
	cursor: pointer;
	gap: 10px;
}

.consumerListItemDetails {
	display: flex;
	flex-flow: column;
	width: 100%;
	color: var(--module-box-text-color);
}

.consumerListItemDetails > strong {
	font-size: 20px;
}

.consumerListItemPic {
	width: 38px;
	min-width: 38px;
	height: 38px;
	min-height: 38px;
	overflow: hidden;
	border-radius: 100px;
	cursor: pointer;
	background-color: rgb(165, 165, 165);
	position: relative;
}

.consumerListItemPic > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.closeXIcon {
	cursor: pointer;
	background-color: var(--input-field-color-mback);
}

.closeXIconInside {
	stroke: var(--module-box-icon-color);
	fill: none;
}

.noProfilePic {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
}

.hasSelectedItem .consumerListItemWrapper {
	grid-template-columns: auto;
}

.hasSelectedItem .consumerListItemWrapper > button {
	display: none;
}

.hasSelectedItem.wrapper {
	max-width: 385px;
}

.oneLineClamp {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
	word-break: break-all;
}

.hasSelectedItem .newConsumerButton {
	width: 42px;
}

.hasSelectedItem .newConsumerButton > div > span {
	display: none;
}

.clickableRow > td {
	padding-top: 15px;
	padding-bottom: 15px;
}

.clickableRow:hover {
	background-color: var(--section-hover-color);
	cursor: pointer;
}

.customerType {
	display: flex;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	border-radius: 6px;
	padding: 6px 5px;
	width: 120px;
	font-weight: 600;
	justify-content: center;
}

.consumerColumn {
	width: 35%;
}

.textWithIconColumn {
	width: 11%;
}

.clickableColumn {
	cursor: pointer;
}

.tableIconAndTextWrapper {
	display: flex;
	gap: 5px;
	align-items: center;
}

.numberCircle {
	border-radius: 100px;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	width: auto;
	height: 28px;
	font-size: 14px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 28px;
	padding: 4px;
}

.emptyNumberCircle {
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
}

.tableIcon {
	stroke: var(--section-icon-color);
	color: var(--section-icon-color);
	font-size: 22px;
}

.emptyNumberCircleIcon {
	stroke: var(--disable-color);
	color: var(--disable-color);
}

.filterSection {
	display: flex;
	width: 100%;
	flex-direction: column;
	box-shadow: 0 2px 8px #00000021;
	border-radius: 12px;
	margin-bottom: 30px;
}

.filterSection .firstRow {
	background-color: var(--main-background-color);
	display: flex;
	gap: 15px;
	padding: 20px 25px;
	border-radius: 12px;
}

.filterSection .secondRow {
	background-color: var(--section-background-color);
}

.filterSection .secondRow > div {
	margin: 20px 25px;
}

.headerText {
	font-size: 40px;
	font-weight: 600;
	height: 100%;
	display: flex;
	align-items: flex-start;
}

.headerAndNewConsumerSection {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
}

.summarySectionWrapper {
	margin-bottom: 20px;
}

.summarySectionBoxHorizontalScroll {
	display: flex;
	gap: 15px;
	padding: 5px;
}

.summarySectionBox {
	background-color: var(--section-background-color);
	padding: 10px 10px 2px;
	border-radius: 12px;
	min-width: 240px;
	width: 240px;
	display: flex;
	flex-direction: column;
	box-shadow: 0 2px 8px #00000021;
}

.summarySectionClickableBox .summarySectionBoxTitleIcon {
	cursor: pointer;
	border-radius: 12px;
	padding: 2px;
}

.summarySectionClickableBox .summarySectionBoxTitleIcon:hover {
	box-shadow: 0 0 6px #00000029;
}

.summarySectionBoxTitleAndIconWrapper {
	display: flex;
	justify-content: space-between;
}

.summarySectionBoxTitleText {
	color: var(--section-background-text-color);
	font-size: 24px;
	font-weight: 600;
}

.summarySectionBoxTitleIcon {
	font-size: 22px;
	color: var(--section-icon-color);
	height: fit-content;
}

.summarySectionBoxTitleIcon svg {
	rotate: 135deg;
}

.summarySectionBoxContent {
	color: var(--section-background-text-color);
	font-size: 60px;
	display: flex;
	justify-content: flex-end;
	font-weight: 600;
	height: fit-content;
	line-height: 40px;
	margin-top: auto;
	margin-right: 10px;
	margin-bottom: 10px;
}

.summarySectionClickableBox .summarySectionBoxContent {
	color: var(--accent-color);
}

.summarySectionBoxOptions {
	display: flex;
	justify-content: space-between;
}

.summarySectionBoxOption {
	color: var(--section-background-text-color);
	cursor: pointer;
	font-size: 14px;
	padding: 0 4px 5px;
}

.summarySectionBoxOption:not(.summarySectionBoxOptionSelected):hover {
	border-bottom: 4px solid var(--accent-color);
}

.summarySectionBoxOptionSelected {
	font-weight: 600;
	border-bottom: 4px solid var(--accent-color);
}

@media (max-width: 1100px) {
	.headerText {
		font-size: 28px;
	}
}

@media (max-width: 950px) {
	.headerAndNewConsumerSection {
		flex-wrap: wrap;
		row-gap: 20px;
	}

	.summarySectionWrapper {
		width: 100%;
	}
}

@media (max-width: 840px) {
	.filterSection .firstRow {
		padding-left: 10px;
		padding-right: 10px;
	}

	.filterSection .secondRow > div {
		margin-left: 10px;
		margin-right: 10px;
	}

	.consumersTable thead {
		display: none;
	}

	.consumersTable tbody tr {
		border-radius: 6px;
		margin-bottom: 12px;
		display: grid;
		grid-template-areas:
			"consumer consumer consumer consumer consumer consumer"
			"consumerType consumerType consumerType orderAmount userAmount inviteAmount";
		grid-template-columns: repeat(6, 1fr);
		padding-bottom: 15px;
		grid-row-gap: 9px;
	}

	.consumersTable tbody tr td {
		border: none !important;
		padding-left: 10px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.consumersTable,
	.consumersTable tbody,
	.consumersTable tbody tr td {
		display: block;
	}

	.consumersTable tbody {
		padding-top: 4px;
	}

	.consumersTable .orderState {
		max-width: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		height: 30px;
		font-size: 14px;
	}

	.consumersTable tbody tr td.statusCell {
		grid-area: status;
		justify-self: flex-start;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.consumerCell {
		font-size: 16px;
		grid-area: consumer;
		margin-top: 10px;
		width: calc(100% - 20px);
	}

	.customerNumberCell,
	.consumerStateCell {
		display: none !important;
	}

	.consumerTypeCell {
		grid-area: consumerType;
	}

	.orderAmountCell {
		grid-area: orderAmount;
	}

	.userAmountCell {
		grid-area: userAmount;
	}

	.inviteAmountCell {
		grid-area: inviteAmount;
	}

	.clickableRow {
		box-shadow: 0 0 6px 1px #00000029;
	}

	.summarySectionBoxTitleText {
		font-size: 18px;
	}

	.summarySectionBoxTitleIcon {
		font-size: 20px;
	}

	.summarySectionBoxContent {
		font-size: 40px;
		line-height: unset;
	}
}

@media (max-width: 670px) {
	.consumerListItemWrapper {
		grid-template-columns: auto;
	}

	.consumerListItemWrapper > button {
		display: none;
	}
}

@media (max-width: 370px) {
	.consumersTable tbody tr {
		grid-template-areas:
			"consumer consumer consumer consumer consumer consumer"
			"consumerType consumerType consumerType paddingCol paddingCol paddingCol"
			"orderAmount userAmount inviteAmount paddingCol paddingCol paddingCol";
	}
}
