.modal {
	height: calc(var(--100vh) - 20px);
	overflow-y: auto;
	max-height: 435px;
}

.wrapper {
	position: relative;
	max-width: 400px;
}

.mainPageTitle {
	font-size: 28px;
	margin-bottom: 40px;
	font-weight: 600;
	color: var(--section-background-text-color);
}

.fields .input:focus-visible {
	outline: var(--chosen-border-color) 0.7px solid;
}

.input {
	width: 100%;
	height: 2.625em;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
}

.inputWrapper {
	margin-bottom: 10px;
	display: flex;
}

.inputWrapper svg {
	color: var(--input-field-inside-color);
}

.inputWrapper > div {
	background-color: var(--input-field-color);
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	display: flex;
}

.inputWrapper > svg,
.inputWrapper > div > svg {
	width: 20px;
	align-self: center;
	margin-left: 10px;
	margin-right: 15px;
}

.form > button {
	margin-top: 45px;
}

.passwordChangedText {
	margin-top: 10px;
	color: var(--valid-color);
	padding: 10px;
	background-color: var(--section-background-color);
}

@media (max-width: 710px) {
	.wrapper {
		max-width: unset;
		min-height: 342px;
	}

	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}

	.form {
		height: calc(100% - 75px);
		display: flex;
		flex-direction: column;
		min-height: 270px;
	}

	.form > button {
		margin-top: auto;
	}
}
