:root {
	--text-color-1: #000; /* Removed */
	--text-color-2: #575756; /* Removed */
	--text-color-3: #8b8b8b; /* Removed */
	--text-color-4: #464645; /* Removed */
	--icon-color-1: #000; /* Removed */
	--icon-color-2: #ffb200; /* Removed */
	--icon-color-3: #fff; /* Removed */
	--validation-error: var(--text-color-2); /* Removed */
}
