.footerRow {
	background-color: var(--footer-color);
}

.footerInnerWrapper {
	height: 100%;
	margin: 0 auto;
	padding: 0 12px 0 60px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: min(100%, 1640px);
}

.footerInnerWrapper *:only-child {
	margin: auto;
}

.poweredBy {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: max-content;
}

.logo {
	fill: var(--footer-logo-color);
	height: 50%;
}

.links {
	display: flex;
	flex-direction: row;
	gap: 24px;
	margin-left: 24px;
	align-items: center;
	overflow: hidden;
}

.footerLink {
	color: var(--footer-logo-color);
	font-size: 16px;
	font-weight: 500;
	text-decoration-line: none;
	text-wrap: nowrap;
	display: block;
}

.overflowedWrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.overflowedWrapper span {
	color: var(--footer-logo-color);
}

.overflowedFolder {
	box-sizing: border-box !important;
	padding: 4px;
	width: 20px;
	height: 20px;
	margin-left: 10px;
	background-color: var(--footer-logo-color);
	color: var(--footer-color);
	border-radius: 1000px;
	cursor: pointer;
}

.overflowedFolder.overlayOpen {
	rotate: 180deg;
}

.overflowLinksOverlay {
	position: absolute;
	bottom: 24px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	right: 0;
	background-color: var(--footer-color);
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 12px;
}

@media (max-width: 1100px) {
	.logo {
		height: 30%;
	}

	.footerInnerWrapper {
		padding: 0 32px 0 20px;
	}
}
