.orderDetails {
	overflow-y: auto;
}

.orderDetails.valueSelected {
	max-height: calc(100% - 165px);
}

.fullHeight {
	height: 100%;
}

.commentInput {
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	height: 75px;
	width: 100%;
	margin-top: 20px;
	resize: none;
	border: 0.5px solid var(--chosen-border-color);
	border-radius: 12px;
	margin-bottom: 20px;
	padding: 10px;
}

.selectedOutcome {
	border: 1px solid black;
}

.confirmWrapper {
	box-shadow: 0 0 5px 1px var(--header-shadow-color);
	background-color: var(--module-box-color);
	padding: 20px;
	border-radius: 0.75em;
	position: relative;
}

.iconColor {
	color: black;
}

.iconColorAccept {
	color: var(--status-accepted-inside-color);
}

.iconColorDeny svg {
	stroke: var(--status-denied-inside-color);
}

.closeXIconInside {
	stroke: black;
}

.outcome {
	padding-left: 20px;
	display: grid;
	grid-template-columns: 15% 70% 15%;
	align-items: center;
	height: 48px;
}

.outcomesWrapper {
	display: flex;
	gap: 15px;
}

.contentWrapper {
	height: 100%;
}

.contentWrapper {
	display: flex;
	flex-direction: column;
}

.modal {
	max-height: calc(var(--100vh) * 0.85);
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
}

@media (min-width: 711px) {
	.modal {
		height: var(--100vh);
	}
}

@media (max-width: 710px) {
	.modal {
		max-height: unset;
		padding-top: 0;
		padding-bottom: 0;
	}

	.confirmWrapper {
		position: relative;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

@media (max-width: 350px) {
	.outcome {
		grid-template-columns: auto;
		padding-left: 0;
	}
	.outcome > svg,
	.outcome > div {
		display: none;
	}
}
