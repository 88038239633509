@font-face {
	font-family: "hk-grotesk";
	src: url("./hk-grotesk.regular.otf");
	font-style: normal;
}

@font-face {
	font-family: "hk-grotesk";
	src: url("./hk-grotesk.medium.otf");
	font-weight: 500 599;
	font-style: normal;
}

@font-face {
	font-family: "hk-grotesk";
	src: url("./hk-grotesk.semibold.otf");
	font-weight: 600 699;
	font-style: normal;
}

@font-face {
	font-family: "hk-grotesk";
	src: url("./hk-grotesk.bold.otf");
	font-weight: 700 1000;
	font-style: normal;
}
