.wrapper {
	position: fixed;
	z-index: 999;
	right: 0;
	top: calc(calc(var(--100vh)) * 0.1);
	height: 40px;
	width: 40px;
	border: 1px solid rgb(83, 83, 83);
	box-shadow: 0 3px 6px #00000029;
	background-color: white;
	padding: 2px;
	transition: width 0.4s, height 0.4s;
}

.show {
	height: calc(calc(var(--100vh)) * 0.8);
	width: 300px;
	overflow: hidden;
}

.toggler {
	position: absolute;
	z-index: 1000;
	right: 0;
	top: 0;
	border: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.show .toggler {
	width: 40px;
	height: 40px;
	transform: rotate(90deg);
}

.contents {
	visibility: hidden;
	overflow: hidden;
}

.show .contents {
	visibility: visible;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 10px 0;
	height: calc(100% - 50px);
}

.contents h2 {
	margin-top: 0;
	margin-bottom: 8px;
}

.colorRow {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.colorRow input {
	margin-left: auto;
}

.wrapper button {
	visibility: hidden;
}

.show.wrapper button {
	visibility: visible;
	position: absolute;
	padding: 5px;
	width: 100%;
	bottom: 0;
}

.groups {
	display: flex;
	gap: 8px;
}

.groups > div {
	background: gray;
	color: white;
	border-radius: 3px;
	padding: 3px;
	font-weight: 600;
	flex-grow: 1;
	text-align: center;
}
.groups > div[aria-selected="true"] {
	background: #5e5e5e;
}

.sections {
	overflow-y: scroll;
}

.foldableSection {
	border: 1px solid black;
	border-radius: 4px;
	margin: 4px 2px;
	cursor: pointer;
}

.foldableTitle {
	padding: 4px;
	display: flex;
	justify-content: space-between;
}

.foldableTitle > span {
	transition: all 0.2s linear;
	transform: rotate(90deg);
	font-weight: 600;
}

.foldableSection[aria-expanded="false"] > .foldableTitle > span {
	transform: rotate(270deg);
}

.foldableContent {
	transition: all 0.2s linear;
	border-top: 1px solid black;
	padding: 6px;
	overflow: hidden;
}

.foldableSection[aria-expanded="false"] > .foldableContent {
	border-top: 0 solid black;
	max-height: 0;
	padding: 0;
}
