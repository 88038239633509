.absoluteWrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.relativeWrapper {
	position: relative;
	height: 100%;
	width: 100%;
}
