.icon {
	margin: 0 16px 0 4px;
	width: 28px;
	height: 28px;
	fill: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
	/* Some icons dont use stroke color but gets a default width of 1, and if we apply colors via css to the stroke
	those old icons gains some extra width, this is a hack until we can update all our icons. */
	stroke-width: 0;
}

@media desktop {
	.icon {
		margin: 0 28px 0 8px;
	}
}
