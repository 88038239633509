.wrapper {
	display: block;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.originalFileName {
	margin-top: 5px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
	word-break: break-all;
	font-size: 10px;
	font-weight: 600;
	max-width: 115px;
}

.orderFilesSection {
	margin-bottom: 25px;
}

.orderFilesSectionHeader {
	font-size: 18px;
	font-weight: 600;
}

.filesTabUploadButton {
	display: flex;
	width: auto;
	align-items: center;
	padding: 0 20px;
	margin-left: auto;
	cursor: pointer;
}

.filesTabUploadButton label {
	color: var(--finalize-inside-color);
	display: flex;
	gap: 10px;
	align-items: center;
	cursor: pointer;
}

.uploadError {
	display: flex;
	color: var(--invalid-color);
	justify-content: flex-end;
	font-weight: 600;
	margin-top: 5px;
}

.genericFile {
	max-width: 120px;
	width: 120px;
	height: 120px;
	max-height: 120px;
	padding: 2px;
	border-radius: 16px;
	box-shadow: 0 1px 6px #00000029;
	cursor: pointer;
	min-width: 120px;
	min-height: 120px;
}

.genericFile:hover {
	border: 1px solid var(--chosen-border-color);
}

div.orderImagesWrapper img {
	max-width: 115px;
	width: 115px;
	height: 115px;
	max-height: 115px;
	padding: 2px;
	border-radius: 16px;
	box-shadow: 0 1px 6px #00000029;
	cursor: pointer;
}

.orderImagesWrapper div.imageWrapper {
	max-width: 115px;
	max-height: 115px;
	width: 115px;
	height: 115px;
	position: relative;
}
