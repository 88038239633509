.modal {
	height: 700px;
	max-height: calc(var(--100vh) * 0.85);
}

.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
}

.fields {
	height: auto;
	overflow-y: scroll;
	margin-bottom: 10px;
	grid-auto-rows: minmax(min-content, max-content) !important;
	grid-template-rows: minmax(min-content, max-content);
}

.fields > label {
	min-height: 90px !important;
}

.finalize {
	margin-top: auto;
	min-height: 42px;
}

.item {
	cursor: pointer;
}

.itemContent {
	grid-template-columns: 75px auto;
	display: grid;
}

.consumerProfilePicture {
	width: 64px;
	min-width: 64px;
	height: 64px;
	min-height: 64px;
}

.profilePicture {
	width: 56px;
	height: 56px;
	background-color: #6f6f70;
	border-radius: 100px;
	display: block;
	overflow: hidden;
	position: relative;
}

.profilePicture > img {
	width: 56px;
	height: 56px;
	border-radius: 100px;
	object-fit: cover;
}

.btnTempUserSwitch {
	width: 100%;
	display: inline-block;
	margin: 0;
	position: relative;
}

.btnTempUserSwitch > label.btnTempUserSwitchInner {
	margin: 0;
	width: 265px;
	height: 42px;
	background: white;
	border-radius: 26px;
	overflow: hidden;
	position: relative;
	transition: all 0.3s ease;
	display: block;
	cursor: pointer;
}

.btnTempUserSwitch > label.btnTempUserSwitchInner:before {
	content: attr(data-on);
	position: absolute;
	font-size: 16px;
	font-weight: 500;
	top: 13px;
	right: 10px;
}

.btnTempUserSwitch > label.btnTempUserSwitchInner:after {
	content: attr(data-off);
	width: 105px;
	height: 38px;
	background: black;
	color: white;
	border-radius: 26px;
	position: absolute;
	left: 2px;
	top: 2px;
	text-align: center;
	transition: all 0.3s ease;
	box-shadow: 0 0 6px -2px #111;
	align-items: center;
	display: flex;
	justify-content: space-evenly;
}

.btnTempUserSwitch input[type="checkbox"] {
	width: 1px;
	height: 1px;
	opacity: 0;
	position: absolute;
	top: 0;
	z-index: 1;
	margin: 0;
}

.btnTempUserSwitch input[type="checkbox"]:checked + label.btnTempUserSwitchInner {
	background: white;
	color: black;
}

.btnTempUserSwitch input[type="checkbox"]:checked + label.btnTempUserSwitchInner:after {
	content: attr(data-on);
	left: 105px;
	width: 160px;
	background: #3c3c3c;
}

.btnTempUserSwitch input[type="checkbox"]:checked + label.btnTempUserSwitchInner:before {
	content: attr(data-off);
	right: auto;
	left: 25px;
	top: 13px;
}

.btnTempUserSwitch input[type="checkbox"]:checked ~ .alert {
	display: block;
}

.input {
	width: 100%;
	height: 2.625em;
	border-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	margin-bottom: 10px;
}

.tempUserForm {
	margin-top: 20px;
	height: auto;
	overflow-y: scroll;
}

@media (max-width: 710px) {
	.item {
		grid-template-columns: auto;
	}

	.item > button {
		display: none;
	}

	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}

	.fields {
		max-height: unset;
	}
}
