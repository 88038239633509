.main {
	height: calc(var(--100vh));
	width: 100%;
	background-color: var(--main-background-color);
}

:global(.modal-open):global(#mainLayoutPageContent) {
	overflow: hidden;
}

.headerRow {
	width: 100%;
	height: 100px;
	border-bottom: 3px solid var(--header-divider-color);
	box-shadow: 0 0 5px 1px var(--header-shadow-color);
	background-color: var(--header-color);
	z-index: 998;
	position: relative;
}

.headerRowInnerWrapper {
	display: grid;
	grid-template-columns: minmax(auto, calc(calc(var(--100vw) - 1376px) / 2)) auto;
	grid-template-rows: 100px;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
}

.headerRowInnerRightPart {
	display: flex;
	justify-content: space-between;
}

.headerCell {
	height: 100%;
}

.headerSidebarCell {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-width: 132px;
	padding-left: 15px;
}

.headerSidebarCell > div {
	width: 50px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: var(--header-notification-color);
	border-radius: 12px;
}

.headerSidebarCell > div:hover {
	box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
}

.headerUserDetailsCell {
	max-width: 350px;
	padding-right: 30px;
	position: relative;
	display: flex;
}

.headerSidebarIcon {
	cursor: pointer;
	height: 18px;
	width: 30px;
	stroke: var(--header-notification-inside-color);
	color: var(--header-notification-inside-color);
}

.headerLogoCell {
	display: grid;
	align-items: center;
	grid-template-columns: auto;
	grid-template-rows: 100px;
	max-width: 1400px;
}

.headerLogoCell > img {
	object-fit: contain;
	max-height: 80%;
}

.headerLogoCell img {
	cursor: pointer;
}

.headerBasketButtonCell {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
}

.headerBasketButtonCell .headerBasketButton {
	width: 200px;
	border-radius: 60px;
	background-color: var(--header-notification-color);
	color: var(--header-notification-inside-color);
	height: 45px;
	min-height: 45px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 25px;
	cursor: pointer;
}

.headerBasketButton:hover {
	box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
}

.headerBasketButton > span {
	font-size: 20px;
	font-weight: 600;
}

.headerLoginAndCreateCustomerWrapper {
	display: flex;
	flex-wrap: wrap;
	flex: 1 1;
	justify-content: flex-end;
}

.headerLoginButtonCell {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 10px;
	min-width: 45px;
}

.headerLoginButtonCell + .headerLoginButtonCell {
	margin-left: 0;
}

.basketIcon {
	fill: var(--header-notification-inside-color);
	stroke: var(--header-notification-inside-color);
}

.pageContent {
	width: 100%;
	height: calc(100% - 100px);
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	padding-left: 140px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
	padding-right: 10px;
}

.noSidebarOrderLayout {
	padding-left: 20px;
}

.pageContent > span {
	padding-left: calc(calc(var(--100vw) - 1640px) / 2); /* Aligns left edge of content to left edge of logo in header */
	padding-right: calc(calc(var(--100vw) - 1640px) / 2); /* Aligns left edge of content to left edge of logo in header */
}

.pagesPageContent {
	width: 100%;
	height: calc(100% - 100px);
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	padding-left: 105px;
	display: flex;
	flex-direction: column;
}

.noSidebarPagesLayout {
	padding-left: 0;
}

.pagesPageContent footer {
	margin-left: 0 !important;
	margin-right: 0 !important;
	margin-top: auto;
}

.mobileOnly {
	display: none;
}

.footer {
	width: var(--100vw);
	position: fixed;
	bottom: 0;
	height: 50px;
	min-height: 50px;
	z-index: 200;
	left: 0;
	margin-top: auto;
}

.footerDesktopHiddenLogo svg {
	opacity: 0;
	overflow: hidden;
	width: 80px;
}

.profilePic {
	width: 45px;
	min-width: 45px;
	height: 45px;
	min-height: 45px;
	overflow: hidden;
	border-radius: 100px;
	cursor: pointer;
	background-color: rgb(165, 165, 165);
	position: relative;
}

.profilePic img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.noProfilePic {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
	font-size: 20px;
}

.userSection {
	display: flex;
	gap: 10px;
	cursor: pointer;
	height: 75%;
	padding: 0 15px;
	align-self: center;
}

.userSection:hover {
	background-color: var(--header-hover-color);
	border-radius: 12px;
}

.userDetails {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: var(--header-text-color);
}

.userDropdown {
	z-index: 997;
	position: absolute;
	top: 100px;
	right: 40px;
	background-color: var(--header-dropdown-main);
	box-shadow: 0 3px 8px 2px var(--header-shadow-color);
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	cursor: pointer;
	max-width: 270px;
	width: var(--100vw);
}

.userDropdown.userDropdownBasketElementShown {
	right: 240px;
}

.userDropdown hr {
	margin: 0;
}

.userDropdownItem {
	background-color: var(--header-dropdown-main);
	margin: 10px 14px 10px 14px;
	padding: 6px 14px 6px 14px;
	display: flex;
	gap: 10px;
	align-items: center;
	color: var(--header-dropdown-main-text);
	min-height: 40px;
	text-decoration: none;
	border-radius: 12px;
}

.userDropdownItemActive {
	background-color: var(--header-dropdown-chosen);
	color: var(--header-dropdown-chosen-inside);
}

.userDropdownItem:hover {
	background-color: var(--header-dropdown-hover);
	color: var(--header-dropdown-main-text);
}

.userDropdownItem svg {
	color: var(--header-dropdown-main-text);
	fill: var(--header-dropdown-main-text);
	stroke: var(--header-dropdown-main-text);
}

.userDropdownItemActive svg {
	color: var(--header-dropdown-chosen-inside);
	fill: var(--header-dropdown-chosen-inside);
	stroke: var(--header-dropdown-chosen-inside);
}

.userDropdownItemActive:hover svg {
	color: var(--header-dropdown-main-text);
	fill: var(--header-dropdown-main-text);
	stroke: var(--header-dropdown-main-text);
}

.loginButton {
	width: 170px;
	background-color: var(--header-color);
	border-radius: 100px;
	border: 3px solid var(--header-notification-color);
	padding: 0 22px;
	height: 45px;
	display: flex;
	align-items: center;
	font-size: 20px;
	justify-content: space-between;
	cursor: pointer;
	color: var(--header-notification-color);
}

.loginButton:hover {
	box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
}

.pageContent.dynamicNavbarOffset,
.pagesPageContent.dynamicNavbarOffset {
	padding-top: 60px;
}

.dynamicNavbar {
	margin-left: 105px;
	padding-left: 35px;
	padding-right: 10px;
	background-color: var(--under-header-color);
	height: 70px;
	position: fixed;
	z-index: 2;
	display: flex;
	align-items: center;
	width: calc(100% - 105px); /* 105 px => sidebar width when collapsed */
	box-shadow: 0 0 5px 1px var(--header-shadow-color);
	transition: opacity 300ms;
}

.dynamicNavbar.noSidebar {
	margin-left: 0;
	width: calc(100%);
}

.dynamicNavbar.regularBasketVisible {
	width: calc(
		100% - 480px - 105px - 16px
	); /* 480px => regular basket, when shown, 105px => sidebar width when collapsed */
}

.dynamicNavbar.regularBasketVisible.noSidebar {
	width: calc(100% - 480px); /* 480px => regular basket, when shown */
}

.dynamicNavbar.hiddenNavbar {
	pointer-events: none;
	opacity: 0;
}

.dynamicNavbarWrapper {
	width: 100%;
	display: grid;
	grid-template-columns: minmax(auto, calc(calc(var(--100vw) - 1640px) / 2)) calc(
			100% - calc(calc(var(--100vw) - 1640px) / 2)
		);
}

@media (min-width: 2130px) {
	.pageContent > span {
		width: 1970px;
		min-width: 1970px;
	}
}

@media (max-width: 1640px) {
	.dynamicNavbarWrapper {
		grid-template-columns: 0 100%;
	}
}

.dynamicNavbarHorizontalScroll {
	width: 100%;
}

.dynamicNavbarHorizontalScroll > div:nth-of-type(3) {
	right: 10px;
}

.dynamicNavbarHorizontalScrollCell {
	display: flex;
	align-items: center;
	gap: 5px;
	overflow-x: auto;
}

.dynamicNavbarButton {
	border-radius: 100px;
	cursor: pointer;
	height: 32px;
	font-size: 18px;
	background-color: transparent;
	color: var(--under-header-inside-color);
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 20px;
}

.dynamicNavbarHorizontalScrollCell > .dynamicNavbarButton,
.dynamicNavbarHorizontalScrollCell > .dynamicNavbarDropdownButtonWrapper {
	flex-shrink: 0;
}

.dynamicNavbarButton:hover {
	background-color: var(--under-header-hover-color);
	color: var(--under-header-hover-inside-color);
}

.dynamicNavbarButtonActive {
	background-color: var(--under-header-chosen-color);
	color: var(--under-header-chosen-inside-color);
}

.dynamicNavbarButtonActive:hover {
	background-color: var(--under-header-hover-color);
	color: var(--under-header-hover-inside-color);
}

.dynamicNavbarButtonImage {
	max-height: 22px;
	width: 100%;
	max-width: 22px;
	object-fit: contain;
}

.dynamicNavbarDropdown {
	position: absolute;
	top: 78px;
	margin-left: 10px;
	z-index: 1;
	background-color: var(--under-header-color);
	border-radius: 12px;
	white-space: nowrap;
}

.dynamicNavbarDropdownHeader {
	padding: 10px 15px 10px 15px;
	font-size: 18px;
	color: var(--under-header-inside-color);
	display: flex;
	align-items: center;
}

.dynamicNavbarDropdownHeader .dynamicNavbarButtonImage {
	margin-right: 10px;
}

.dynamicNavbarDropdown .dynamicNavbarButton {
	border-radius: 0;
	padding-top: 20px;
	padding-bottom: 20px;
	justify-content: flex-start;
}

.dynamicNavbarDropdown .dynamicNavbarButton:first-of-type {
	border-top: 1px solid var(--chosen-border-color);
}

.dynamicNavbarDropdown .dynamicNavbarButton {
	border-bottom: 1px solid var(--chosen-border-color);
}

.dynamicNavbarDropdown .dynamicNavbarButton:last-of-type {
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	border-bottom: none;
}

.minWidthWrapper {
	min-width: 1475px;
	display: block;
}

.notificationWrapper {
	display: flex;
	align-items: center;
	padding: 0 8px;
	position: relative;
	z-index: 1;
}

.notificationIcon {
	cursor: pointer;
	stroke: var(--header-button-inside-color);
	padding: 8px;
	border-radius: 100px;
	background-color: var(--header-button-color);
}

.notificationIcon svg g {
	fill: none;
}

.notificationIcon:hover svg g {
	stroke: var(--header-button-inside-hover-color);
	fill: var(--header-button-inside-hover-color);
}

.notificationCounter {
	background-color: var(--header-notification-color);
	color: var(--header-notification-inside-color);
	border: 1px solid var(--header-color);
	padding: 4px;
	border-radius: 100px;
	min-width: 24px;
	width: fit-content;
	max-width: 30px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 30%;
	right: -5px;
	font-size: 14px;
	cursor: pointer;
}

.notificationDropdown {
	position: absolute;
	top: 105px;
	right: 5px;
	background-color: var(--header-dropdown-main);
	width: 360px;
	height: fit-content;
	max-height: calc(var(--100vh) - 100px - 50px - 10px);
	border-radius: 6px;
	box-shadow: 0 0 6px 1px var(--header-shadow-color);
	padding: 20px 10px 0 10px;
	display: flex;
	flex-direction: column;
}

.notificationDropdownHeader {
	font-size: 28px;
	font-weight: 600;
	color: var(--header-dropdown-main-text);
	margin: 0 10px;
}

.notificationDropdown .filterButtons {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	margin: 10px 10px 25px 10px;
}

.notificationDropdown .filterButtons button {
	width: 80px;
	height: 30px;
	font-size: 14px;
	border: 1px solid var(--header-dropdown-accent-inside);
	background-color: transparent;
	color: var(--header-dropdown-accent-inside);
}

.notificationDropdown .filterButtons button:hover {
	background-color: var(--header-dropdown-accent-hover) !important;
	color: var(--header-dropdown-accent-hover-inside) !important;
}

.notificationDropdown .filterButtons button.selectedFilterButton {
	border: none;
	background-color: var(--header-dropdown-selected-color);
	color: var(--header-dropdown-selected-color-inside);
}

.notificationDropdown .filterButtons button.selectedFilterButton:hover {
	background-color: var(--header-dropdown-selected-hover-color) !important;
	color: var(--header-dropdown-selected-hover-color-inside) !important;
}

.notificationDropdown .notificationsList {
	display: flex;
	flex-direction: column;
	gap: 5px;
	overflow-y: auto;
	padding-bottom: 20px;
}

.notification {
	color: var(--header-dropdown-main-text);
	cursor: pointer;
	border-radius: 12px;
	padding: 10px;
}

.notification:hover {
	background-color: var(--header-dropdown-hover);
}

:global(.notification-clicked),
:global(.notification-clicked) .marking,
:global(.notification-clicked) .createdAt {
	background-color: var(--header-dropdown-chosen) !important;
	color: var(--header-dropdown-chosen-inside) !important;
}

.notification .notificationHeader {
	font-size: 18px;
	font-weight: 600;
	padding-right: 10px;
}

.notification .notificationMessageContent {
	display: flex;
	justify-content: space-between;
}

.notificationMessageContent .notificationMessageContentText span {
	hyphens: auto;
	word-break: unset;
}

.notification .notificationMessageContentUnreadMarker {
	margin-left: 10px;
	background-color: var(--header-dropdown-accent);
	border-radius: 100px;
	width: 12px;
	min-width: 12px;
	max-width: 12px;
	height: 12px;
	min-height: 12px;
	max-height: 12px;
}

.notification .notificationMarkingAndDate {
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
}

.notificationMarkingAndDate .marking {
	color: var(--header-dropdown-second-text);
	font-weight: 600;
	font-size: 14px;
}

.notificationMarkingAndDate .createdAt {
	color: var(--header-dropdown-accent);
	font-size: 14px;
}

.showAllNotificationsLink {
	color: var(--header-dropdown-accent);
	cursor: pointer;
	font-size: 14px;
}

.showAllNotificationsLink:hover {
	color: var(--header-dropdown-accent-hover);
}

.userDropdownToggle {
	color: var(--header-icon-color);
	top: 30%;
	position: relative;
}

@media (max-width: 1900px) {
	.minWidthWrapper {
		min-width: unset;
	}
}

@media (max-width: 1100px) {
	.headerBasketButtonCell {
		display: none;
	}

	.headerLoginAndCreateCustomerWrapper {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}

	.dynamicNavbar,
	.dynamicNavbar.regularBasketVisible,
	.dynamicNavbar.noSidebar,
	.dynamicNavbar.regularBasketVisible.noSidebar {
		width: 100%;
		margin-left: 0;
		padding-left: 20px;
	}

	.headerLogoCell > img {
		width: 100%;
	}

	.noProfilePic {
		font-size: 16px;
	}

	.headerSidebarCell {
		padding-left: 0;
		min-width: 60px;
	}

	.headerSidebarCell > div {
		background-color: transparent;
	}

	.headerSidebarCell > div:hover {
		box-shadow: none;
		background-color: var(--header-hover-color);
	}

	.headerSidebarIcon {
		stroke: var(--header-icon-color);
		color: var(--header-icon-color);
	}

	.footerDesktopHiddenLogo svg {
		opacity: 1;
		display: unset;
		overflow: unset;
		width: unset;
	}

	.loginButton {
		width: 36px;
		height: 36px;
		padding: 0;
		margin-left: auto;
		color: var(--header-notification-inside-color);
		background-color: var(--header-notification-color);
		justify-content: center;
	}

	.headerLoginButtonCell {
		padding-right: 0;
		margin-left: 0;
	}

	.userDropdown,
	.userDropdown.userDropdownBasketElementShown {
		top: 60px;
		right: 10px;
		width: calc(var(--100vw) - 20px);
	}

	.profilePic {
		width: 36px;
		min-width: 36px;
		height: 36px;
		min-height: 36px;
	}

	.mobileOnly {
		display: flex;
	}

	.headerRow {
		height: 63px;
	}

	.desktopOnly {
		display: none !important;
	}

	.headerRowInnerWrapper,
	.headerLogoCell {
		grid-template-rows: 60px;
	}

	.headerRowInnerWrapper,
	.pageContent {
		width: 100%;
	}

	.headerRowInnerWrapper {
		padding-left: 20px;
		padding-right: 10px;
		grid-template-columns: 60px auto;
	}

	.headerRowInnerWrapper .headerLogoCell img {
		margin: 0 auto;
	}

	.headerUserDetailsCell {
		padding-right: 0;
		margin-left: auto;
	}

	.pageContent {
		position: relative;
		width: 100%;
		height: calc(100% - 63px);
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 0;
	}

	.pagesPageContent {
		padding-left: 0;
		height: calc(100% - 63px);
	}

	.footer {
		margin-left: -20px;
		height: 35px;
		min-height: 35px;
		position: unset;
		bottom: unset;
		z-index: unset;
		left: unset;
	}

	.mobileShowBasketButtonFooterMargin {
		margin-bottom: 82px;
	}

	.userDetails {
		display: none;
	}

	.notificationDropdown {
		top: 68px;
		right: 0;
		width: calc(var(--100vw) - 25px);
		max-width: 360px;
		max-height: calc(var(--100vh) - 63px - 10px);
	}

	.userSection {
		position: relative;
		padding-left: 5px;
		margin-left: 5px;
		padding-right: 5px;
	}

	.notificationCounter {
		top: unset;
		bottom: 12%;
		min-width: 22px;
		font-size: 14px;
		height: 22px;
	}

	.notificationIcon {
		width: 36px !important;
		height: 36px !important;
		padding: 6px;
	}

	.userDropdownToggle {
		background-color: var(--header-button-color);
		color: var(--header-button-inside-color);
		border: 1px solid var(--header-color);
		border-radius: 100px;
		padding: 3px;
		width: 14px;
		height: 14px;
		position: absolute;
		left: 30px;
		top: 23px;
	}

	.userSection:hover .userDropdownToggle {
		color: var(--header-button-inside-hover-color);
	}
}

@media (max-width: 710px) {
	.dynamicNavbar,
	.dynamicNavbar.regularBasketVisible,
	.dynamicNavbar.noSidebar,
	.dynamicNavbar.regularBasketVisible.noSidebar {
		padding-left: 8px;
	}

	.pageContent {
		padding-left: 8px;
		padding-right: 8px;
	}

	.headerRow {
		z-index: 1002;
	}

	.userDropdown {
		z-index: 1001;
	}

	.headerRowInnerWrapper {
		padding-left: 8px;
		padding-right: 15px;
	}

	.footer {
		margin-left: -8px;
	}
}
