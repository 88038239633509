.wrapper {
	cursor: pointer;
}

.fill {
	background-size: cover;
	background-position: center;
}

.imageFit {
	overflow: auto;
}

.typedImage {
	aspect-ratio: 1/1;
	/*No colors here since the actual color to use depends on where the image is used*/
}
