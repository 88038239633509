.baseButton {
	font-weight: 600;
	border-style: none;
	border-radius: 1000px; /*Capsule shape*/
	width: 100%;
	height: 2.625em;
	cursor: pointer;
}

.baseButton:hover {
	opacity: 80%;
}

.chooserButton {
	height: 1.625em;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

input[type="time"] {
	text-align: center;
	padding-top: 2px;
}
