.productTable {
	text-align: left;
	align-content: center;
	border-collapse: collapse;
	width: 100%;
}

.listRow {
	border-bottom: 1px solid var(--product-base-color);
}

thead th {
	padding-bottom: 0.2rem;
	color: var(--text-color-1);
}

tbody td {
	width: auto;
	padding-top: 8px;
	padding-bottom: 8px;
	color: var(--text-color-1);
}

@media (max-width: 768px) {
	.listRow {
		border-style: none;
	}

	tbody td {
		padding-top: 4px;
		padding-bottom: 4px;
		width: auto;
	}

	tbody:last-child {
		padding-bottom: 4px;
	}
}
