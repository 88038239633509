.confirmButtonWrapper {
	border-radius: 15px;
}

.confirmButtonWrapper:hover {
	background-color: var(--accent-hover-color);
	transition: 1.5s;
	cursor: pointer;
}
.confirmButtonContent {
	display: inline-grid;
	grid-template-columns: 40px auto;
	height: 100%;
}
.confirmButtonContentItem {
	display: flex;
	justify-content: center;
	align-items: center;
}

.confirmButtonText {
	display: flex;
	justify-content: center;
	align-items: center;
}
