.errorWrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	gap: 10px;
}

.errorWrapper > * {
	width: 80%;
	margin: 0 auto;
}

.errorHeader {
	font-size: 30px;
	font-weight: 600;
}
