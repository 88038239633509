.wrapper {
	position: relative;
}

.cellContainer {
	overflow-x: scroll;
	scrollbar-width: none;
	padding-bottom: 1px; /*Handle quirk in Gnome Web*/
	width: 100%;
}

.cellContainer::-webkit-scrollbar {
	display: none;
}

.scrollButton {
	position: absolute;
	top: 50%;
	height: min(68%, 40px);
	aspect-ratio: 1/1;
	background-color: var(--accent-color);
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: var(--accent-inside-color);
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s ease-in-out;
}

.scrollButton.show {
	opacity: 1;
	visibility: visible;
	z-index: 1;
}

.scrollButton svg {
	height: 40%;
}

.scrollButtonPrevious {
	left: 0;
	transform: translateY(-50%) translateX(-60%);
}

.scrollButtonNext {
	right: 0;
	transform: translateY(-50%) translateX(60%);
}

.scrollButton:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

@media (max-width: 1100px) {
	.scrollButtonPrevious {
		transform: translateY(-50%) translateX(0);
	}

	.scrollButtonNext {
		transform: translateY(-50%) translateX(0);
	}
}
