/* Google maps stuff */
:global(.google-maps-find-my-location-button),
:global(.google-maps-find-my-location-button-other) {
	width: 40px;
	height: 40px;
	appearance: none;
	border: none;
	background-color: white;
	margin: 0;
	padding: 0;
	box-shadow: 0 3px 6px #00000029;
	cursor: pointer;
}

:global(.google-maps-find-my-location-button) {
	bottom: 24px !important;
	right: 55px !important;
}

:global(.google-maps-find-my-location-button-other) {
	right: 10px !important;
	display: none;
}

:global(div.pac-container) {
	z-index: 1200 !important;
	position: fixed !important;
	border-top: 1px solid var(--input-field-color) !important;
}

:global(div.pac-item) {
	border-top: 1px solid #dddddd !important;
	background-color: var(--input-field-color);
}

:global(div.pac-item > span:not(:nth-of-type(1))) {
	color: var(--input-field-inside-color);
}

:global(div.pac-logo:after) {
	background-color: var(--input-field-color);
}

:global(.custom-map-marker) {
	font-size: 16px !important;
	font-weight: bold;
	color: black !important;
	-webkit-text-stroke: 0.5px white;
}

:global(.google-maps-custom-map-marker-label) {
	color: var(--input-field-inside-color) !important;
	background-color: var(--input-field-color);
	padding: 10px 15px;
	border-radius: 6px;
	border: 1px solid black;
	position: absolute;
	top: -58px;
	width: fit-content;
	font-size: 14px;
	white-space: nowrap;
}

:global(.google-maps-custom-map-marker-label)::before,
:global(.google-maps-custom-map-marker-label)::after {
	content: "";
	position: absolute;
	-webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
	clip-path: polygon(100% 0, 0 0, 50% 100%);
	left: 50%;
	transform: translate(-50%, 0);
}

:global(.google-maps-custom-map-marker-label)::before {
	height: 19px;
	width: 25px;
	bottom: -19px;
	background-color: black;
}

:global(.google-maps-custom-map-marker-label)::after {
	height: 17px;
	width: 23px;
	bottom: -17px;
	background-color: var(--input-field-color);
}

/* Regular component styles */

.wrapper {
	height: calc(var(--100vh) - 100px - 50px);
	width: var(--100vw);
	background-color: var(--section-background-color);
	z-index: 996;
	position: fixed;
	top: 100px;
	left: 0;
}

.mapWrapper {
	height: 100%;
	width: 100%;
	min-height: 50px;
	display: block;
	position: relative;
	transform-origin: 0 0;
}

.mapWrapperLoading {
	background-color: var(--section-background-color);
	display: flex;
	justify-content: center;
	align-items: center;
}

.mapContainer {
	width: 100%;
	height: 100%;
}

.closeButtonWrapper {
	line-height: 2em;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 2em;
	height: 2em;
	border-radius: 1em;
	background-color: var(--module-box-color);
	cursor: pointer;
	text-align: center;
	z-index: 1100;
	box-shadow: 0 1px 6px #0000006f;
}

.crossIcon {
	width: unset !important;
	height: unset !important;
	display: block !important;
	margin-top: 2px;
}

.crossIconInside {
	stroke: var(--module-box-icon-color);
	fill: none;
}

.autoCompleteWrapper {
	position: absolute;
	top: 30px;
	left: 60px;
	display: flex;
	height: 85px;
	background-color: var(--input-field-color);
	box-shadow: 0 3px 6px #00000029;
	align-items: center;
	width: 480px;
	padding-left: 15px;
	padding-right: 15px;
	gap: 10px;
	z-index: 1;
	border-radius: 6px 6px 0 0;
}

.autoCompleteWrapper:not(.confirmAutoComplete) {
	border-radius: 6px;
}

.backButton:not(.isEditingLocation) {
	display: none;
}

.autoCompleteWrapper .backButton {
	background-color: var(--input-field-color);
	width: 35px;
	cursor: pointer;
}

.autoCompleteWrapper .backButton path {
	fill: var(--input-field-inside-color);
}

.autoCompleteInputWrapper,
.autoCompleteInput {
	width: 100%;
}

.autoCompleteInput {
	border: none;
	height: 32px;
	padding-left: 10px;
	color: var(--input-field-inside-color);
	background-color: var(--input-field-color);
	padding-bottom: 1px;
	padding-top: 1px;
}

.autoCompleteInput::-webkit-input-placeholder {
	color: var(--input-field-inside-color);
	opacity: 70%;
}

.autoCompleteClearWrapper {
	cursor: pointer;
	width: 18px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.autoCompleteClearWrapper .crossIcon {
	width: 22px !important;
	height: 22px !important;
	margin-top: 0;
}

.autoCompleteClearWrapper.noSearchValue {
	opacity: 0;
}

.autoCompleteClearWrapper .crossIconInside {
	height: 100%;
	stroke: var(--input-field-inside-color);
}

.selectedPlaceBox {
	position: absolute;
	top: 30px;
	left: 60px;
	background-color: var(--section-background-color);
	width: 440px;
	box-shadow: 0 3px 6px #00000029;
	border-radius: 6px;
	padding: 20px;
	overflow-y: scroll;
	max-height: calc(100% - 35px);
}

.selectedPlaceBox > section:not(:last-of-type) {
	margin-bottom: 15px;
}

.selectedPlaceBox .addressAndMinimapWrapper {
	margin-top: 15px;
	box-shadow: 0 1px 6px #00000029;
	padding: 10px;
	background-color: var(--module-box-color);
	height: auto;
	display: flex;
	flex-direction: column;
	gap: 5px;
	border-radius: 6px;
}

.address {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: var(--module-box-text-color);
	background-color: var(--module-box-color);
	padding: 10px;
	border-radius: 6px;
	margin-top: 10px;
	box-shadow: 0 1px 6px #00000029;
}

.manualAddress .contactInput {
	border: 1px solid var(--hover-border-color) !important;
}

.address > span:nth-of-type(1) {
	padding-top: 4px;
}

.addressAndMinimapWrapper .mapContainer {
	min-height: 100px;
}

.editAddressIcon {
	stroke: var(--module-box-icon-color);
	cursor: pointer;
}

.contactInput {
	display: flex;
	align-items: center;
	padding: 10px;
	background-color: var(--input-field-color);
	margin-top: 10px;
	border-radius: 6px;
	gap: 10px;
}

.contactInput input {
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: none;
	height: 26px;
	width: 100%;
}

.selectedPlaceBox .addressConfirmButton {
	margin-top: 20px;
}

.selectedSectionText {
	color: var(--module-box-text-color);
	font-weight: 600;
}

.editLocationAddressField,
.editLocationBox {
	position: absolute;
	left: 60px;
	background-color: var(--input-field-color);
	box-shadow: 0 3px 6px #00000029;
	border-radius: 6px;
}

.editLocationAddressField {
	top: 30px;
	display: flex;
	gap: 10px;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	width: 480px;
	height: 85px;
	z-index: 1;
	color: var(--input-field-inside-color);
	font-size: 24px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.editLocationAddressFieldAddress {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.editLocationAddressFieldArrow {
	cursor: pointer;
}

.editLocationAddressFieldArrow path {
	fill: var(--input-field-inside-color);
}

.editLocationBox {
	width: 480px;
	top: 115px;
	padding: 28px 26px 20px;
	max-height: calc(100% - 35px);
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.editLocationBox > section {
	color: var(--input-field-inside-color);
}

.zoneMapIcon {
	fill: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
}

.zoneMapIconWrapper {
	justify-self: center;
}

.transportZone {
	box-shadow: 0 1px 6px #00000029;
	background-color: var(--module-box-color);
	border-radius: 6px;
	display: grid;
	grid-template-columns: auto auto;
	padding: 10px;
	justify-content: space-between;
	height: auto;
	align-items: center;
	margin-top: 10px;
}

.transportZoneNotFound {
	font-weight: 600;
	grid-template-columns: auto;
}

.transportZoneName {
	font-size: 16px;
	color: var(--module-box-text-color);
}

.selectExactLocation {
	display: flex;
	gap: 10px;
	background-color: var(--module-box-color);
	color: var(--module-box-text-color);
	border-radius: 6px;
	padding: 15px 10px;
	cursor: pointer;
	align-items: center;
}

.selectExactLocation .icon {
	font-size: 26px;
}

.selectedExactLocationBox {
	width: 100%;
	margin-top: 15px;
	box-shadow: 0 1px 6px #00000029;
	padding: 10px;
	background-color: var(--module-box-color);
	height: 150px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	border-radius: 6px;
}

.addressNotFoundBox {
	background-color: var(--module-box-color);
	color: var(--module-box-text-color);
	border-radius: 6px;
}

.addressClarificationText {
	font-size: 14px;
	font-weight: 600;
}

.manualAddress {
	display: grid;
	grid-auto-columns: auto;
	column-gap: 10px;
}

.manualAddress > div:nth-child(1) {
	grid-column: 1/3;
}

.coordinatesRequiredText {
	font-size: 14px;
	color: var(--invalid-color);
}

.addressConfirmButton {
	height: 42px;
	min-height: 42px;
}

.confirmPlaceButtonWrapper {
	position: absolute;
	left: 60px;
	top: 115px;
	width: 480px;
	background-color: var(--input-field-color);
	padding: 28px 20px 20px;
	box-shadow: 0 3px 6px #00000029;
	border-radius: 0 0 6px 6px;
}

.autoCompleteSearchWrapper {
	position: relative;
	font-size: 24px;
}

.autoCompleteSearchButton {
	position: absolute;
	top: 1px;
	right: 2px;
	cursor: pointer;
	background-color: var(--input-field-color);
}

.autoCompleteSearchButton > svg {
	color: var(--input-field-inside-color);
	height: 20px;
	width: 20px;
}

.autoCompleteSearchButtonSearched > svg {
	color: var(--finalize-color);
}

.showClarifyAddress {
	background-color: var(--module-box-color);
	padding: 10px;
	margin-top: 15px;
	border-radius: 6px;
}

.markingIcon {
	fill: var(--input-field-inside-color);
	stroke: var(--input-field-inside-color);
}

@media (max-width: 1100px) {
	.wrapper {
		top: 60px;
		height: calc(var(--100vh) - 60px);
	}
}

@media (max-width: 600px) {
	.selectedPlaceBox {
		width: var(--100vw);
		top: 0;
		left: 0;
		border-radius: 0;
		max-height: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.addressConfirmButton {
		margin-top: auto !important;
	}

	.editLocationBox {
		left: unset;
		top: unset;
		bottom: 0;
		width: var(--100vw);
		border-radius: 6px 6px 0 0;
	}

	:global(.google-maps-find-my-location-button) {
		display: none;
	}

	:global(.google-maps-find-my-location-button-other) {
		display: block;
	}

	.confirmPlaceButtonWrapper {
		top: unset;
		bottom: 20px;
		background-color: transparent;
		padding: 0;
		box-shadow: none;
		border-radius: 0;
	}

	.autoCompleteWrapper,
	.confirmPlaceButtonWrapper,
	.editLocationAddressField {
		right: 8px;
		left: 8px;
		width: unset;
	}

	.closeButtonWrapper.isEditingLocation {
		display: none;
	}

	.backButton:not(.isEditingLocation) {
		display: block;
	}

	.editLocationAddressField,
	.autoCompleteWrapper {
		height: 52px;
	}

	.autoCompleteSearchWrapper,
	.editLocationAddressField {
		font-size: 20px;
	}

	.confirmPlaceButtonWrapper > button {
		box-shadow: 0 2px 4px #00000029;
	}

	.editLocationAddressField {
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	.autoCompleteWrapper {
		border-radius: 6px;
	}
}
