.wrapper {
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: var(--main-background-color);
}

.lottieAnimation {
	margin: auto;
	height: clamp(120px, 20vh, 200px);
}

.timeline {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-inline-start: 0;
	margin-top: 0;
	margin-bottom: 30px;
}

.timelineLine,
.timelineLineComplete {
	margin-left: 5px;
	margin-right: 5px;
	padding: 0 20px;
}

.timelineLine {
	border-top: 3px solid var(--disable-color);
}

.timelineLineComplete {
	border-top: 3px solid var(--confirmed-amount-color);
}

.timeline > li > div:before {
	content: "";
	display: block;
	width: 15px;
	height: 15px;
	background-color: var(--disable-color);
	border-radius: 25px;
}

.timeline > li.complete > div:before {
	background-color: var(--confirmed-amount-color);
	border: 2px solid var(--confirmed-amount-color);
}

.timeline > li.current > div:before {
	background-color: transparent;
	border: 2px solid var(--confirmed-amount-color);
}

@media (max-width: 880px) {
	.lottieAnimation {
		height: clamp(160px, 35vh, 450px);
		max-width: 100%;
	}
}
