.wrapper {
	display: grid;
	grid-template-areas:
		"icon info"
		".    info";
	grid-template-columns: auto 1fr;
	column-gap: 12px;
	align-items: center;
	margin: 0 4px;
	padding: 0;
}

.icon {
	grid-area: icon;
	margin: 0;
}

.info {
	grid-area: info;
	display: grid;
	grid-template-rows: auto auto;
	color: var(--text-color-2);
	font-size: 9px;
}
