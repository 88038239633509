.footerRow {
	margin-top: 4rem;
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: var(--footer-color);
	height: clamp(45px, 5.7vh, 60px);
	z-index: 10;
}

.poweredBy {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.logoWrapper {
	height: 40%;
}

.logo {
	fill: var(--footer-logo-color);
}
