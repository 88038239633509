.wrapper {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 0;
	height: 0;
}

.annotation {
	position: relative;
	top: -0.4em;
	right: 0.4em;
	display: grid;
	align-content: center;
	justify-content: center;
	border-radius: 1000px;
	width: 1em;
	height: 1em;
}
