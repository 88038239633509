.sbtH1 {
	margin: 0;
	font-size: 40px;
	font-weight: 600;
	color: var(--section-background-text-color);
}

@media (max-width: 1100px) {
	.sbtH1 {
		font-size: 28px !important;
	}
}
