.wrapper {
	width: 100%;
	position: relative;
}

.selector {
	width: 100%;
	height: 52px;
	display: flex;
	gap: 10px;
	padding: 15px 20px 15px 10px;
	background-color: var(--module-box-color);
	align-items: center;
	border-radius: 6px;
}

.selector.notSelected {
	box-shadow: 0 2px 3px #00000029;
	cursor: pointer;
}

.contactIcon path {
	fill: var(--module-box-icon-color);
	stroke: var(--module-box-icon-color);
}

.choosePersonText {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-weight: 500;
	color: var(--module-box-text-color);
}

.angleIcon {
	font-size: 22px;
}

.dropdown {
	position: relative;
	top: 5px;
	width: 100%;
	min-height: 62px;
	background-color: var(--module-box-color);
	border-radius: 12px;
	box-shadow: 0 2px 3px #00000029;
}

.dropdownItem {
	display: flex;
	gap: 10px;
	padding: 15px 20px 15px 10px;
	cursor: pointer;
	border-bottom: 1px solid #dddddd;
}

.dropdownItem:hover {
	background-color: var(--section-hover-color);
}

.dropdownItem:first-of-type:hover {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.dropdownItem:last-of-type:hover {
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.closeXIcon {
	width: 18px !important;
	height: 28px !important;
	cursor: pointer;
	stroke: var(--module-box-icon-color);
}

.closeXIcon svg {
	height: 14px;
}

.moreContactPersonsBtn {
	max-width: 200px;
	display: flex;
	justify-content: space-between;
	padding: 10px 15px;
	align-items: center;
	gap: 20px;
	width: unset;
}

.profilePic {
	width: 32px;
	min-width: 32px;
	height: 32px;
	min-height: 32px;
	overflow: hidden;
	border-radius: 100px;
	background-color: rgb(165, 165, 165);
	cursor: pointer;
}

.profilePic img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.addContactPersonPfp {
	width: 32px;
	min-width: 32px;
	max-width: 32px;
	height: 32px;
	min-height: 32px;
	max-height: 32px;
	border-radius: 100px;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
}

.arrowIcon {
	color: var(--module-box-icon-color);
	cursor: pointer;
}
