.input {
	width: 100%;
	height: 2.625em;
	border-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	margin-bottom: 10px;
}

.input:disabled {
	cursor: not-allowed;
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
}

.inputTitle {
	color: var(--section-background-text-color);
	font-size: 16px;
	font-weight: 600;
}

.steppedFormWrapper {
	display: flex;
	flex-direction: column;
	row-gap: 25px;
}

.steppedFormBox {
	padding: 25px;
	background-color: var(--section-background-color);
	max-width: 550px;
	width: 100%;
	margin: 0 auto;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
}

.steppedFormBoxClosed {
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;
}

.steppedFormBoxClosedEditIcon {
	margin-top: auto;
	margin-bottom: auto;
	min-width: 22px;
	width: 22px;
}

.steppedFormBoxClosedEditIcon svg {
	stroke: var(--section-icon-color);
	cursor: pointer;
}

.steppedFormBoxClosedEditIconDisabled svg {
	stroke: var(--disable-inside-color);
	cursor: not-allowed;
}

.steppedFormBoxHeader {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
	margin-bottom: 10px;
}

.steppedFormBoxSubHeader {
	font-size: 16px;
	color: var(--section-background-text-color);
	margin-bottom: 25px;
}

.steppedFormBoxClosed .steppedFormBoxHeader,
.steppedFormBoxClosed .steppedFormBoxSubHeader {
	margin-bottom: 0;
	font-size: 20px;
	word-break: break-word;
	hyphens: auto;
}

.steppedFormBoxClosed .steppedFormBoxHeader {
	margin-bottom: 5px;
}

.steppedFormFinalStepDescription {
	font-size: 16px;
	color: var(--section-background-text-color);
	margin-bottom: 5px;
}

.steppedFormFinalStepAutoRedirect {
	font-size: 16px;
	color: var(--section-background-text-color);
	margin-bottom: 15px;
}

.steppedFormBoxCompleted .steppedFormBoxHeader,
.steppedFormBoxCompleted .steppedFormBoxSubHeader,
.steppedFormBoxCompleted .steppedFormFinalStepDescription,
.steppedFormBoxCompleted .steppedFormFinalStepAutoRedirect {
	text-align: center;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.nextButton {
	margin-left: auto;
	margin-top: 10px;
	max-width: 170px;
	width: 100%;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.nextStepErrorText {
	font-size: 16px;
	font-weight: 600;
	color: var(--invalid-color);
	margin-top: 15px;
	align-self: flex-end;
	word-break: break-word;
	hyphens: auto;
}

.finalStepButton {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
