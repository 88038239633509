.wrapper {
	margin: auto;
	padding-right: 10px;
	padding-left: 10px;
	max-width: 700px;
}

.header {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	color: var(--main-background-text-color);
}

.subHeader {
	text-align: center;
	font-size: 16px;
	color: var(--main-background-text-color);
}

.productImage {
	height: 2em;
}

.showSummaryButton {
	max-width: 430px;
}

.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

.summarySectionWrapper {
	width: 100%;
	margin-top: 10px;
}

.summarySectionWrapper > div {
	margin-left: auto;
	margin-right: auto;
}

.attachments {
	margin-top: 35px;
}

.orderImagesWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	background-color: var(--module-box-color);
	padding: 10px;
	border-radius: 6px;
}

.orderImagesWrapper img {
	max-width: 120px;
	width: 120px;
	height: 120px;
	max-height: 120px;
	padding: 2px;
	border-radius: 16px;
	box-shadow: 0 1px 6px #00000029;
	cursor: pointer;
}

.orderImagesWrapper img:hover {
	border: 1px solid var(--chosen-border-color);
}

.transportationMethod {
	color: var(--module-box-text-color);
	border-radius: 6px;
	background-color: var(--module-box-color);
	padding: 15px;
	margin-top: 15px;
	margin-bottom: 10px;
	font-size: 16px;
}

@media (max-width: 710px) {
	.wrapper {
		padding-right: 0;
		padding-left: 0;
	}
}
