.trashIcon {
	stroke: var(--module-box-icon-color);
	fill: var(--module-box-icon-color);
}

.discountIcon {
	stroke: var(--module-box-discount-color);
	fill: var(--module-box-discount-color);
}

.deleteDiscountCode {
	cursor: pointer;
	color: var(--section-background-text-color);
	margin-left: auto;
	margin-top: auto;
	margin-bottom: auto;
}

.deleteDiscountCode:hover .trashIcon:not(.disabled) {
	stroke: var(--section-background-text-color);
	fill: var(--section-background-text-color);
}

.basketItemProductRemove {
	display: grid;
	grid-template-columns: 20px 60px;
	align-items: center;
	font-size: 14px;
	margin-top: 4px;
}

.orderItemRemoveIcon {
	stroke: var(--basket-module-icon-color);
	fill: var(--basket-module-icon-color);
}

.discountCodeContainer {
	display: block;
	margin-top: 20px;
	background-color: var(--section-background-color);
	padding: 25px 24px 42px;
	border-radius: 6px;
	container: discountCodeContainer / inline-size;
}

.discountCodeContainer .inputWrapper {
	display: flex;
	gap: 15px;
}

.discountCodeContainer .inputWrapper label {
	color: var(--section-background-text-color);
}

.discountCodeContainer .inputWrapper > label > strong {
	font-size: 18px;
}

.discountCodeContainer .input {
	width: 28px;
	height: 28px;
	background-color: var(--section-background-color);
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid var(--section-icon-color);
	-webkit-appearance: none;
	cursor: pointer;
	flex-shrink: 0;
}

.discountCodeContainer .input:checked {
	background-color: var(--accent-color);
	border: none;
}

.discountCodeContainer .input:disabled {
	background-color: var(--disable-color);
	cursor: not-allowed;
}

.discountCodeInput {
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border-radius: 4px;
	padding-left: 12px;
	width: 100%;
	border: none;
	margin-right: 8px;
	height: 2.625em;
}

.discountCodeInput:focus-visible {
	outline: var(--chosen-border-color) 0.7px solid;
}

.discountCodeInput:disabled {
	color: var(--disable-inside-color);
	background-color: var(--disable-color);
	cursor: wait;
}

.addDiscountCodeWrapper {
	padding-top: 10px;
	border-radius: 6px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 600;
}

.addDiscountCodeWrapper > span {
	color: var(--module-box-text-color);
}

.addDiscountCodeWrapper > button {
	-webkit-appearance: none;
	border: none;
	background-color: var(--accent-color);
	border-radius: 50px;
	padding: 5px 20px;
	font-weight: 600;
	cursor: pointer;
	height: 28px;
	width: 100px;
	min-width: 100px;
	color: var(--accent-inside-color);
}

.addDiscountCodeWrapper > button:hover {
	background-color: var(--accent-hover-color);
	color: var(--accent-hover-inside-color);
}

.addDiscountCodeWrapper > button:disabled {
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
	cursor: wait;
}

.validDiscountCodeWrapper {
	background-color: var(--module-box-color);
	display: flex;
	padding: 12px;
	border-radius: 6px;
}

.validDiscountCodeTextWrapper {
	display: flex;
	flex-direction: column;
	margin-left: 12px;
	gap: 6px;
}

.validDiscountCodeText {
	color: var(--module-box-text-color);
}

.discountIcon {
	margin-top: auto;
	margin-bottom: auto;
}

@container discountCodeContainer (max-width: 250px) {
	.addDiscountCodeWrapper {
		flex-direction: column;
		gap: 10px;
	}

	.addDiscountCodeWrapper > button {
		width: 100%;
		height: 36px;
	}

	.discountCodeInput {
		margin-right: 0;
	}
}

@media (max-width: 1100px) {
	.discountCodeContainer > h2 {
		font-size: 20px;
	}
}

@media (max-width: 852px) {
	.discountCodeContainer {
		max-width: unset;
	}
}

@media (max-width: 710px) {
	.discountCodeContainer {
		padding-left: 12px;
		padding-right: 12px;
	}
}
