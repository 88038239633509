.wrapper {
	display: grid;
	grid-template-columns: auto min-content;
}

.wrapper > div {
	display: grid;
	grid-template-columns: auto min-content; /*Fallback for browser without subgrid support, ie. Samsung Internet*/
	grid-template-columns: subgrid;
	grid-column-start: 1;
	grid-column-end: -1;
	align-items: center;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #d8d8d8;
}

.infoText {
}

.title {
	font-weight: 600;
}

.description {
	font-size: 14px;
}

.narrowTitle {
	display: none;
}

.sidebarWrapperHidden .loginButtonWrapper {
	display: none;
}

.downloadButton {
	height: 32px;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-wrap: nowrap;
}

.downloadIcon {
	display: none;
}

@media (max-width: 320px) {
	.infoText {
		display: none;
	}

	.narrowTitle {
		display: block;
	}

	.downloadButton {
		width: 35px;
		height: 20px;
		padding: 0;
	}

	.downloadText {
		display: none;
	}

	.downloadIcon {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
