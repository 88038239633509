.wrapper {
	width: 100%;
	background-color: var(--main-background-color);
	padding-bottom: 20px;
}

.listOrdersText {
	margin-bottom: 40px;
	color: var(--main-background-text-color);
}

.listOrdersText > div {
	font-size: 40px;
	font-weight: 600;
}

.statusText {
	margin-right: 10px;
}

.statusIcon {
	display: none;
}

.tableHeaderSubtext {
	font-weight: normal;
	font-size: 14px;
}

.iconColorStroke {
	stroke: black;
}

.iconColorFill {
	fill: black;
}

.orderState {
	width: 100%;
	max-width: 110px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	border-radius: 35px;
}

.orderStateIcon {
	max-width: 30px;
	height: 30px;
}

.orderStateRow {
	max-width: unset;
	height: 40px !important;
	border-radius: 6px 0 0 0;
	width: 100%;
	justify-content: flex-start;
	gap: 10px;
}

.orderStateRow > span {
	font-weight: 600;
	font-size: 18px;
}

div.orderStateAccepted {
	background-color: var(--status-accepted-color);
	color: var(--status-accepted-inside-color);
}

.orderStateAccepted svg {
	fill: var(--status-accepted-inside-color);
}

div.orderStateCreated {
	background-color: var(--status-not-managed-color);
	color: var(--status-not-managed-inside-color);
}

.orderStateCreated svg {
	fill: var(--status-not-managed-inside-color);
}

div.orderStateDeclined {
	background-color: var(--status-denied-color);
	color: var(--status-denied-inside-color);
}

.orderStateDeclined svg {
	fill: var(--status-denied-inside-color);
}

div.orderStateDone {
	background-color: var(--status-done-color);
	color: var(--status-done-inside-color);
}

.orderStateDone svg {
	fill: var(--status-done-inside-color);
}

.filterButton {
	cursor: pointer;
	border-radius: 22px;
	background-color: var(--filter-button-color);
	color: var(--filter-button-inside-color);
	height: 45px;
	width: 45px;
	border: none;
	flex-shrink: 0;
}

.filterButton.onlyDesktop {
	display: flex;
	align-items: center;
	width: 150px;
	justify-content: space-between;
	padding-left: 20px;
	padding-right: 20px;
}

.searchInputAndMobileFilterWrapper {
	display: flex;
	gap: 10px;
	flex-basis: 330px;
}

.filterButton:hover,
.searchByOrderNumberButton:hover {
	background-color: var(--filter-button-hover-color);
	color: var(--filter-button-hover-inside-color);
}

.filterButton svg {
	fill: var(--filter-button-inside-color);
}

.filterButton:hover svg {
	fill: var(--filter-button-hover-inside-color);
}

.searchByOrderNumberButtonIcon {
	width: 20px;
	height: 18px;
}

button > .searchByOrderNumberButtonIcon {
	stroke: var(--filter-button-inside-color);
}

button:hover > .searchByOrderNumberButtonIcon {
	stroke: var(--filter-button-hover-inside-color);
}

span > .searchByOrderNumberButtonIcon {
	stroke: var(--input-field-inside-color);
}

.searchByOrderNumberButton {
	cursor: pointer;
	border-radius: 22px;
	background-color: var(--filter-button-color);
	color: var(--filter-button-inside-color);
	height: 45px;
	border: none;
	flex-shrink: 0;
	align-items: center;
	gap: 15px;
	max-width: 220px;
	width: 100%;
}

.searchInputWrapper {
	display: flex;
	flex-basis: 330px;
	height: 45px;
}

.searchInputWrapper input {
	width: 100%;
	height: 45px;
	-webkit-appearance: none;
	border-radius: 0 22px 22px 0;
	border: none;
	color: var(--input-field-inside-color);
	background-color: var(--input-field-color-mback);
}

.searchInputWrapper input::-webkit-input-placeholder {
	color: var(--input-field-inside-color);
}

.searchInputWrapper input::-webkit-inner-spin-button {
	display: none;
}

.searchInputWrapper input::-webkit-textfield-decoration-container {
	margin-right: 5px;
}

.searchInputWrapper input:focus-visible {
	outline: none;
}

.searchInputWrapper > span {
	border-top-left-radius: 22px;
	border-bottom-left-radius: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--input-field-color-mback);
}

.searchInputWrapper > span > svg {
	width: 20px;
	margin-left: 10px;
	margin-right: 10px;
	color: var(--input-field-inside-color);
}

.searchInputWrapper.disabled *,
.filterButton.disabled {
	background-color: var(--disable-color) !important;
	color: var(--disable-inside-color) !important;
	cursor: progress !important;
}

.searchInputWrapper.disabled svg {
	stroke: var(--disable-inside-color) !important;
}

.searchInputWrapper.disabled input::-webkit-input-placeholder {
	color: var(--disable-inside-color);
}

.filterButton.disabled svg {
	fill: var(--disable-inside-color);
}

.clickableRow > td {
	padding-top: 20px;
	padding-bottom: 20px;
}

.clickableRow:hover {
	background-color: var(--section-hover-color);
	cursor: pointer;
}

.selectedFilterRow {
	display: flex;
	gap: 5px;
	margin-left: 4px;
	overflow-x: scroll;
	min-width: 100px;
	flex-wrap: wrap;
}

.selectedFilterRow::-webkit-scrollbar {
	display: none;
}

.selectedFilter {
	border: solid #5d5d5d 1px;
	padding: 5px;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 600;
	white-space: nowrap;
	max-width: 200px;
	display: grid;
	grid-template-columns: auto 25px;
	background-color: var(--input-field-color-mback);
}

.selectedFilter > span {
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 20px;
	color: var(--input-field-inside-color);
}

.selectedFilter > button {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.closeXIcon {
	width: unset !important;
	height: unset !important;
	display: block !important;
	margin-top: 2px;
}

.closeXIconInside {
	stroke: var(--input-field-inside-color);
	fill: none;
	width: 12px;
	height: 12px;
}

.topFilterRow {
	display: flex;
	gap: 10px;
}

.cellIcon {
	display: none;
}

.orderNotFoundError {
	color: var(--invalid-color);
}

.productCategoryImage {
	width: 30px;
	max-height: 80px;
	aspect-ratio: 1/1;
	fill: var(--section-icon-color);
	stroke: var(--section-icon-color);
	stroke-width: 0.3px;
}

.productCategoryImage path[data-name="stroke"] {
	stroke: var(--section-icon-color);
}

.amountOfProductsCircle {
	background-color: var(--confirmed-amount-color);
	border-radius: 100px;
	font-size: 12px;
	width: 15px;
	height: 15px;
	color: var(--confirmed-amount-inside-color);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -7px;
	right: -10px;
}

.selectedFilterRowWrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 20px;
	gap: 10px;
}

.clearFilters {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	color: var(--main-background-text-color);
}

.brandedSelect {
	background-color: var(--input-field-color-mback);
	color: var(--input-field-inside-color);
	border-radius: 15px;
	width: 45px;
	padding-top: 2px;
	padding-left: 5px;
	height: 25px;
	cursor: pointer;
}

.brandedSelect {
	width: 60px;
}

.brandedSelect:disabled {
	cursor: not-allowed;
}

.dateColumnHeader {
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 55px;
	gap: 16px;
}

.dateColumnHeaderArrows {
	display: flex;
	flex-direction: column;
}

.onlyMobile,
.onlyMobileFlex {
	display: none;
}

.dateSlotTableContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.amountCircle {
	display: flex;
	border-radius: 100px;
	width: 22px;
	font-size: 10px;
	align-items: center;
	justify-content: center;
	height: 22px;
	font-weight: 600;
	line-height: 12px;
}

.amountCircle:hover {
	box-shadow: 0 3px 6px #00000029;
}

.circleNotificationColor {
	background-color: var(--client-notification-color);
	color: var(--client-notification-inside-color);
}

.circleAccentColor {
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
}

.activityCell svg,
.attachmentsCell svg {
	stroke: var(--section-icon-color);
	fill: var(--section-icon-color);
	stroke-width: 0.5;
}

.attachmentsCell > span,
.activityCell > span {
	display: flex;
	height: 100%;
	gap: 4px;
	align-items: center;
}

.statusRow {
	display: none;
}

.dateTimeCell .time svg {
	display: none;
}

.paymentReferenceCell .paymentReferenceLogo {
	display: none;
}

.zeroAmountCircle {
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
}

@media (max-width: 1100px) {
	.listOrdersText {
		margin-bottom: 10px;
	}

	.listOrdersText > div {
		font-size: 24px;
	}
}

@media (max-width: 1000px) {
	.statusText {
		display: none;
	}

	.statusIcon {
		display: block;
	}

	.statusColumn {
		min-width: 50px !important;
	}
}

@media (min-width: 761px) {
	.ordersTable tbody {
		position: relative;
		top: -2px;
	}

	.ordererColumn,
	.addressColumn {
		width: 18%;
	}

	.orderNumberColumn,
	.paymentReferenceColumn {
		width: 10%;
	}

	.categoryColumn {
		width: 16%;
	}

	.markingColumn {
		width: 13%;
	}

	.dateColumn,
	.statusColumn {
		width: 12%;
	}

	.statusColumn {
		min-width: 110px;
	}

	.attachmentsColumn {
		min-width: 70px;
		margin-right: 10px;
	}

	.activityColumn {
		min-width: 80px;
	}

	.ordersTable > tbody > tr td:nth-of-type(4) {
		hyphens: auto;
	}

	.tableSpacing {
		min-width: 10px;
	}
}

@media (max-width: 950px) {
	.paymentReferenceCell .content {
		display: flex;
		padding-right: 10px;
		align-items: center;
		height: 100%;
	}

	.paymentReferenceCell .paymentReferenceLogo {
		display: unset;
		height: 28px;
		margin-left: auto;
	}

	.paymentReferenceCell .paymentReferenceText {
		display: none;
	}

	.statusRow {
		display: block;
	}

	.statusIcon {
		display: none;
	}

	.orderNumberCell.orderStateAccepted {
		background-color: var(--status-accepted-color);
		color: var(--status-accepted-inside-color);
	}

	.orderNumberCell.orderStateCreated {
		background-color: var(--status-not-managed-color);
		color: var(--status-not-managed-inside-color);
	}

	.orderNumberCell.orderStateDeclined {
		background-color: var(--status-denied-color);
		color: var(--status-denied-inside-color);
	}

	.orderNumberCell.orderStateDone {
		background-color: var(--status-done-color);
		color: var(--status-done-inside-color);
	}

	.orderNumberCell > div {
		margin-left: auto;
	}

	.orderNumberCell > div > div,
	.orderNumberCell > div > span {
		width: fit-content;
		margin-left: auto;
	}

	.dateSlotTableContent {
		display: grid;
		grid-template-columns: auto auto auto;
		column-gap: 3px;
	}

	.onlyMobile {
		display: block;
	}

	.onlyMobileFlex {
		display: flex;
	}

	span.onlyDesktop,
	button.onlyDesktop,
	.onlyDesktop,
	.filterButton.onlyDesktop,
	.ordersTable td.onlyDesktop {
		display: none;
	}

	.cellIcon {
		display: block;
		color: var(--section-icon-color);
		stroke: var(--section-icon-color);
		width: 18px;
		height: 18px;
	}

	.topFilterRow {
		flex-direction: column-reverse;
		gap: 5px;
	}

	.searchInputWrapper {
		margin-left: 0;
		flex-basis: 100%;
	}

	.orderNotFoundError {
		margin-left: 0;
	}

	.ordersTable thead {
		display: none;
	}

	.ordersTable tbody tr {
		border-radius: 6px;
		margin-bottom: 12px;
		display: grid;
		grid-template-areas:
			"status status status status orderNumber orderNumber"
			"categoryAndService categoryAndService categoryAndService consumer consumer consumer"
			"address address address address address address"
			"dateTime dateTime dateTime dateTime dateTime dateTime"
			"activity attachments paddingCol paddingCol paddingCol paymentReference";
		grid-template-columns: repeat(6, 1fr);
		padding-bottom: 10px;
		grid-row-gap: 9px;
	}

	.ordersTable tbody tr td {
		border: none !important;
		padding-left: 10px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.ordersTable,
	.ordersTable tbody,
	.ordersTable tbody tr td {
		display: block;
	}

	.ordersTable .orderState {
		max-width: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		height: 30px;
		font-size: 14px;
	}

	.ordersTable tbody tr td.statusCell {
		grid-area: status;
		justify-self: flex-start;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.orderNumberCell {
		grid-area: orderNumber;
		font-size: 16px;
		font-weight: 400;
		display: flex !important;
		align-items: center;
		border-top-right-radius: 6px;
		padding-right: 10px !important;
	}

	.consumerCell {
		font-size: 16px;
		grid-area: consumer;
		margin-left: 10px;
		margin-right: 10px;
		width: calc(100% - 20px);
	}

	.consumerCell .onlyMobile {
		font-size: 16px;
	}

	.addressCell {
		grid-area: address;
		margin-right: 10px;
		margin-left: 10px;
		width: calc(100% - 20px);
		font-size: 16px !important;
		align-items: center;
	}

	.addressCell .cellIcon {
		width: 20px !important;
		height: 20px !important;
	}

	.addressCell,
	.dateTimeCell {
		font-size: 14px;
	}

	.dateTimeCell > span {
		display: grid;
		width: 100%;
		grid-template-columns: calc(50% - 5px) calc(50% - 5px);
		column-gap: 10px;
		margin-right: 10px;
	}

	.dateTimeCell .exactTime {
		display: none;
	}

	.dateTimeCell .date,
	.dateTimeCell .time {
		width: 100%;
		display: flex;
	}

	.dateTimeCell .time svg {
		display: block;
		font-size: 18px;
	}

	.addressCell strong,
	.dateTimeCell strong {
		font-weight: 500;
	}

	.dateTimeCell {
		grid-area: dateTime;
	}

	.addressCell,
	.dateTimeCell {
		display: flex !important;
		flex-wrap: wrap;
		gap: 5px;
	}

	.addressCell > div:not(:last-of-type):not(.cellIcon)::after {
		content: ",";
	}

	.categoryAndServiceCell {
		grid-area: categoryAndService;
		margin-left: 10px;
		margin-right: 10px;
		width: calc(100% - 10px);
	}

	.categoryAndServiceCell .amountOfProductsCircle {
		display: none;
	}

	.consumerCell,
	.categoryAndServiceCell,
	.addressCell {
		justify-self: start;
		padding: 10px 10px !important;
		background-color: var(--module-box-color);
		border-radius: 6px;
	}

	.dateTimeCell .date,
	.dateTimeCell .time {
		justify-self: start;
		padding: 10px 10px !important;
		background-color: var(--module-box-color);
		border-radius: 6px;
		gap: 8px;
		align-items: center;
	}

	.dateTimeCell .date .cellIcon {
		min-width: 18px;
	}

	.categoryIconBgWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.statusCell .orderStateRow {
		padding-left: 5px;
	}

	.statusText {
		margin-right: 0;
	}

	.searchInputAndMobileFilterWrapper {
		flex-basis: unset;
	}

	.filterButton {
		padding: 0;
	}

	.filterButton > div {
		margin: 0 auto;
	}

	.attachmentsCell {
		grid-area: attachments;
	}

	.attachmentsCell.noActivities {
		grid-column: 1/2;
	}

	.attachmentsCell > span,
	.activityCell > span {
		padding: 5px;
		width: 70px;
	}

	.attachmentsCell:hover > span,
	.activityCell:hover > span {
		background-color: var(--section-hover-color);
		cursor: pointer;
		border-radius: 12px;
	}

	.activityCell {
		grid-area: activity;
	}

	.paymentReferenceCell {
		grid-area: paymentReference;
	}
}

@media (max-width: 400px) {
	.selectedFilterRowWrapper {
		flex-direction: column;
		gap: 0;
	}

	.dateSlotTableContent {
		grid-template-columns: auto;
	}
}
