.wrapper {
	pointer-events: none;
	position: fixed;
	z-index: 1200;
	bottom: 0;
	left: 0;
	font-size: 1em;
	color: rgba(88, 88, 88, 0.5);
	margin: 0;
	padding: 0;
}
