.sidebarWrapper {
	background-color: var(--sidebar-color);
	position: fixed;
	top: 100px;
	left: 0;
	box-shadow: 0 0 20px #00000029;
	transition: all 0.25s;
	z-index: 996;
	height: calc(var(--100vh) - 100px);
}

.sidebarWrapperShown {
	z-index: 998;
}

.userIdentifierWrapper {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 220px;
}

.sidebarWrapperHidden .userIdentifierWrapper,
.sidebarWrapperHidden .switchConsumerIcon {
	display: none;
}

.sidebarLogo {
	fill: var(--sidebar-logo-color);
	margin-top: 20px;
	margin-bottom: 15px;
	height: 22px;
}

.sidebarLogoMini {
	fill: var(--sidebar-logo-color);
	margin-top: 20px;
	margin-bottom: 15px;
	height: 28px;
}

.sidebarWrapperHidden .sidebarBottomActions {
	display: none;
}

.sidebarBottomActions hr {
	height: 0;
	border-top: 1px solid #d8d8d8;
}

.sidebarWrapperShown {
	width: 300px;
}

.sidebarWrapperHidden {
	width: 105px;
}

.sidebarWrapperHidden .sidebarBottomWrapper {
	display: flex;
	justify-content: center;
	padding-left: 10px;
}

.menuItemsWrapper {
	position: relative;
	height: 100%;
	overflow-y: auto;
	border-bottom: 1px solid #c7c7c7;
	display: flex;
	flex-direction: column;
	/*
		Hide overflow to stop scrollbar being shown when zoom is applied due to dpi.
		When zoom compensation is applied, depending on zoom, the element gets 1 or 2 pixels too big or small, generating a scrollbar, which looks ugly
	*/
	overflow-x: hidden;
}

.menuItemsWrapper hr {
	height: 0;
	border: 1px solid #e5e5e5;
	width: calc(100% - 2px);
	margin: 0;
}

.sidebarWrapperShown .bigMenuItem {
	padding-left: 25px;
}

.menuItem {
	color: var(--main-background-text-color);
	background-color: transparent;
	border: none;
	font-size: 20px;
	display: grid;
	grid-template-columns: 35px auto;
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
	margin: 8px 0;
	text-decoration: none;
}

.menuItem:not(.bigMenuItem):not(.orderPageMenuItem):hover {
	background-color: var(--sidebar-hover-color);
	border-radius: 12px;
}

.clientInformation .menuItem:not(.bigMenuItem):hover {
	background-color: unset;
}

.sidebarWrapperShown .menuItem:not(.bigMenuItem) {
	margin-left: 25px;
	margin-right: 25px;
	padding-left: 13px;
	padding-right: 25px;
}

.sidebarWrapperShown .clientInformation .menuItem {
	margin-right: 0;
	padding-right: 10px;
}

.sidebarWrapperHidden.loggedIn .clientInformation {
	margin-top: 12px;
	display: block;
}

.clientInformation .menuItem {
	margin-bottom: 0;
	margin-top: 0;
	font-size: 16px;
}

.clientInformation .menuItem a,
.clientInformation .menuItem a:not(:visited) {
	text-decoration: none;
	color: var(--sidebar-text-color);
}

.clientInformationSection {
	margin-bottom: auto;
	padding-top: 20px;
}

.sidebarWrapper:not(.sidebarWrapperShown) .bigMenuItem,
.sidebarWrapper:not(.sidebarWrapperShown) .menuItem {
	display: flex;
	align-items: center;
	margin: 0 12px 10px 18px;
	padding: 0;
	flex-direction: column;
	gap: 10px;
	width: 60px;
	height: 60px;
	min-width: 60px;
	min-height: 60px;
	justify-content: center;
	border-radius: 12px;
}

.sidebarWrapper:not(.sidebarWrapperShown) .bigMenuItem {
	margin-top: 10px;
	margin-bottom: 20px;
}

.sidebarWrapper:not(.sidebarWrapperShown) .clientInformationSection .bigMenuItem > span:nth-of-type(2) {
	display: none !important;
}

.sidebarWrapper:not(.sidebarWrapperShown) .menuItemTextStrong {
	display: none;
}

.sidebarWrapper:not(.sidebarWrapperHidden) .collapsedOnly {
	display: none;
}

.sidebarWrapper:not(.loggedIn) .loggedInOnly {
	display: none;
}

.sidebarWrapper:not(.loggedOut) .loggedOutOnly {
	display: none;
}

.clientInformationCollapsedText {
	font-size: 14px;
	font-weight: 600;
	color: var(--sidebar-text-color);
}

.clientInformation .menuItemTextStrong {
	display: flex;
	align-items: center;
}

.bigMenuItem {
	grid-template-columns: 64px auto;
}

.selectedMenuItem {
	background-color: var(--sidebar-chosen-color);
	border-radius: 12px;
}

.menuItemIcon svg {
	color: var(--sidebar-icon-color);
	stroke: var(--sidebar-icon-color);
}

.menuItemIcon svg path[data-name="fill"] {
	fill: var(--sidebar-icon-color);
}

.menuItem.orderPageMenuItem .menuItemTextStrong,
.menuItem.orderPageMenuItem .collapsedOnly {
	color: var(--accent-inside-color);
}

.menuItem.orderPageMenuItem svg {
	stroke: var(--accent-inside-color);
}

.menuItem.orderPageMenuItem:hover {
	background-color: var(--accent-hover-color);
}

.menuItem.orderPageMenuItem:hover .menuItemTextStrong,
.menuItem.orderPageMenuItem:hover .collapsedOnly {
	color: var(--accent-hover-inside-color);
}

.menuItem.orderPageMenuItem:hover svg {
	stroke: var(--accent-hover-inside-color);
}

.profilePicture,
.profilePicture > img {
	width: 45px;
	height: 45px;
	min-width: 45px;
	min-height: 45px;
	border-radius: 100px;
}

.profilePicture {
	background-color: #6f6f70;
	display: block;
	overflow: hidden;
	position: relative;
}

.profilePicture > img {
	object-fit: cover;
}

.clientLogo,
.clientLogo > img {
	border-radius: 6px;
}

.menuItemText {
	font-weight: normal;
}

.menuItemTextStrong {
	font-weight: 600;
}

.menuItemText,
.menuItemTextStrong {
	color: var(--sidebar-text-color);
	max-width: 290px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.sidebarInnerWrapper {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.sidebarBottomWrapper {
	padding-left: 15px;
	padding-right: 10px;
}

.sidebarWrapperShown .sidebarLogoMini {
	display: none;
}

.sidebarWrapperHidden .sidebarLogo {
	display: none;
}

.hiddenMenuItem {
	display: none !important;
}

.orderPageMenuItem {
	background-color: var(--accent-color);
	margin-top: 30px !important;
	height: 60px;
	min-height: 60px;
}

.sidebarWrapperHidden .orderPageMenuItem {
	border-radius: 20px !important;
	margin-right: auto !important;
}

.sidebarWrapperShown .orderPageMenuItem {
	margin-left: 10px;
	margin-right: 10px;
	border-radius: 32px;
	padding-right: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

span.menuItemTextStrong.oneLineClamp {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
	word-break: break-all;
}

.clientInformation .menuItem .oneLineClamp {
	line-height: 22px;
}

@media (max-width: 1100px) {
	.sidebarWrapper {
		height: calc(var(--100vh) - 60px);
		top: 60px;
	}

	.sidebarWrapperHidden {
		left: -105px;
	}
}

@media (max-width: 710px) {
	.sidebarWrapper {
		z-index: 1001;
	}
}

@media (max-width: 320px) {
	.sidebarWrapperShown {
		width: 200px;
	}

	.userIdentifierWrapper {
		width: 120px;
	}

	.sidebarLogo {
		width: 180px;
	}

	.menuItemText,
	.menuItemTextStrong {
		max-width: 190px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
