.modal {
	padding-top: 20px;
	height: calc(var(--100vh) - 20px);
	max-height: 620px;
	overflow-y: scroll;
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 580px;
}

.header {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
}

.finalize {
	margin-top: auto;
	min-height: 42px;
}

@media (max-width: 710px) {
	.modal {
		max-height: unset;
		height: calc(var(--100vh) - 60px);
	}
}
