.modal {
	max-height: calc(var(--100vh) * 0.85);
}

.header {
	color: var(--section-background-text-color);
	font-size: 28px;
	margin-bottom: 20px;
	font-weight: 600;
}

.inputField input {
	width: calc(100% - 2px);
	margin-left: 1px;
	height: 2.625em;
	border-radius: 6px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border: 0;
	padding-left: 10px;
	margin-bottom: 10px;
}

.inputField input:focus {
	outline: 0.7px solid var(--chosen-border-color);
}

.finalize {
	margin-top: auto;
}

.wrapper {
	display: flex;
	flex-direction: column;
}

.wrapper form {
	overflow-y: auto;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.toggleWrapper {
	margin-bottom: 25px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	row-gap: 10px;
}

.warningText {
	color: var(--invalid-color);
	font-weight: 600;
	font-size: 14px;
	text-decoration: underline;
}

.emphasisText {
	margin-top: 10px;
	font-weight: 600;
	font-size: 14px;
	text-decoration: underline;
}

@media (max-width: 710px) {
	.modal {
		max-height: unset;
	}
}
