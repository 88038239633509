.wrapper {
	padding-bottom: 150px;
}

.superHeader {
	font-size: 40px;
	font-weight: 600;
	color: var(--main-background-text-color);
	word-break: break-word;
	hyphens: auto;
	margin-bottom: 10px;
}

.superHeader,
.headerAndSubHeaderWrapper {
	display: flex;
	flex-direction: column;
	max-width: 600px;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.header {
	align-items: center;
	display: flex;
	font-size: 28px;
	font-weight: 600;
	height: 100%;
	color: var(--main-background-text-color);
	word-break: break-word;
	hyphens: auto;
}

.subHeaderText {
	align-items: center;
	display: flex;
	font-size: 20px;
	color: var(--main-background-text-color);
	margin-bottom: 25px;
	word-break: break-word;
	hyphens: auto;
	max-width: 1200px;
}

.loginExistingAccountText {
	font-size: 24px;
	font-weight: 600;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;
	color: var(--main-background-text-color);
	margin-bottom: 20px;
}

.loginExistingAccountText span {
	cursor: pointer;
}

.loginExistingAccountText strong {
	text-decoration: underline;
	font-weight: bolder;
}

.consumerTypeSelection {
	width: 100%;
	max-width: 600px;
	border-bottom: 1px solid var(--section-border-color);
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 15px;
	display: flex;
	justify-content: center;
}

.consumerTypeOption {
	color: var(--main-background-text-color);
	font-size: 20px;
	padding: 5px 10px 5px 10px;
	cursor: pointer;
	user-select: none;
}

.consumerTypeOptionSelected {
	font-weight: 600;
}

.consumerTypeOptionSelected,
.consumerTypeOption:not(.consumerTypeOptionDisabled):hover {
	border-bottom: 6px solid var(--accent-color);
}

.consumerTypeOptionDisabled {
	cursor: not-allowed;
	color: var(--disable-color);
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	background-color: var(--section-background-color);
	color: var(--section-background-text-color);
	padding: 20px;
}

.boxHeader {
	font-size: 28px;
	margin-bottom: 10px;
	font-weight: 600;
	word-break: break-word;
	hyphens: auto;
}

.goToBookingButton {
	max-width: 300px;
	margin: 20px auto 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.goToBookingButton svg path {
	stroke: var(--accent-inside-color);
}

.goToBookingButton:hover svg path {
	stroke: var(--accent-hover-inside-color);
}
