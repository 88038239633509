.wrapper {
	position: relative;
	background-color: var(--section-background-color);
	padding: 15px 20px 28px 20px;
	border-radius: 12px;
	width: 100%;
	max-width: 600px;
	height: fit-content;
}

.headerText {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
	word-break: break-all;
	hyphens: auto;
}

.consumerTypeText {
	font-size: 22px;
}

.headerAndEditRow {
	display: flex;
	justify-content: space-between;
}

.editIcon {
	stroke: var(--section-icon-color);
	cursor: pointer;
}

.profilePicRow {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	flex-wrap: wrap;
}

.profilePic {
	width: 80px;
	min-width: 80px;
	height: 80px;
	min-height: 80px;
	overflow: hidden;
	border-radius: 100px;
	cursor: pointer;
	background-color: rgb(165, 165, 165);
	position: relative;
}

.profilePic img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.noProfilePic {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
}

.uploadProfilePictureButton {
	-webkit-appearance: none;
	padding: 12px 20px 12px 20px;
	border-radius: 20px;
	display: flex;
	justify-content: space-between;
	border: none;
	box-shadow: 0 3px 6px #00000029;
	gap: 20px;
	align-items: center;
	height: 42px;
	background-color: var(--finalize-color);
	color: var(--finalize-inside-color);
	margin-top: auto;
	margin-bottom: auto;
}

.uploadProfilePictureButton:hover {
	background-color: var(--finalize-hover-color);
	color: var(--finalize-hover-inside-color);
	cursor: pointer;
}

.uploadProfilePictureButton .text {
	font-size: 16px;
}

.uploadProfilePictureButton .icon {
	font-size: 22px;
	font-weight: 600;
}

.textAsInput {
	display: flex;
	align-items: flex-end;
	min-height: 52px;
	width: 100%;
	font-size: 16px;
	background-color: var(--input-field-color);
	color: var(--input-field-inside-color);
	border-radius: 6px;
	padding: 0 12px 5px;
	overflow-x: auto;
	white-space: nowrap;
}

.textAsInput::-webkit-scrollbar {
	display: none;
}

.textInputWithLabel {
	position: relative;
	overflow: hidden;
}

.textInputWithLabel:not(:last-of-type) {
	margin-bottom: 15px;
}

.textInputWithLabel label {
	color: var(--input-field-inside-color);
	-webkit-text-fill-color: var(--input-field-inside-color);
	position: absolute;
	top: 5px;
	left: 12px;
	font-weight: 600;
	font-size: 14px;
	white-space: nowrap;
	z-index: 1;
}

.twoInputsSameRow {
	display: grid;
	grid-template-columns: 49% 2% 49%;
}

.uploadedFiles {
	background-color: var(--module-box-color);
	padding: 12px;
	color: var(--module-box-text-color);
	border-radius: 12px;
	box-shadow: 0 1px 2px #00000029;
}

.uploadedFiles .header {
	font-size: 18px;
	font-weight: 600;
}

.uploadedFiles .filesContainer {
	display: flex;
	flex-wrap: wrap;
	column-gap: 10px;
	row-gap: 10px;
	min-height: 100px;
}

.filesContainer .fileContainer {
	border-radius: 6px;
	border: 0.5px solid #b7b7b7;
	display: flex;
	flex-direction: column;
	padding: 5px;
	width: 125px;
	max-height: 150px;
	position: relative;
}

.fileContainer:hover {
	box-shadow: 0 0 6px 2px #00000029;
	cursor: pointer;
}

.fileContainer .image {
	max-width: 120px;
	max-height: 100px;
	border-radius: 6px;
}

.fileContainer .fileName {
	font-size: 12px;
	font-weight: 500;
	word-break: break-all;
	margin-top: auto;
}

.fileContainer .spinner {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #7777776b;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.fileContainer .spinner svg {
	font-size: 52px;
}

.fileInput {
	background-color: var(--accent-color);
	display: flex;
	padding: 10px 15px 10px 15px;
	margin-top: 25px;
	border-radius: 22px;
	width: 180px;
	height: 42px;
}

.fileInput label {
	color: var(--accent-inside-color);
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-weight: 600;
}

.fileInput:hover {
	background-color: var(--accent-hover-color);
	cursor: pointer;
}

.fileInput:hover label {
	color: var(--accent-hover-inside-color);
	cursor: pointer;
}

.fileInput input {
	display: none;
}

.fileInput label > span:first-of-type {
	font-size: 16px;
}

.fileInput label > span:last-of-type {
	font-size: 24px;
}

.twoLineClamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: auto;
}

.trashIcon svg {
	stroke: var(--module-box-icon-color);
	fill: var(--module-box-icon-color);
}

.trashIcon {
	position: absolute;
	top: 5px;
	right: 5px;
	background-color: var(--module-box-color);
	border-radius: 100px;
	padding: 4px;
	height: 26px !important;
	width: 26px !important;
}

.trashIcon:hover {
	cursor: pointer;
	border: 1px solid black;
}

@media (max-width: 1100px) {
	.headerText {
		font-size: 20px;
	}

	.consumerTypeText {
		font-size: 16px;
	}

	.uploadedFiles .header {
		font-size: 16px;
	}
}

@media (max-width: 450px) {
	.twoInputsSameRow {
		display: flex;
		flex-direction: column;
	}

	.twoInputsSameRow > div.textInputWithLabel {
		margin-bottom: 15px;
	}
}
