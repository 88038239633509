.modal {
	height: calc(var(--100vh) - 20px);
	overflow-y: scroll;
	max-height: 690px;
}

.wrapper {
	max-width: 520px;
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 635px;
}

.header {
	font-size: 28px;
	font-weight: 600;
	margin-top: -15px;
	color: var(--section-background-text-color);
}

.uploadProfilePictureButton {
	max-width: 205px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--accent-color);
	color: var(--accent-inside-color);
	padding: 10px 20px 10px 20px;
	border-radius: 26px;
}

.noProfilePic {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
}

.pfpUploading {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(128, 128, 128, 0.7);
	width: 100%;
	height: 100%;
	color: black;
	display: flex;
	justify-content: center;
	align-items: center;
}

.iconColorStroke {
	stroke: var(--section-icon-color);
}

.iconColorFill {
	fill: var(--section-icon-color);
}

.iconColorStroke.disabled {
	stroke: var(--disable-inside-color);
}

.iconColorFill.disabled {
	fill: var(--disable-inside-color);
}

.deleteUser {
	display: flex;
	gap: 10px;
	margin-bottom: 30px;
	align-items: center;
	cursor: pointer;
	color: var(--section-background-text-color);
}

.deleteUser .disabled {
	color: var(--disable-inside-color);
	cursor: not-allowed;
}

.deleteUser:hover {
	color: var(--section-hover-color);
}

.deleteUser:hover .iconColorStroke:not(.disabled) {
	stroke: var(--section-hover-color);
}

.deleteUser:hover .iconColorFill:not(.disabled) {
	fill: var(--section-hover-color);
}

.deleteUser > span {
	font-weight: 600;
}

@media (max-width: 710px) {
	.wrapper {
		max-width: unset;
	}

	.modal {
		height: calc(var(--100vh) - 60px);
		max-height: unset;
	}

	.finalize {
		margin-top: auto;
		min-height: 42px;
	}
}
