.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 50vh;
}

.header {
	font-size: 1.22em;
	font-weight: bold;
	margin: 1em 0;
}

.wrapper p {
	text-align: center;
	margin: 0.5em 0;
}
