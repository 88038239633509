.table {
	border-spacing: 0 2px;
	width: 100%;
	overflow-x: auto;
}

.table thead {
	background-color: var(--section-header-color);
	height: 65px;
}

.table thead tr {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

.table thead th {
	text-align: start;
	color: var(--section-header-inside-color);
}

.table thead th:first-of-type {
	border-top-left-radius: 8px;
}

.table thead th:first-of-type,
.table tbody tr > td:first-of-type {
	padding-left: 15px;
	padding-right: 5px;
}

.table thead th:last-of-type {
	border-top-right-radius: 8px;
}

.table tbody tr {
	background-color: var(--section-background-color);
}

.table tbody tr td {
	color: var(--section-background-text-color);
	border-top: 3px solid var(--section-border-color);
	border-bottom: 3px solid var(--section-border-color);
}

.table tbody tr td:first-of-type {
	border-left: 3px solid var(--section-border-color);
}

.table tbody tr td:last-of-type {
	border-right: 3px solid var(--section-border-color);
}

.tablePagination {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

.tablePagination > span {
	color: var(--main-background-text-color);
}

.tablePagination select {
	background-color: var(--input-field-color-mback);
	color: var(--input-field-inside-color);
	border-radius: 15px;
	width: 50px;
	padding-top: 2px;
	padding-left: 5px;
	height: 25px;
	cursor: pointer;
}

.tablePagination select:disabled {
	cursor: not-allowed;
}

.tablePagination button {
	border-radius: 15px;
	background-color: var(--input-field-color-mback);
	color: var(--input-field-inside-color);
	border: none;
	padding-left: 8px;
	padding-right: 8px;
	height: 25px;
	cursor: pointer;
}

.tablePagination .navigationArrow path {
	fill: var(--input-field-inside-color);
}

.tablePagination button:disabled {
	cursor: not-allowed;
	background-color: var(--disable-color);
	color: var(--disable-inside-color);
}
