.mobileBanner .bannerWrapper {
	position: fixed;
	bottom: 0;
	width: var(--100vw);
	height: 115px;
	background-color: var(--section-background-color);
	box-shadow: 0 0 5px 1px var(--header-shadow-color);
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	z-index: 100;
}

.mobileBanner .innerBannerWrapper {
	margin: 12px 12px 24px;
}

.innerBannerWrapper {
	background-color: var(--module-box-color);
	padding: 12px;
	border-radius: 6px;
	display: grid;
	grid-template-columns: auto minmax(auto, 160px) auto;
	grid-template-rows: 70px;
	gap: 12px;
}

.iosInnerBannerWrapper {
	grid-template-columns: minmax(auto, 44px) auto;
}

.iosInnerBannerWrapper::-webkit-scrollbar {
	display: none;
}

.innerBannerWrapper .downloadButton {
	width: 110px;
	height: 32px;
}

.innerBannerWrapper .customerLogo {
	max-width: 44px;
	border-radius: 6px;
	object-fit: contain;
}

.innerBannerWrapper .infoText {
	word-break: break-all;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: var(--module-box-text-color);
}

.innerBannerWrapper .downloadButtonWrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.closeXIcon {
	position: absolute;
	right: 10px;
	top: 5px;
	margin-top: 2px;
	background-color: var(--section-background-color);
	border-radius: 24px;
	cursor: pointer;
	padding: 5px;
}

.modal .closeXIcon {
	display: none;
}

.closeXIconInside {
	stroke: var(--module-box-icon-color);
	fill: none;
}

.iosText {
	font-size: 14px;
}

.iosInnerBannerWrapper .iosText {
	overflow-y: scroll;
}

.iosInnerBannerWrapper .iosText > img {
	height: 18px;
}

.innerBannerWrapper .logoWrapper {
	display: inherit;
	align-content: center;
}

.downloadIcon {
	display: none;
}

@media (max-width: 320px) {
	.innerBannerWrapper .customerLogo {
		display: none;
		width: 0;
		min-width: unset;
	}
}

.hr {
	height: 0;
	border-top: 1px solid #d8d8d8;
}
