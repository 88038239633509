.wrapper {
	width: 100%;
	max-width: 817px;
	display: flex;
	flex-direction: column;
}

.title {
	color: var(--main-background-text-color);
	font-size: 40px;
	font-weight: 600;
	margin-bottom: 10px;
}

.box {
	position: relative;
	background-color: var(--section-background-color);
	border-radius: 8px;
	padding: 15px 20px 20px 20px;
	min-height: 250px;
	height: 100%;
}

.searchAndFilterRow {
	display: flex;
	justify-content: space-between;
}

.listWrapper {
	overflow-y: scroll;
}

.image {
	width: 62px;
	height: 62px;
	box-shadow: 0 0 4px var(--chosen-border-color);
	border-radius: 10px;
	cursor: pointer;
	min-width: 62px;
}

.listItem {
	display: flex;
	gap: 10px;
}

.image:hover {
	box-shadow: 0 0 8px var(--chosen-border-color);
}

.showOrderButton {
	width: 100px;
	margin-left: auto;
}

.orderNumber {
	margin: 0 auto;
	color: var(--module-box-text-color);
}

.noDocsText {
	margin-top: 80px;
	color: var(--section-background-text-color);
	font-size: 18px;
	display: flex;
	justify-content: center;
}

.infoText {
	color: var(--section-background-text-color);
	font-size: 14px;
}

.fileName {
	font-size: 14px;
	width: 100%;
	max-width: 400px;
}

@media (max-width: 1100px) {
	.title {
		font-size: 24px;
	}
}

@media (max-width: 710px) {
	.wrapper {
		max-width: unset;
	}
}
