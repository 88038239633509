.header {
	height: clamp(40px, 11.41vh, 120px);
	background-color: var(--header-color);
	display: flex;
	align-items: center;
}

.header > img {
	max-height: 40%;
	object-fit: contain;
	margin-left: 4rem;
}

@media (max-width: 768px) {
	.header {
		justify-content: center;
		align-items: center;
		height: clamp(20px, 11.41vh, 80px);
	}

	.header > img {
		margin: 0;
		max-height: 90%;
		max-width: 50%;
	}
}
