.orderItemPriceSummaryWrapper {
	background-color: var(--module-box-color);
	margin-bottom: 10px;
	padding: 10px 10px 10px 15px;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	row-gap: 5px;
}

.orderItemPriceSummaryWrapper hr {
	width: 100%;
}

.annotation {
	background: var(--confirmed-amount-color);
	color: var(--confirmed-amount-inside-color);
	font-size: 0.6rem;
}
