.wrapper {
	position: relative;
	background-color: var(--section-background-color);
	padding: 15px 20px 28px 20px;
	border-radius: 12px;
	width: 100%;
	max-width: 600px;
	height: fit-content;
}

.headerText {
	font-size: 28px;
	font-weight: 600;
	color: var(--section-background-text-color);
	word-break: break-all;
}

.headerAndEditRow {
	display: flex;
	justify-content: space-between;
}

.editIcon {
	stroke: var(--section-icon-color);
	cursor: pointer;
	min-width: 22px;
}

@media (max-width: 1100px) {
	.headerText {
		font-size: 20px;
	}
}
